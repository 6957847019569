
import axios from 'axios'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { standardButton, chooseSecurityMethodButton, answerSecurityQuestionButton } from '../../../helpers/buttons'
import { setTokenToLocalStorage, setProfPicToLocalStorage, setEmailToLocalStorage } from '../../../helpers/storage'
import { securityCodeTextField } from '../../../helpers/textfields'
import { standardErrorContainer } from '../../../helpers/errors'
import { getUserIdFromToken } from '../../../helpers/storage'
import { reloadView } from '../../../helpers/globalHelpers'

import { customAnalyticsEvent } from '../../../helpers/analytics'

// When "Email Security Code" is pressed
const codeChosen = async (setAllAuthViewIndex, token) => {
  // console.log('code chosen runs')

  // Email Code
  await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/security-code-generate/${getUserIdFromToken(token)}`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  // Go to the Input 6-Digit Code View
  setAllAuthViewIndex(2)
}

// When "Answer Security Question" is pressed
const questionChosen = async (setAllAuthViewIndex, token, questionIndex, setSecurityQuestion) => {
  // console.log('question chosen runs')

  const newForm = {
    questionIndex: questionIndex,
  }

  // Get Security Question
  const { data } = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/get-security-question/${getUserIdFromToken(token)}`, newForm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  // Set the security question
  setSecurityQuestion(data.securityQuestion)

  // Go to the Input Security Question Answer View
  setAllAuthViewIndex(3)
}

// Email 6-Digit Code or Answer Security Question
export const codeOrQuestion = (setAllAuthViewIndex, token, securityQuestionIndex, setSecurityQuestion) => {

  return (
    <>

      {/* Advisory */}
      <Typography 
        sx={{
          mt: 6,
          color: 'black',
        }}
      >
        <em>Choose a way to verify your identity:</em>
      </Typography>

      {/* Email Security Code Button */}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {chooseSecurityMethodButton(
          'Email Security Code', 
          'submit',
          'contained',
          false,
          'primary', 
          4,
          0,
          0,
          '250px', 
          '45px', 
          codeChosen,
          setAllAuthViewIndex,
          token,
          securityQuestionIndex,
          setSecurityQuestion
        )}
      </Box>

      {/* Answer Security Question Button */}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {chooseSecurityMethodButton(
          'Answer Security Question', 
          'submit',
          'contained',
          false,
          'secondary', 
          2,
          0,
          0,
          '250px', 
          '45px', 
          questionChosen,
          setAllAuthViewIndex, 
          token, 
          securityQuestionIndex,
          setSecurityQuestion
        )}
      </Box>
    
    </>
  )
}

const executeResponseCorrectOrIncorrect = (data, token, email, profilePicture, navigate, setSecurityCodeErrors, attemptsRemaining, setAttemptsRemaining, urlPath) => {
  if (data.message === 'Correct') {

    // Set the token, username, email, and profile picture to local storage
    setTokenToLocalStorage(token)
    setEmailToLocalStorage(email)
    setProfPicToLocalStorage(profilePicture)

    const userId = getUserIdFromToken(token)

    // Proceed to Next View 
    if (urlPath === '/login') {
      navigate(`/dashboard/${userId}`)
    } else if (urlPath === '/create-account') {
      navigate(`/dashboard/${userId}`)
    }

    // Set Errors to false
    setSecurityCodeErrors(false)
  } else if (data.message === 'Incorrect') {
    setAttemptsRemaining(attemptsRemaining - 1)

    setSecurityCodeErrors(true)

    // Collect Analytics
    // if (attemptsRemaining - 1 === 0) {
    //   customAnalyticsEvent(
    //     `account_locked`, 
    //     `account_locked`, 
    //     `account_locked`, 
    //     `account_locked`, 
    //     'Account Locked'
    //   )
    // } else {
    //   customAnalyticsEvent(
    //     `attempts_remaining_${attemptsRemaining - 1}`, 
    //     `attempts_remaining_${attemptsRemaining - 1}`, 
    //     `attempts_remaining`, 
    //     `attempts_remaining_${attemptsRemaining - 1}`, 
    //     'Attempts Remaining'
    //   )
    // }
  }
}

// Submit Security Guess
const submitSecurityCodeGuess = async (codeOrQuestion, securityCodeGuess, attemptsRemaining, setAttemptsRemaining, token, setSecurityCodeErrors, email, profilePicture, navigate, securityQuestionIndex, urlPath) => {
  
  if (codeOrQuestion === 'code') {
    // console.log('code submitted')

    const newForm = {
      guess: securityCodeGuess,
      attemptsRemaining: attemptsRemaining - 1,
    }

    try {
      // Check to see if security code guess matches the code on the User property in the database
      const { data } = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/guess-security-code/${getUserIdFromToken(token)}`, newForm, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      executeResponseCorrectOrIncorrect(data, token, email, profilePicture, navigate, setSecurityCodeErrors, attemptsRemaining, setAttemptsRemaining, urlPath)

    } catch (error) {
      // console.log(error)
      // error message posting new profile
      setSecurityCodeErrors(true)
    }

  } else if (codeOrQuestion === 'question') {
    // console.log('answer submitted')

    const newForm = {
      guess: securityCodeGuess,
      questionIndex: securityQuestionIndex,
      attemptsRemaining: attemptsRemaining - 1,
    }
    
    try {
      // Check to see if security code guess matches the code on the User property in the database
      const { data } = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/answer-security-question/${getUserIdFromToken(token)}`, newForm, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      executeResponseCorrectOrIncorrect(data, token, email, profilePicture, navigate, setSecurityCodeErrors, attemptsRemaining, setAttemptsRemaining, urlPath)

    } catch (error) {
      // console.log(error)
      // error message posting new profile
      setSecurityCodeErrors(true)
    }
  }
}

// Input 6-Digit Code
export const input6DigitCode = (email, profilePicture, securityCodeGuess, setSecurityCodeGuess, attemptsRemaining, setAttemptsRemaining, securityCodeErrors, setSecurityCodeErrors, token, navigate, urlPath) => {

  return (
    <>
      {/* Advisory */}
      <Typography 
        sx={{
          mt: 6,
          color: 'black',
        }}
      >
        <em>Input 6-digit code emailed to <strong>{email}</strong>:</em>
      </Typography>

      {/* Answer */}
      <Box
        sx={{
          width: '95%', maxWidth: '250px',
        }}
      >
        {securityCodeTextField(
          `answerSecurityQuestion`, 
          'Answer',
          attemptsRemaining < 1,
          'off',
          true,
          setSecurityCodeGuess
        )}
      </Box>

      {/* Submit button */}
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        {answerSecurityQuestionButton(
          'Submit', 
          'code',
          'button', 
          'contained',
          securityCodeGuess,
          attemptsRemaining, 
          setAttemptsRemaining,
          securityCodeGuess === '',
          'primary',
          2,
          2,
          0,
          '250px', 
          '45px', 
          submitSecurityCodeGuess, 
          token, 
          setSecurityCodeErrors,
          email,
          profilePicture, 
          navigate,
          0,
          urlPath
        )}
      </Box>
      
      {/* Error Message, if errors */}
      {securityCodeErrors &&
        standardErrorContainer(
          attemptsRemaining > 0 ? `*Incorrect code. ${attemptsRemaining} attempts remaining` : `This account has been locked. Reset password to unlock account.`,
          0,
          0
        )
      }
    </>
  )
}

// Answer Security Question
export const answerSecurityQuestion = (email, profilePicture, securityQuestion, securityQuestionIndex, securityCodeGuess, setSecurityCodeGuess, attemptsRemaining, setAttemptsRemaining, securityCodeErrors, setSecurityCodeErrors, token, navigate, urlPath) => {

  return (
    <>
      {/* Question */}
      <Typography 
        sx={{
          mt: 6,
          color: 'black',
        }}
      >
        {/* <em>{question}</em> */}
        <em>{securityQuestion}</em> 
      </Typography>

      {/* Answer */}
      <Box
        sx={{
          width: '95%', maxWidth: '250px',
        }}
      >
        {securityCodeTextField(
          `answerSecurityQuestion`, 
          'Answer',
          attemptsRemaining < 1,
          'off',
          true,
          setSecurityCodeGuess
        )}
      </Box>

      {/* Submit button */}
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        {answerSecurityQuestionButton(
          'Submit', 
          'question',
          'button', 
          'contained',
          securityCodeGuess,
          attemptsRemaining, 
          setAttemptsRemaining,
          securityCodeGuess === '',
          'primary',
          2,
          2,
          0,
          '250px', 
          '45px', 
          submitSecurityCodeGuess, 
          token, 
          setSecurityCodeErrors,
          email,
          profilePicture, 
          navigate,
          securityQuestionIndex,
          urlPath
        )}
      </Box>
      
      {/* Error Message, if errors */}
      {securityCodeErrors &&
        standardErrorContainer(
          attemptsRemaining > 0 ? `*Incorrect code. ${attemptsRemaining} attempts remaining` : `This account has been locked. Reset password to unlock account.`,
          0,
          0
        )
      }
    </>
  )
}