/* eslint-disable no-prototype-builtins */
import React from 'react'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import blackDottedSpinnerGIF from '../../../../assets/dotted-black-trans-spinner-200.gif'
import turquoiseDottedSpinnerGIF from '../../../../assets/dotted-turquoise-trans-spinner-200.gif'
import whiteDottedSpinnerGIF from '../../../../assets/dotted-white-trans-spinner-200.gif'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'

import { navbarHeight, footerHeight, positionChangeWidthSm, exampleCompaniesObj, positionChangeWidthMd } from '../../../../helpers/variableDefaults'
import { standardButton, exitModalIconButton } from '../../../../helpers/buttons'
import { numberWithCommas } from '../../../../helpers/globalHelpers'

import { seoPageTags, customAnalyticsEvent } from '../../../../helpers/analytics'

// VC Sim Instructions
const Instructions = (props) => {
  
  // Destructure Props
  const { vcSimulation, setVCSimulation, rules, showInstructions, setShowInstructions, width } = props
  // console.log('vcSimulation ->', vcSimulation)
  // const rules = vcSimulation.standardizedSimulation.rules

  const handleClose = () => {
    setShowInstructions(false)
  }

  const boldedLabelElement = (label, description) => {
    return (
      <Box
        sx={{
          // mb: 1,
          width: '85%',
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start',
        }}
      >
        {/* Description Label */}
        <Typography
          sx={{
            mr: 1,
            width: '190px', minWidth: '190px',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
        >
          {label}
        </Typography>

        {/* Name of Company*/}
        <Typography
          sx={{
            // backgroundColor: 'blue',
            ml: 1,
            fontSize: '18px',
          }}
        >
          {description}
        </Typography>
      </Box>
    )
  }

  const boldedDescriptionElement = (label, description, marginTop = 0) => {
    return (
      <Box
        sx={{
          // backgroundColor: 'green',
          mt: marginTop, pt: marginTop,
          mb: 0.5,
          borderTop: marginTop > 0 ? 'solid 1px black' : 'solid 0px black',
          width: '90%',
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        {/* Description Label */}
        <Typography
          sx={{
            // backgroundColor: 'yellow',
            mr: 1,
            width: '350px', minWidth: '350px',
            // fontWeight: 'bold',
            fontSize: '18px',
          }}
        >
          {label}
        </Typography>

        {/* Description*/}
        <Typography
          sx={{
            // backgroundColor: 'blue',
            ml: 1,
            fontWeight: 'bold',
            fontSize: '18px',
          }}
        >
          {description}
        </Typography>
      </Box>
    )
  }

  return (
    <>

      {/* Follow Ons Advisory Body */}
      <Box sx={{ 
        width: '100vw', height: '100vh',
        display: 'flex', flexDirection: 'row', justifyContent: 'stretch', alignItems: 'stretch',
      }}>
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={showInstructions ? true : false}
          closeAfterTransition
          // Backdrop={{
          //   timeout: 500,
          // }}
          keepMounted
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
        >
          <Fade in={showInstructions ? true : false}>
            <Paper sx={{
              width: '85%',
              height: '85%',
              pb: 6,
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                WebkitAppearance: 'none',
                width: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '4px',
                backgroundColor: 'rgba(0, 0, 0, .35)',
                webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
              },
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
            }}>
              {/* Exit Icon Button */}
              <Box
                sx={{
                  mr: 0,
                  width: '100%',
                  display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center',
                }}
              >
                {exitModalIconButton(handleClose, false, 0, 1, 1)}
              </Box>

              {/* Title */}
              <Typography
                sx={{
                  fontSize: '22px',
                  fontWeight: 'bold',
                }}
              >
                Simulation Guidelines
              </Typography>

              {/* Paremeters Chart */}
              {width >= positionChangeWidthMd &&
                <Box
                  sx={{
                    // backgroundColor: 'orange',
                    py: 2, mt: 2,
                    border: `solid 2px black`, boxShadow: 3,
                    width: '85%', maxWidth: '550px',
                    display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                  }}
                >

                  {/* Parameters Title */}
                  <Typography
                    sx={{
                      mb: 1,
                      fontSize: '18px',
                      fontWeight: 'bold',
                    }}
                  >
                    Parameters
                  </Typography>
                  {boldedDescriptionElement('Fund Size ($)', `$${rules.fundSize}M`, 0)}
                  {boldedDescriptionElement('Fund Capital to Invest (Subtracting Fees & Expenses) ($)', `$${rules.amountToInvest}M`, 0)}

                  {boldedDescriptionElement('Total Number of Portfolio Companies', rules.maxPortfolioCompanies, 2)}
                  {boldedDescriptionElement('Portfolio Companies That Will Receive Follow-On Investment', rules.maxFollowOnCompanies, 0)}
                  {boldedDescriptionElement('Initial Investment Amount ($)', `$${rules.initialInvestmentAmount}M`, 0)}
                  {boldedDescriptionElement('Follow-On Investment Amount ($)', `$${rules.followOnInvestmentAmount}M`, 0)}

                  {boldedDescriptionElement('Carried Interest to Fund Managers if LP Multiple Below 3x', `${rules.performanceFeePre3x * 100}%`, 2)}
                  {boldedDescriptionElement('Carried Interest to Fund Managers if LP Multiple Above 3x', `${rules.performanceFeePost3x * 100}%`, 0)}
                  {boldedDescriptionElement('Preferred Return (Annual Interest Rate to LPs Before Carry)', '8%', 0)}

                  {boldedDescriptionElement('Investment Period Management Fees (% of Fund/Year)', `${rules.feeForInvestmentPeriod * 100}%`, 2)}
                  {boldedDescriptionElement('Follow-On Period Management Fees (% of Fund/Year)', `${rules.feeForNonInvestmentPeriod * 100}%`, 0)}
                  {boldedDescriptionElement('Management Fees for Full Fund Life ($)', `$${rules.cumulativeFeesAmount}M`, 0)}
                  {boldedDescriptionElement('Fund Expenses for Full Fund Life ($)', `$${rules.cumulativeExpensesAmount}M`, 0)}

                  {boldedDescriptionElement('Investment Period (Years)', (rules.investmentPeriodMonths / 12), 2)}
                  {boldedDescriptionElement('Month to End Making Initial Investments', rules.investmentPeriodMonths, 0)}
                  {boldedDescriptionElement('Follow-On Period (Years)', ((rules.fundLifeMonths - rules.investmentPeriodMonths) / 12), 0)}
                  {boldedDescriptionElement('Fund Life (Years)', (rules.fundLifeMonths / 12), 0)}

                  {boldedDescriptionElement('% of Fund Invested/Reserved Until Next Allowed to Fundraise (ie. initial investments plus potential follow-ons)', `${rules.newFundraisingThreshold * 100}%`, 2)}
                  {boldedDescriptionElement('Investments Required to Meet Threshold', `${vcSimulation.simulationVersion === 'choose20v1' ? 12 : 6}`, 0)}
                  {boldedDescriptionElement('Month After Which Penalty Is Applied', `${rules.poorPacingMonthThreshold}`, 0)}
                  {boldedDescriptionElement('Percent that Penalized-Company Exits Are Reduced', `${rules.poorPacingExitMultipleDiscount * 100}%`, 0)}

                  {boldedDescriptionElement('Capital Returned Expected to Be "Recycled," or Re-Invested', '$0', 2)}
                </Box>
              }

              {/* Background */}
              <Typography
                sx={{
                  mt: 3,
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
              >
                Background
              </Typography>

              {/* Advisory */}
              <Typography
                sx={{
                  mt: 1, mb: 2,
                  width: '85%', maxWidth: '550px',
                }}  
              >
                {ReactHtmlParser(
                  `You are a General Partner and Investment Committee member for Simulation Capital. Simulation Capital is a generalist venture capital firm that finances early-stage (Seed and occasionally Series A) startups. Sim Cap has just raised its third fund. Key parameters are listed above.`
                )}
              </Typography>

              {/* Specifics */}
              <Typography
                sx={{
                  mt: 2,
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
              >
                Specifics
              </Typography>

              {/* Advisory */}
              <Typography
                sx={{
                  mt: 1, mb: 2,
                  width: '85%', maxWidth: '550px',
                }}  
              >
                {ReactHtmlParser(
                  `In summary, Simulation Capital Fund III is $${rules.fundSize} million in size, of which $${rules.amountToInvest} million will be invested with the remaining $${rules.fundSize - rules.amountToInvest} million earmarked for Fund III fees and expenses.
                  <br/><br/>
                  Sim Cap's investors, or Limited Partners ("LPs"), expect investments in ${rules.maxPortfolioCompanies} total companies, of which ${rules.maxFollowOnCompanies} companies will receive one round of follow-on investment.
                  <br/><br/>
                  The Fund's "Investment Period" is ${(rules.investmentPeriodMonths / 12)} years, meaning initial investments in all ${rules.maxPortfolioCompanies} portfolio companies must occur within ${rules.investmentPeriodMonths} months. You estimate an ability to perform solid diligence on ${rules.totalOpportunities} companies in ${(rules.investmentPeriodMonths / 12)} years, so you will need to invest in ${rules.maxPortfolioCompanies} of the up-to-${rules.totalOpportunities} companies presented to the Investment Committee. Follow-on investments can occur at any time during the Fund's ${(rules.fundLifeMonths / 12)}-year life (including during the Investment Period). After ${(rules.fundLifeMonths / 12)} years, capital will be returned to LPs.
                  <br/><br/>
                  Once LPs receive their invested capital of $${rules.fundSize} million plus an annualized interest rate of 8%, Fund managers will receive ${rules.performanceFeePre3x * 100}% of dollars the fund returns as "carried interest." Carried interest jumps to ${rules.performanceFeePost3x * 100}% if the fund returns more than 3x to LPs, performance historically accomplished by 5-20% of funds, depending on the year.
                  <br/><br/>
                  You've built a very strong team, and a priority is to keep them motivated, so that they are retained and continue to do great work. Promotions and salary increases occur when a new Fund is raised, and Sim Cap can only begin fundraising for the next fund once capital is deployed in portfolio companies where the initial investment and potential follow-on represent ${rules.newFundraisingThreshold * 100}% of the capital earmarked for investment. A goal is to achieve this hurdle in ${rules.poorPacingMonthThreshold} months, while, in the process, not compromising on investment quality.
                  `
                )}
              </Typography>

              {/* Process */}
              <Typography
                sx={{
                  mt: 2,
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
              >
                Process
              </Typography>

              {/* Advisory */}
              <Typography
                sx={{
                  mt: 1, mb: 2,
                  width: '85%', maxWidth: '550px',
                }}  
              >
                {ReactHtmlParser(
                  `1) You will be shown ${exampleCompaniesObj.exampleCompanies.length} example initial investments and ${exampleCompaniesObj.exampleCompanies.length} example follow-ons.
                  <br/>
                  2) You will then be prompted for your strategy for initial investments and follow-on investments.
                  <br/>
                  3) Afterwards, you will start the real simulation.
                  <br/>
                  4) Once the specified number of initial investments and follow-ons are met, you will be prompted to explain how your strategy evolved throughout the simulation.
                  <br/>
                  5) Finally, you will be able to view your results, and a copy of your results will also be viewable for your admin.
                  `
                )}
              </Typography>

              {/* The Simulation */}
              <Typography
                sx={{
                  mt: 2,
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
              >
                The Simulation
              </Typography>

              {/* Advisory */}
              <Typography
                sx={{
                  mt: 1, mb: 2,
                  width: '85%', maxWidth: '550px',
                }}  
              >
                {ReactHtmlParser(
                  `You will be "pitched" investment opportunities from your diligence teams one-by-one. You have a lot of faith in your team and trust their thorough evaluations. Your job is to make tough, inherently risky judgment calls when presented information.
                  <br/><br/>
                  Note that there are some investment criteria that are out of scope for this simulation, including:
                  <br/>
                  — <em>Diversification</em>: Treat each investment opportunity as unique. The simulation doesn't reward factors like sector diversification.
                  <br/>
                  — <em>Economic Environment</em>: Assume a good current and future macroeconomic environment.
                  <br/>
                  — <em>Strategy Pivots</em>: Sim Cap is unable to pivot from its initial strategy during its fund life. So Sim Cap is required to deploy ${rules.maxPortfolioCompanies} initial investments of $${rules.initialInvestmentAmount}M and ${rules.maxFollowOnCompanies} follow-ons of $${rules.followOnInvestmentAmount}M.
                  <br/><br/>
                  To make an investment, answer "Yes" in the field beside "WILL YOU INVEST?". To pass on the opportunity, answer "No."
                  <br/><br/>
                  <span style="font-weight:bold; color:#FF0000;">NOTE THAT ALL DECISIONS ARE FINAL. YOU CANNOT GO BACKWARDS. IF YOU DO NOT INVEST THE FULL PORTFOLIO, THE REMAINDER OF COMPANIES WILL BE INVESTED AT RANDOM</span>
                  `
                )}
              </Typography>

              {/* OK Button */}
              <Box
                sx={{
                  mt: 3, mb: 5,
                  width: '100%',
                  height: '80px',
                  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                }}
              >
                {standardButton(
                  'OK', 
                  'button',
                  'contained',
                  false,
                  'primary', 
                  0,
                  0,
                  0,
                  '150px', 
                  '55px', 
                  handleClose
                )}
              </Box>
              
              
            </Paper>
          </Fade>
        </Modal>
      </Box>
    </>
  )
}

export default Instructions