/* eslint-disable no-prototype-builtins */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import blackDottedSpinnerGIF from '../../../../assets/dotted-black-trans-spinner-200.gif'
import turquoiseDottedSpinnerGIF from '../../../../assets/dotted-turquoise-trans-spinner-200.gif'
import whiteDottedSpinnerGIF from '../../../../assets/dotted-white-trans-spinner-200.gif'

import { standardSubmitButton } from '../../../../helpers/buttons'
import { securityCodeTextField } from '../../../../helpers/textfields'
import { standardErrorContainer } from '../../../../helpers/errors'

// mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'

import { seoPageTags, customAnalyticsEvent } from '../../../../helpers/analytics'

// Security
const Security = (props) => {

  // Destructure Props
  const { securityCode, securityPassed, setSecurityPassed, setShowInstructions, testIsFinished, hasResults, investmentSelectionsLength } = props
  // console.log('currentCompany security ->', currentCompany)

  // Guess and error states
  const [ securityCodeGuess, setSecurityCodeGuess ] = useState('')
  const [ securityCodeErrors, setSecurityCodeErrors ] = useState(false)

  // Execute button pressed
  const handleSubmitSecurityCode = (e) => {
    e.preventDefault()

    // If guess equals code, security is passed, if not, show an error message
    if (securityCode === securityCodeGuess) {
      setSecurityCodeErrors(false)
      setSecurityPassed(true)
      setShowInstructions(!testIsFinished && !hasResults && investmentSelectionsLength === 0 )

      // Collect Analytics
      // customAnalyticsEvent(
      //   'vc_sim_security_passed', 
      //   'vc_sim_security_passed', 
      //   'vc_sim_process', 
      //   'vc_sim_security_passed', 
      //   'Security'
      // )
    } else {
      setSecurityCodeErrors(true)

      // Collect Analytics
      // customAnalyticsEvent(
      //   'vc_sim_security_failed', 
      //   'vc_sim_security_failed', 
      //   'vc_sim_process', 
      //   'vc_sim_security_failed', 
      //   'Security'
      // )
    }
  }

  return (
    <>

      {/* Security Code Body */}
      <Box sx={{ 
        width: '100vw', height: '100vh',
        display: 'flex', flexDirection: 'row', justifyContent: 'stretch', alignItems: 'stretch',
      }}>
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={!securityPassed ? true : false}
          closeAfterTransition
          // Backdrop={{
          //   timeout: 500,
          // }}
          keepMounted
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
        >
          <Fade in={!securityPassed ? true : false}>
            <Paper sx={{
              width: '85%',
              height: '85%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>

              {/* Title */}
              <Typography>
                <strong>Security Code</strong>
              </Typography>
              
              {/* TextField and Button Container */}
              <Box 
                component="form" 
                onSubmit={handleSubmitSecurityCode} 
                noValidate 
                textAlign={'center'}
                sx={{ 
                  mt: 1,
                  width: '100%', maxWidth: '300px',
                }}
              >
                
                {/* Security Code Guess TextField */}
                {securityCodeTextField(
                  'securityCodeGuess',
                  'Security Code',
                  false,
                  'off',
                  true,
                  setSecurityCodeGuess
                )}

                {/* Submit button */}
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  {standardSubmitButton(
                    'Submit', 
                    'submit', 
                    securityCodeGuess === '',
                    'primary',
                    2,
                    2, 
                    '155px', 
                    '45px'
                  )}
                </Box>
                
                {/* Error Message, if errors */}
                {securityCodeErrors &&
                  standardErrorContainer(
                    '*Incorrect code',
                    0,
                    0
                  )
                }
                
              </Box>

              {/* Useful Info */}
              <Typography display="block" sx={{ mt: 1, width: '90%', maxWidth: '400px' }}>
                *The security code is in the same email in which you received the link for this simulation
              </Typography>
              
            </Paper>
          </Fade>
        </Modal>
      </Box>
    </>
  )
}

export default Security