/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Divider from '@mui/material/Divider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import { vcResultsAnalysisTabJSX } from './helpers/vcResultsAnalysisTab'
import { vcResultsPortfolioTabJSX } from './helpers/vcResultsPortfolioTab'
import { vcResultsStrategyTabJSX } from './helpers/vcResultsStrategyTab'

import { navbarHeight, footerHeight, positionChangeWidthSm, positionChangeWidthLg, simcapPink, simcapTurquoise } from '../../../helpers/variableDefaults'
import { standardSpinner } from '../../../helpers/spinners'
import { standardButton } from '../../../helpers/buttons'
import { standardErrorContainer } from '../../../helpers/errors'
import { couldNotLoadPageError } from '../../../helpers/errors'
import { makeTabIndex } from '../../../helpers/formatting'
import { TabPanel } from '../../../helpers/formatting'
import { userIsAuthenticated, getTokenFromLocalStorage, getPayload } from '../../../helpers/storage'
import useWindowDimensions from '../../../helpers/windowDimensions'

import { seoPageTags, customAnalyticsEvent } from '../../../helpers/analytics'
import { standardInfoTooltip } from '../../../helpers/tooltips'

// VC Sim Results
const VCResults = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Payload
  const payload = getPayload()

  // console.log('props ->', props)
  // Destructure Props
  const  { vcSimulation, rules, loading } = props
  const results = vcSimulation.results
  // console.log('results ->', results)
  const resultsTabsArray = [
    'Analysis', 
    'Portfolio', 
    'Strategy'
  ]
  const date = new Date(results.createdAt)
  // console.log('results.createdAt ->', typeof results.createdAt)

  //Keeps track of which tab we are in, default is Analysis at index 0
  const [value, setValue] = useState(0)

  // Resend PDF
  const [pdfResent, setPDFResent] = useState(false)
  const [pdfResending, setPDFResending] = useState(false)

  // Errors
  const [errors, setErrors] = useState(false)
  const [resendPDFErrors, setResendPDFErrors] = useState(false)

  // Update view every time tab changes
  useEffect(() => {
    // console.log('value ->', value)
  }, [value])

  // Handles tab changes
  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }



  const handleResendPDF = async () => {
    // console.log('handleResendLink runs')
    setPDFResending(true)
    setResendPDFErrors(false)
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/resend-results-pdf/${vcSimulation._id}`, {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      })
      
      if (data.message === 'PDF resent') {
        setResendPDFErrors(false)
        setPDFResent(true)
        setPDFResending(false)
      } else {
        setResendPDFErrors(true)
        setPDFResending(false)
      }

    } catch (error) {
      setResendPDFErrors(true)
      setPDFResending(false)
    }
    
  }

  const testDetailsElement = (label, description) => {
    return (
      <Box
        sx={{
          // backgroundColor: 'yellow',
          // mb: 1,
          width: '85%', maxWidth: '300px',
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
        }}
      >
        {/* Description Label */}
        <Typography
          sx={{
            // backgroundColor: 'orange',
            mr: 1,
            width: '95px', minWidth: '95px',
            fontWeight: 'bold',
            fontSize: '18px',
            // display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start',
          }}
        >
          {label}
        </Typography>

        {/* Description */}
        <Typography
          sx={{
            // backgroundColor: 'blue',
            ml: 1,
            // width: '70px',
            fontSize: '18px',
          }}
        >
          {description}
        </Typography>
      </Box>
    )
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Simulation Results'
      )}

      {/* Body */}
      <Box 
        sx={{ 
          width: '100vw', 
          minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight})`, 
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        {/* Header — Test Taker Name; Admin Name; Date of Test */}
        <Box
          sx={{
            // backgroundColor: 'yellow',
            mt: 4,
            width: '100%',
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          {testDetailsElement('Test Taker:', results.nameOfTaker)}
          {testDetailsElement('Admin:', results.nameOfAdmin)}
          {/* {testDetailsElement('Date', vcSimulation.updatedAt)} */}
          {testDetailsElement('Date:', date.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' }))}

          {/* Email PDF Button */}
          {getTokenFromLocalStorage() && payload.hasOwnProperty('sub') && payload.sub.length > 0 && payload.sub === vcSimulation.adminUserId
          &&
            standardButton(
              'Email PDF', 
              'button',
              'contained',
              pdfResent || pdfResending,
              'primary', 
              2,
              0,
              0,
              '150px', 
              '40px', 
              handleResendPDF
            )
          }

          {/* Email Error */}
          {resendPDFErrors &&
            standardErrorContainer(
              'There was an error sending the link. Check your Internet connection and try again.',
              1,
              2
            )
          }

          {/* Successful Email */}
          {pdfResent &&
            <Typography 
              component='pre'
              textAlign={'center'}
              sx={{
                mt: 1,
                width: '85%', maxWidth: '400px',
                fontStyle: 'italic',
                color: simcapPink,
              }}
            >
              {`Sent to ${vcSimulation.emailOfAdmin}`.replace(/ {5} +/g, '')}
            </Typography>
          }

          {/* PDF Sending */}
          {pdfResending &&
            <Typography 
              component='pre'
              textAlign={'center'}
              sx={{
                mt: 1,
                width: '85%', maxWidth: '400px',
                fontStyle: 'italic',
                color: 'blue',
              }}
            >
              {`Emailing PDF to ${vcSimulation.emailOfAdmin}...`.replace(/ {5} +/g, '')}
            </Typography>
          }

          {/* To admin email */}
          {/* {!pdfResending && !pdfResent &&
            <Typography 
              component='pre'
              textAlign={'center'}
              sx={{
                mt: 1,
                width: '85%', maxWidth: '400px',
                // fontStyle: 'italic',
                color: 'black',
              }}
            >
              {`To ${vcSimulation.emailOfAdmin}`.replace(/ {5} +/g, '')}
            </Typography>
          } */}
  
        </Box>
        {/* Tabs */}
        <Box 
          sx={{ 
            mt: 2, 
            width: '100%',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          <Tabs
            value={value}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="primary tabs example"
            variant={'standard'}
            sx={{ 
              // backgroundColor: 'yellow',
              // width: '90vw', maxWidth: '375px',
              // width: width < superSmallScoreboardWidth ? '100%' : '275px',
              borderTop: 1, borderColor: 'divider',
              // display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            }}
          >
            {resultsTabsArray.map((tabName, tabIndex) => (
              <Tab key={tabIndex} label={tabName} {...makeTabIndex(tabIndex)} />
            ))}
          </Tabs>
        </Box>


        {/* Tab Panels */}
        {/* Analysis Tab */}
        {vcResultsAnalysisTabJSX(value, 0, results, vcSimulation, loading, width)}

        {/* Portfolio Tab */}
        {vcResultsPortfolioTabJSX(value, 1, results, vcSimulation, rules, loading, width)}

        {/* Strategy Tab */}
        {vcResultsStrategyTabJSX(value, 2, vcSimulation, loading, width)}

      </Box>
    </>
  )
}

export default VCResults