/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import LiveVCSimulation from './LiveVCSimulation.js'
import Instructions from './helpers/Instructions.js'
import PageNavbar from '../../common/PageNavbar.js'
import Footer from '../../common/Footer.js'

import { navbarHeight, footerHeight, positionChangeWidthSm, positionChangeWidthLg, exampleCompaniesObj } from '../../../helpers/variableDefaults'
import { standardSpinner } from '../../../helpers/spinners'
import { couldNotLoadPageError } from '../../../helpers/errors'
import { makeTabIndex } from '../../../helpers/formatting'
import { TabPanel } from '../../../helpers/formatting'
import { standardButton } from '../../../helpers/buttons'
import useWindowDimensions from '../../../helpers/windowDimensions'

import { seoPageTags, customAnalyticsEvent } from '../../../helpers/analytics'
import { standardInfoTooltip } from '../../../helpers/tooltips'

// VC Demo
const VCDemo = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Navigate
  const navigate = useNavigate()

  // Demo is Finished
  const [demoIsFinished, setDemoIsFinished] = useState(false)

  // VC Simulation
  const [vcSimulation, setVCSimulation] = useState({})
  const [currentCompany, setCurrentCompany] = useState(exampleCompaniesObj.exampleCompanies[0])
  const [rules, setRules] = useState(exampleCompaniesObj.exampleRulesChoose10)
  const [exampleCurrentInvestmentIndex, setExampleCurrentInvestmentIndex] = useState(0)
  const [exampleCurrentFollowOnIndex, setExampleCurrentFollowOnIndex] = useState(-1)
  const [showInstructions, setShowInstructions] = useState(true)
  const [loading, setLoading] = useState(false)

  // Update view every time tab changes
  useEffect(() => {
    // console.log('demoIsFinished ->', demoIsFinished)
  }, [demoIsFinished, showInstructions])

  // Navigate to /create-account when Create Account button Clicked
  const handleCreateEstatePressed = async (e) => {
    e.preventDefault()
    navigate('/create-account')
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {/* {seoPageTags(
        'VC Demo'
      )} */}

      <Box
        sx={{ 
          width: '100vw', minWidth: '100vw',
          height: '100vh', minHeight: '100vh',
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
        }}
      >
        {demoIsFinished ?
          <>
            <PageNavbar/>

            {/* Body */}
            <Box
              sx={{
                width: '100%', 
                minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight})`,
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
              }}
            >
              {/* Demo Complete */}
              <Typography
                sx={{
                  fontSize: '30px',
                  fontWeight: 'bold',
                  width: '100%',
                  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                }}
              >
                Demo Complete!
              </Typography>

              {/* Create Account Button */}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {standardButton(
                  'Create Account', 
                  'button',
                  'contained',
                  false,
                  'primary', 
                  2,
                  0,
                  0,
                  '180px', 
                  '45px', 
                  handleCreateEstatePressed
                )}
              </Box>
            </Box>

            <Footer/>
          </>
          :
          <>
            {/* Demo Simulation */}
            <LiveVCSimulation vcSimulation={vcSimulation} setVCSimulation={setVCSimulation} currentCompany={currentCompany} setCurrentCompany={setCurrentCompany} rules={rules} setShowInstructions={setShowInstructions} exampleCurrentInvestmentIndex={exampleCurrentInvestmentIndex} setExampleCurrentInvestmentIndex={setExampleCurrentInvestmentIndex} exampleCurrentFollowOnIndex={exampleCurrentFollowOnIndex} setExampleCurrentFollowOnIndex={setExampleCurrentFollowOnIndex} loading={loading} setLoading={setLoading} isDemo={true} setDemoIsFinished={setDemoIsFinished} />

            {/* Show Instructions */}
            {showInstructions &&
              <Instructions vcSimulation={vcSimulation} setVCSimulation={setVCSimulation} rules={rules} showInstructions={showInstructions} setShowInstructions={setShowInstructions} width={width} />
            }
          </>
            
        }

      </Box>
    </>
  )

}

export default VCDemo
