/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import axios from 'axios'
import jwtDecode from 'jwt-decode'

import { handleChange, validEmail } from '../../../helpers/globalHelpers.js'
import { setTokenToLocalStorage, setProfPicToLocalStorage, setEmailToLocalStorage } from '../../../helpers/storage.js'
import { loginTextField } from '../../../helpers/textfields.js'
import { standardSubmitButton, handleSwitchLoginRegisterButton, standardButton } from '../../../helpers/buttons.js'
import { loginRegisterErrors, standardErrorContainer } from '../../../helpers/errors.js'
import { profPicDefaultURL, entityTypes, positionChangeWidthSm, subscriptionTypes, simcapPink, oneTimeRate, yearlyRate, monthlyRate } from '../../../helpers/variableDefaults.js'
import { standardSpinner } from '../../../helpers/spinners.js'
import { securityQuestionAndAnswerGridItems } from '../../../helpers/securityQuestionsAndAnswers.js'
import { acceptTOSCheckbox } from '../../../helpers/checkboxes.js'
import { selectElement, selectElementHandleChange } from '../../../helpers/selects.js'
import { numberWithCommas } from '../../../helpers/globalHelpers.js'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'

// Stripe
import { PaymentElement } from '@stripe/react-stripe-js'


// Proceed to next view, depending on the fromComponent
export const proceedToNextView = (fromComponent, navigate, setAllAuthViewIndex) => {

  if (fromComponent === '/login' || fromComponent === '/create-account') {
    setAllAuthViewIndex(1)
  } 
}

// Reset all auth errors and Loadings
export const resetAllAuthErrors = (setLoginErrors, setRegisterErrors, setLoadingLogin, setLoadingRegister, setLoadingPayment, setPaymentErrors) => {
  // Reset error states
  setLoginErrors(false)
  setRegisterErrors({})
  setPaymentErrors({})

  // Reset Loading states
  setLoadingLogin(false)
  setLoadingRegister(false)
  setLoadingPayment(false)
}

// Code inside 'try' part of try/catch when attempting a login
const loginTry = async (fromComponent, loginOrRegister, setLoading, setErrors, formToUpload, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture) => {
  
  // Set Loading
  setLoading(true)

  // Upload Login/Register Form to server
  // const { data } = await axios.post('/api/auth/', formToUpload)
  const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/${loginOrRegister}/`, formToUpload)

  // Set these to local storage after answering security question
  setToken(data.token)
  setEmail(data.email)
  setProfilePicture(data.profilePicture)

  // Depending on the component the login occurs in, go to the appropriate next view
  proceedToNextView(fromComponent, navigate, setAllAuthViewIndex)

  // Reset view
  // setLoginViewIndex(0)

  // Set loading and login errors to false
  setLoading(false)
  setErrors(false)
}

// Code inside 'catch' part of try/catch when attempting to login
const loginCatch = async (err, setLoading, setErrors) => {
  // console.log('err: ', err.message)
  setLoading(false)
  setErrors(true)
}

// Code inside 'catch' part of try/catch when attempting to register
// Necessary because we want Register error messages to be more specific than login error messages
const registerCatch = async (err, setLoading, errors, setErrors) => {
  // console.log('err ->', err.response)
  const errorObj = err.response.data.errors
  const messageToReturn = errorObj[Object.keys(errorObj)[0]].message.includes('Value:') ? errorObj[Object.keys(errorObj)[0]].message.split(' Value:')[0] : errorObj[Object.keys(errorObj)[0]].message

  // console.log('error message ->', errorObj[Object.keys(errorObj)[0]].message)
  // console.log('message to return ->', messageToReturn)

  setLoading(false)
  setErrors({ ...errors, [Object.keys(errorObj)[0]]: messageToReturn })
}

// The try/catch when attempting to log in
const loginTryCatch = async (fromComponent, setLoading, setErrors, formToUpload, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture) => {
  try {
    await loginTry(fromComponent, 'login', setLoading, setErrors, formToUpload, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture)

  } catch (err) {
    await loginCatch(err, setLoading, setErrors)
  }
}


// Email/Password login attempt submitted
const handleSubmitLogin = async (e, fromComponent, loginForm, setLoginErrors, setRegisterErrors, setPaymentErrors, setLoadingLogin, setLoadingRegister, setLoadingPayment, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture) => {
  e.preventDefault()

  // reset all error states
  resetAllAuthErrors(setLoginErrors, setRegisterErrors, setLoadingLogin, setLoadingRegister, setLoadingPayment, setPaymentErrors)

  if (loginForm.email && loginForm.password) {

    // Upload login form to server to execute login
    await loginTryCatch(fromComponent, setLoadingLogin, setLoginErrors, loginForm, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture)

  } else {
    // console.log('login form not complete')

    // Set errors to true if the email or password is missing
    setLoadingLogin(false)
    setLoginErrors(true)
  }
}

// Email/Password register attempt submitted
const handleSubmitRegister = async (e, fromComponent, registerForm, registerErrors, paymentErrors, setPaymentErrors, setLoginErrors, setRegisterErrors, setLoadingLogin, setLoadingRegister, setLoadingPayment, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture, stripe, elements) => {
  e.preventDefault()

  // reset all error states
  resetAllAuthErrors(setLoginErrors, setRegisterErrors, setLoadingLogin, setLoadingRegister, setLoadingPayment, setPaymentErrors)

  // If it's necessary to delete the customer
  const deleteStripeCustomer = async (customerId) => {

    await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/delete-stripe-customer/`, {
      data: { customerId: customerId },
    })
    // console.log('customer deleted')
  }

  // ! Delete or comment out when accepting payments again
  if (registerForm.entityName.trim() && registerForm.entityType && registerForm.email && registerForm.password && registerForm.passwordConfirmation && (registerForm.password === registerForm.passwordConfirmation) && registerForm.securityQuestions[0].a && registerForm.securityQuestions[1].a && registerForm.securityQuestions[2].a) {
    try {
      setLoadingRegister(true)
      // upload register form to server to execute login/register
      await loginTry(fromComponent, 'register', setLoadingRegister, setRegisterErrors, registerForm, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture)

      // Update States
      setLoadingRegister(false)
      
    } catch (err) {
      console.log('register error ->', err)

      // Catch Register errors if there are any
      await registerCatch(err, setLoadingRegister, registerErrors, setRegisterErrors)
      
    }
  } else if (registerForm.password !== registerForm.passwordConfirmation) {
    // console.log('password does not match password confirmation')

    // If there's an error, pass this message
    setLoadingRegister(false)
    setRegisterErrors({ ...registerErrors, passwordConfirmation: 'password and password confirmation do not match' })
  } else {
    // console.log('register form not complete')

    // If there's an error, pass this message
    setLoadingRegister(false)
    setRegisterErrors({ ...registerErrors, passwordConfirmation: 'All fields are required' })
  }

  // ! Comment back in when accepting payments again
  // // If none of the form fields are empty, POST the form data to the register endpoint and then navigate to /login
  // if (registerForm.entityName.trim() && registerForm.entityType && registerForm.email && registerForm.password && registerForm.passwordConfirmation && (registerForm.password === registerForm.passwordConfirmation) && registerForm.securityQuestions[0].a && registerForm.securityQuestions[1].a && registerForm.securityQuestions[2].a && registerForm.paymentMethodIsValid) {
  //   try {
  //     setLoadingPayment(true)

  //     // Handle Payment
  //     // Stripe — Trigger form validation and wallet collection
  //     const { error: submitError } = await elements.submit()
  //     if (submitError) {
  //       setRegisterErrors(false)
  //       setPaymentErrors(submitError)
  //       setLoadingPayment(false)
  //       return
  //     }

  //     // Create the subscription if necessary; Then create a payment intent
  //     const formToUpload = { ...registerForm }
  //     try {
  //       if (registerForm.mode === 'subscription') {
  //         // console.log('subscription mode')
  //         const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/create-subscription/`, formToUpload)
  //         // console.log('data ->', data)
  //         // console.log('type ->', data.type)
  //         // console.log('clientSecret ->', data.clientSecret)
  //         // console.log('customerId ->', data.customerId)
  //         // console.log('subscriptionId ->', data.subscriptionId)
  
  //         const type = data.type
  //         const clientSecret = data.clientSecret
  //         const customerId = data.customerId
  //         const subscriptionId = data.subscriptionId
  
  //         const confirmIntent = type === 'setup' ? stripe.confirmSetup : stripe.confirmPayment
  
  //         // Confirm the Intent using the details collected by the Payment Element
  //         const { error: confirmIntentError } = await confirmIntent({
  //           elements,
  //           clientSecret,
  //           confirmParams: {
  //             return_url: `${process.env.REACT_APP_CLIENT_URL}/`, 
  //           },
  //           redirect: 'if_required',
  //         })
  
  //         // console.log('confirm intent error ->', confirmIntentError)
  
  //         if (confirmIntentError) {
  //           // console.log('confirm intent error')
  //           // This point is only reached if there's an immediate error when confirming the Intent.
  //           // Show the error to your customer (for example, "payment details incomplete").
  
  //           // Delete the created customer
  //           if (customerId) {
  //             await deleteStripeCustomer(customerId)
  //           }
  
  //           setRegisterErrors(false)
  //           setPaymentErrors(confirmIntentError)
  //           setLoadingPayment(false)
  //         } else {
  //           // console.log('everything runs successfully, now you can add estate')
            
  //           // Create the User Here
  //           formToUpload.stripeSubscriptionId = subscriptionId
  //           formToUpload.stripeCustomerId = customerId
  //           formToUpload.subscriptionIsActive = true
  
  //           // upload register form to server to execute login/register
  //           await loginTry(fromComponent, 'register', setLoadingRegister, setRegisterErrors, formToUpload, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture)
  
  //           // Update States
  //           setRegisterErrors(false)
  //           setPaymentErrors({})
  //           setLoadingPayment(false)
  //         }
  //       } else if (registerForm.mode === 'payment') {
  //         const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/create-payment-intent/`, formToUpload)
  //         // console.log('data ->', data)
  //         // console.log('data.clientSecret ->', data.clientSecret)
  //         // console.log('data.customerId ->', data.customerId)
  
  //         const clientSecret = data.clientSecret
  //         const customerId = data.customerId
  //         // console.log('elements ->', elements)
  //         // console.log('cardElement ->', cardElement)
  //         // console.log('stripe ->', stripe)
  
  //         const { error: confirmPaymentError } = await stripe.confirmPayment({
  //           elements,
  //           clientSecret,
  //           confirmParams: {
  //             return_url: `${process.env.REACT_APP_CLIENT_URL}/`,
  //           },
  //           redirect: 'if_required',
  //         })
  //         // console.log('confirmPaymentError ->', confirmPaymentError)
  
  //         if (confirmPaymentError) {
  //           console.log('confirm payment error')
  
  //           // Delete the created customer
  //           if (customerId) {
  //             await deleteStripeCustomer(customerId)
  //           }
  
  //           setRegisterErrors(false)
  //           setPaymentErrors(confirmPaymentError)
  //           setLoadingPayment(false)
  //         } else {
  //           console.log('everything runs successfully, now you can add a user')
            
  //           // Create the Estate Here
  //           formToUpload.stripeCustomerId = customerId
  //           formToUpload.subscriptionIsActive = true
  
  //           // upload register form to server to execute login/register
  //           await loginTry(fromComponent, 'register', setLoadingRegister, setRegisterErrors, formToUpload, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture)
  
  //           // Update States
  //           setRegisterErrors(false)
  //           setPaymentErrors({})
  //           setLoadingPayment(false)
  //         }
  
  //       } else {
  //         console.log('this runs 1')
  //         setRegisterErrors(false)
  //         setPaymentErrors({ message: 'Could not upload payment. Refresh the view to see if your Internet is working and try again.' })
  //         setLoadingPayment(false)
  //       }
  
  //     } catch (err) {
  //       console.log('err: ', err)
  //       // console.log('catch error ->', err)
  //       setRegisterErrors(true)
  //       setPaymentErrors({})
  //       setLoadingPayment(false)
  //     }

  //   } catch (err) {
  //     console.log('register error ->', err)

  //     // Catch Register errors if there are any
  //     await registerCatch(err, setLoadingRegister, registerErrors, setRegisterErrors)

  //   }
  // } else if (registerForm.password !== registerForm.passwordConfirmation) {
  //   // console.log('password does not match password confirmation')

  //   // If there's an error, pass this message
  //   setLoadingPayment(false)
  //   setRegisterErrors({ ...registerErrors, passwordConfirmation: 'password and password confirmation do not match' })
  // } else {
  //   // console.log('register form not complete')

  //   // If there's an error, pass this message
  //   setLoadingPayment(false)
  //   setRegisterErrors({ ...registerErrors, passwordConfirmation: 'All fields are required' })
  // }
}

// Email/Password Login Elements
export const inProcessLogin = (fromComponent, loginForm, setLoginForm, loginErrors, setLoginErrors, loadingLogin, setLoadingLogin, loginViewIndex, setLoginViewIndex, setRegisterErrors, setPaymentErrors, setLoadingRegister, setLoadingPayment, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture, width) => {

  return (
    <Box sx={{ 
      width: '85%',
      maxWidth: '400px',
      mt: 2, mb: 2,
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
    }}>
      {
        loadingLogin ?
          standardSpinner()
          :
          <>
            {/* Login Form */}
            <Box component="form" 
              onSubmit={(e) => handleSubmitLogin(e, fromComponent, loginForm, setLoginErrors, setRegisterErrors, setPaymentErrors, setLoadingLogin, setLoadingRegister, setLoadingPayment, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture)} 
              noValidate 
              sx={{ 
                mt: 1, mb: 1,
                width: '100%',
              }}
            >
              
              {/* Email */}
              <Box sx={{ mb: 1, width: '100%' }}>
                {loginTextField('email', 'Email Address', loginForm.email, 'outlined', true, true, true, 'email', loginForm, setLoginForm, setLoginErrors, handleChange )}
              </Box>
              
              {/* Password */}
              <Box sx={{ mt: 1, width: '100%' }}>
                {loginTextField('password', 'Password', loginForm.password, 'outlined', true, true, false, 'current-password', loginForm, setLoginForm, setLoginErrors, handleChange )}
              </Box>
              
              {/* Errors if there are errors */}
              {loginRegisterErrors(loginErrors, 'Unauthorised.', 1, 0)}

              {/* Submit button */}
              <Box 
                sx={{ 
                  mt: 1, mb: 1, 
                  width: '100%', 
                  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
                }}
              >
                {standardSubmitButton('Sign In', 'submit', false, 'primary', 2, 2, '150px', '50px')}
              </Box>

              {/* Link to Reset Password */}
              {fromComponent === '/login' && loginViewIndex === 0 &&
                <Box sx={{ width: '100%' }}>
                  <Button 
                    onClick={(e) => navigate('/reset-password')} 
                    variant="text" 
                    color='secondary'
                    sx={{ 
                      mt: 0,
                      width: '150px', 
                      height: '40px', 
                      display: 'flex', flexDirection: 'row', justifyContent: 'left',
                    }}
                  >
                    Reset Password
                  </Button>
                </Box>
              }
            </Box>
          </>
      }
    </Box>
  )
}

// Email/Password Register Elements
export const inProcessRegister = (fromComponent, registerForm, setRegisterForm, registerErrors, setRegisterErrors, paymentErrors, setPaymentErrors, loadingRegister, setLoadingRegister, loadingPayment, setLoadingPayment, acceptedTOS, setAcceptedTOS, loginViewIndex, setLoginViewIndex, setLoginErrors, setLoadingLogin, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture, stripe, elements, width) => {
  

  // When credit card info is all added, switch paymentMethodIsValid to true
  const handlePaymentElementChange = (e) => {
    // console.log('handlePaymentElementChange runs')
    // console.log('handlePaymentElementChange event ->', e)

    setPaymentErrors(false)

    if (e.complete !== registerForm.paymentMethodIsValid) {

      setRegisterForm({ ...registerForm, paymentMethodIsValid: e.complete })

    } 
  }
  
  return (
    <Box sx={{ 
      width: '85%',
      maxWidth: '400px',
      mt: 2, mb: 2,
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
    }}>
      {
        loadingRegister ?
          standardSpinner()
          :
          <>
            {/* Register Form */}
            <Box component="form" noValidate 
              onSubmit={(e) => handleSubmitRegister(e, fromComponent, registerForm, registerErrors, paymentErrors, setPaymentErrors, setLoginErrors, setRegisterErrors, setLoadingLogin, setLoadingRegister, setLoadingPayment, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture, stripe, elements)} 
              sx={{ 
                mt: 3, 
                width: '100%',
              }}
            >
              {/* Entity Details */}
              <Box 
                sx={{ 
                  pb: 3, pt: 2, mb: 3,
                  width: '100%',
                  backgroundColor: 'whitesmoke',
                  boxShadow: 3, borderRadius: 3,
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                }}
              >
                {/* Title */}
                <Box
                  sx={{
                    mb: 2,
                    width: '100%',
                    fontWeight: 'bold', fontSize: '18px',
                    display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                  }}
                >
                  Details
                </Box>
                
                {/* Entity Name */}
                <Box sx={{ mb: 1, width: '90%' }}>
                  {loginTextField('entityName', 'Entity Name', registerForm.entityName, 'outlined', true, true, true, 'new-password', registerForm, setRegisterForm, setRegisterErrors, handleChange )}
                </Box>

                {/* Error message under entityName if it's a entityName error */}
                {loginRegisterErrors(registerErrors.entityName, registerErrors.entityName, 0, 1)}
                
                {/* Entity Type */}
                <Box sx={{ mb: 1, mt: 1, width: '90%' }}>
                  {/* {loginTextField('familyName', 'Last Name', registerForm.entityType, 'outlined', true, true, false, 'new-password', registerForm, setRegisterForm, setRegisterErrors, handleChange )} */}
                  {selectElementHandleChange('entityType', 'Entity Type', registerForm.entityType, false, true, 'lg', entityTypes, registerForm, setRegisterForm, setRegisterErrors)}
                </Box>

                {/* Error message under familyName if it's a familyName error */}
                {loginRegisterErrors(registerErrors.entityType, registerErrors.entityType, 0, 1)}

                {/* Email */}
                <Box sx={{ mb: 1, mt: 1, width: '90%' }}>
                  {loginTextField('email', 'Email Address', registerForm.email, 'outlined', true, true, false, 'new-password', registerForm, setRegisterForm, setRegisterErrors, handleChange )}
                </Box>

                {/* Error message under email if it's an email error */}
                {loginRegisterErrors(registerErrors.email, registerErrors.email, 0, 1)}

                {/* Password */}
                <Box sx={{ mb: 1, mt: 1, width: '90%' }}>
                  {loginTextField('password', 'Password', registerForm.password, 'outlined', true, true, false, 'new-password', registerForm, setRegisterForm, setRegisterErrors, handleChange )}
                </Box>

                {/* Error message under password if it's a password error */}
                {loginRegisterErrors(registerErrors.password, registerErrors.password, 0, 1)}

                {/* Password Confirmation */}
                <Box sx={{ mb: 1, mt: 1, width: '90%' }}>
                  {loginTextField('passwordConfirmation', 'Password Confirmation', registerForm.passwordConfirmation, 'outlined', true, true, false, 'new-password', registerForm, setRegisterForm, setRegisterErrors, handleChange )}
                </Box>

                {/* Error message under password confirmation if it's a password confirmation error */}
                {loginRegisterErrors(registerErrors.passwordConfirmation, registerErrors.passwordConfirmation, 0, 1)}

              </Box>

              {/* Security Questions and Answers */}
              <Box 
                sx={{ 
                  pb: 3, pt: 2, mb: 3,
                  width: '100%',
                  backgroundColor: 'whitesmoke',
                  boxShadow: 3, borderRadius: 3,
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                }}
              >
                {/* Title */}
                <Box
                  sx={{
                    mb: 2,
                    width: '100%',
                    fontWeight: 'bold', fontSize: '18px',
                    display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                  }}
                >
                  Security
                </Box>

                {/* Security Questions and Answers */}
                {securityQuestionAndAnswerGridItems(registerForm, setRegisterForm)}

              </Box>


              {/* Payment Details */}
              {/* {registerForm.hasOwnProperty('subscriptionType') && registerForm.subscriptionType.length > 0 &&
                <Box 
                  sx={{ 
                    pb: 3, pt: 0, mb: 0,
                    width: '100%',
                    backgroundColor: 'whitesmoke',
                    boxShadow: 3, borderRadius: 3,
                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                  }}
                > */}
              {/* Subscription Type */}
              {/* <Box 
                sx={{ 
                  mt: 2, mb: 0, pt: 2,
                  // backgroundColor: 'whitesmoke',
                  width: '100%',
                  maxWidth: '600px', 
                  // boxShadow: 3,
                  borderRadius: width < positionChangeWidthSm ? 0 : 5,
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
                }}
              > */}
              {/* Title */}
              {/* <Box 
                sx={{ 
                  mb: 2,
                  width: '95%',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
                }}
              >
                Subscription Type
              </Box> */}

              {/* Subscription Types */}
              {/* <Box 
                sx={{ 
                  width: '95%',
                  display: 'flex', flexDirection: width < positionChangeWidthSm ? 'column' : 'row', justifyContent: width < positionChangeWidthSm ? 'space-around' : 'space-around', alignItems: 'center',
                  // flexWrap: 'wrap',
                }}
              >
                {subscriptionTypes.length > 0 && subscriptionTypes.map((subscriptionType, subscriptionTypeIndex) => (
                  <Box
                    key={subscriptionTypeIndex}
                    // onClick={() => handleSelectSubscriptionType(subscriptionType)}
                    sx={{
                      my: 1, mx: 0,
                      py: 3,
                      backgroundColor: registerForm.subscriptionType === subscriptionType.interval ? 'white' : 'whitesmoke',
                      width: '100%',
                      maxWidth: '175px',
                      boxShadow: 3,
                      borderRadius: 5,
                      cursor: registerForm.subscriptionType === subscriptionType.interval ? 'pointer' : '',
                      border: registerForm.subscriptionType === subscriptionType.interval ? '2px solid deeppink' : '0px solid deeppink',
                      display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                    }}
                  > */}

              {/* Title */}
              {/* <Box
                textAlign={'center'}
                sx={{
                  // backgroundColor: 'yellow',
                  pt: 1, pb: 1,
                  width: '95%',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: registerForm.subscriptionType === subscriptionType.interval ? 'black' : 'lightgray',
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                }}
              >
                {subscriptionType.interval === 'one-time' ? `Individual` : registerForm.entityType !== 'Individual' ? registerForm.entityType : `Institution`}
              </Box> */}

              {/* Rate */}
              {/* <Box
                textAlign={'center'}
                sx={{
                  // backgroundColor: 'yellow',
                  pt: 0, pb: 1,
                  width: '95%',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: registerForm.subscriptionType === subscriptionType.interval ? 'black' : 'lightgray',
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                }}
              >
                {`$${numberWithCommas(subscriptionType.rate)}`}
              </Box> */}

              {/* Interval */}
              {/* <Box
                textAlign={'center'}
                sx={{
                  // backgroundColor: 'yellow',
                  pt: 0, pb: 1,
                  width: '95%',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: registerForm.subscriptionType === subscriptionType.interval ? 'black' : 'lightgray',
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                }}
              >
                {subscriptionType.interval === 'one-time' ? `(${subscriptionType.interval})` : `(per ${subscriptionType.interval})`}
              </Box>

              <Box
                textAlign={'center'}
                sx={{
                  // backgroundColor: 'yellow',
                  pt: 0, pb: 1,
                  width: '95%',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  color: registerForm.subscriptionType === subscriptionType.interval ? 'black' : 'lightgray',
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                }}
              >
                {subscriptionType.interval === 'one-time' ? `${subscriptionType.simulations} Simulations` : `${subscriptionType.simulations}`}
              </Box> */}

              {/* Free Trial */}
              {/* <Typography 
                textAlign={'center'}
                sx={{
                  width: '90%',
                  color: registerForm.subscriptionType === subscriptionType.interval ? simcapPink : 'lightgray',
                }}
              >
                {subscriptionType.freeTrial ?
                  <em>*7-day Free Trial</em>
                  : 
                  <em>No Free Trial</em> 
                }
              </Typography>

            </Box>
          ))}

        </Box>
      </Box> */}

              {/* Payment Method */}
              {/* <Box 
                sx={{ 
                  my: 0, py: 0,
                  // backgroundColor: 'whitesmoke',
                  width: '100%',
                  maxWidth: '600px', 
                  // boxShadow: 3,
                  borderRadius: width < positionChangeWidthSm ? 0 : 5,
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
                }}
              > */}

              {/* Card Information */}
              {/* <Box 
                sx={{ 
                  width: '95%',
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >
                <Box 
                  // component='form' 
                  // onSubmit={(e) => handleSubmitLogin(e, fromComponent, loginForm, setLoginErrors, setRegisterErrors, setLoadingLogin, setLoadingRegister, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture)} 
                  // noValidate 
                  sx={{ 
                    // mt: 1, mb: 1,
                    display: 'flex', flexDirection: 'column',
                  }}
                > */}
              {/* Cardholder Name */}
              {/* <Typography
                sx={{
                  fontSize: '15px',
                  fontWeight: 400,
                  color: '#36454F',
                }}
              >
                Cardholder Name
              </Typography>

              <TextField
                focused={false}
                size={'small'}
                name={'cardholderName'}
                value={registerForm.cardholderName}
                onChange={(e) => handleChange(e, setRegisterErrors, setRegisterForm, registerForm)}
                sx={{
                  mb: 1.5,
                  backgroundColor: 'white',
                }}
              />

              <PaymentElement onChange={(e) => handlePaymentElementChange(e)} /> */}


              {/* Promo Code */}
              {/* <Typography
                sx={{
                  mt: 1.5,
                  fontSize: '15px',
                  fontWeight: 400,
                  color: '#36454F',
                }}
              >
                Promo Code
              </Typography>

              <TextField
                focused={false}
                size={'small'}
                name={'promoCode'}
                value={registerForm.promoCode}
                onChange={(e) => handleChange(e, setRegisterErrors, setRegisterForm, registerForm)}
                sx={{
                  // mb: 1.5,
                  backgroundColor: 'white',
                }}
              /> */}


              {/* Payment Error */}
              {/* {Object.keys(paymentErrors).length > 0 &&
                standardErrorContainer(
                  `${paymentErrors.hasOwnProperty('message') ? paymentErrors.message : 'Could not process payment. Try again with a different card.'}`,
                  0,
                  0
                )
              }
              
              {loadingPayment &&
                <Box
                  sx={{
                    width: '100%',
                    minHeight: '100px',
                    height: '100px',
                    maxHeight: '100px',
                    display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                  }}
                >
                  {standardSpinner()}
                </Box>
              }

              {loadingPayment && 
                <Typography 
                  component='pre'
                  textAlign={'center'}
                  sx={{
                    mt: 2, mb: 2,
                    width: '90%',
                    fontStyle: 'italic',
                    color: 'blue',
                  }}
                >
                  
                  {
                    `*Payment Processing 
                    *Do not refresh or exit page`.replace(/ {4} +/g, '')
                  }
                    
                </Typography>
              }
            </Box> */}

              {/* Advisory */}
              {/* {!loadingPayment && 
                <Typography 
                  component='pre'
                  textAlign={'center'}
                  sx={{
                    mb: 0, mt: 1,
                    width: '90%',
                    fontStyle: 'italic',
                    color: 'blue',
                  }}
                >
                  {registerForm.subscriptionType !== 'one-time' ?
                    `*Card not charged until end of 7-day free trial`.replace(/ {4} +/g, '')
                    : 
                    `*Card will be charged immediately`.replace(/ {4} +/g, '')
                  }
                </Typography>
              } */}

              {/* Advisory */}
              {/* {!loadingPayment && 
                <Typography 
                  component='pre'
                  textAlign={'center'}
                  sx={{
                    mt: 0, mb: 2,
                    width: '90%',
                    fontStyle: 'italic',
                    color: 'blue',
                  }}
                >
                  
                  {`*Payments processed through Stripe`.replace(/ {4} +/g, '')}
                    
                </Typography>
              }
            </Box>

          </Box>

        </Box>
      } */}
              
              {/* Terms of Service and Privacy Policy Checkbox */}
              <Box
                sx={{ 
                  width: '100%',
                  fontSize: '13px',
                  mb: 1, mt: 1,
                  pl: 1, pr: 1, pt: 1,
                  display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap',
                  overflow: 'visible',
                }}
              >
                {acceptTOSCheckbox('acceptedTOS', acceptedTOS, setAcceptedTOS)}
              </Box>

              {/* Register Button */}
              <Box sx={{ mb: 1, mt: 1, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {standardSubmitButton(
                  'Create', 
                  'submit', 
                  !(
                    registerForm.hasOwnProperty('entityName') && registerForm.entityName.trim().length > 0 
                    && registerForm.hasOwnProperty('entityType') && registerForm.entityType.length > 0 && 
                    registerForm.hasOwnProperty('email') && registerForm.email.length > 0 && 
                    registerForm.hasOwnProperty('password') && registerForm.password.length > 0 
                    && registerForm.hasOwnProperty('passwordConfirmation') && registerForm.passwordConfirmation.length > 0 
                    && acceptedTOS && registerForm.hasOwnProperty('securityQuestions') 
                    && registerForm.securityQuestions.length === 3 && registerForm.securityQuestions[0].q.length > 0 && registerForm.securityQuestions[0].a.length > 0 && registerForm.securityQuestions[1].q.length > 0 && registerForm.securityQuestions[1].a.length > 0 && registerForm.securityQuestions[2].q.length > 0 && registerForm.securityQuestions[2].a.length > 0
                    // && registerForm.hasOwnProperty('amount') && registerForm.amount > 0
                    // && registerForm.hasOwnProperty('currency') && registerForm.currency.length > 0
                    // && registerForm.hasOwnProperty('mode') && registerForm.mode.length > 0
                    // && registerForm.hasOwnProperty('cardholderName') && registerForm.cardholderName.trim().length > 0 
                    // && registerForm.hasOwnProperty('paymentMethodIsValid') && registerForm.paymentMethodIsValid
                    && !loadingPayment
                  ), 
                  'primary', 
                  2, 
                  2, 
                  '150px', 
                  '50px')}

                {/* Error message under entityName if it's a entityName error */}
                {loginRegisterErrors(registerErrors.entityName, registerErrors.entityName, 0, 1)}

                {/* Error message under entityType if it's a entityType error */}
                {loginRegisterErrors(registerErrors.entityType, registerErrors.entityType, 0, 1)}

                {/* Error message under email if it's an email error */}
                {loginRegisterErrors(registerErrors.email, registerErrors.email, 0, 1)}

                {/* Error message under password if it's a password error */}
                {loginRegisterErrors(registerErrors.password, registerErrors.password, 0, 1)}

                {/* Error message under password confirmation if it's a password confirmation error */}
                {loginRegisterErrors(registerErrors.passwordConfirmation, registerErrors.passwordConfirmation, 0, 1)}
              </Box>
            </Box>
          </>
      }
    </Box>
  )
}

// All Auth Elements: Email/Password Login and Register Options
export const inProcessAllAuth = (fromComponent, loginViewIndex, setLoginViewIndex, loginForm, setLoginForm, loginErrors, setLoginErrors, loadingLogin, setLoadingLogin, registerForm, setRegisterForm, registerErrors, setRegisterErrors, paymentErrors, setPaymentErrors, loadingRegister, setLoadingRegister, loadingPayment, setLoadingPayment, acceptedTOS, setAcceptedTOS, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture, stripe, elements, width ) => {

  return (
    <>

      {/* Login Form */}
      {loginViewIndex === 0 && 
        inProcessLogin(fromComponent, loginForm, setLoginForm, loginErrors, setLoginErrors, loadingLogin, setLoadingLogin, loginViewIndex, setLoginViewIndex, setRegisterErrors, setPaymentErrors, setLoadingRegister, setLoadingPayment, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture, width)
      }
      
      {/* Register Form */}
      {loginViewIndex === 1 && registerForm.hasOwnProperty('securityQuestions') && registerForm.securityQuestions.length >= 0 &&
        inProcessRegister(fromComponent, registerForm, setRegisterForm, registerErrors, setRegisterErrors, paymentErrors, setPaymentErrors, loadingRegister, setLoadingRegister, loadingPayment, setLoadingPayment, acceptedTOS, setAcceptedTOS, loginViewIndex, setLoginViewIndex, setLoginErrors, setLoadingLogin, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture, stripe, elements, width)
      }

    </>
  )
}