/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

import blackDottedSpinnerGIF from '../../assets/dotted-black-trans-spinner-200.gif'
import turquoiseDottedSpinnerGIF from '../../assets/dotted-turquoise-trans-spinner-200.gif'
import whiteDottedSpinnerGIF from '../../assets/dotted-white-trans-spinner-200.gif'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import BarChartIcon from '@mui/icons-material/BarChart'
import ForwardIcon from '@mui/icons-material/Forward'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import { standardSpinner } from '../../helpers/spinners'

import { Input, dummyBox, validEmail } from '../../helpers/globalHelpers'
import { positionChangeHeightSm, positionChangeWidthSm, positionChangeWidthMd, personTypes, privilegesOptions } from '../../helpers/variableDefaults'
import { viewResultsIconButton, takeTestIconButton, viewTestInfoIconButton } from '../../helpers/buttons'
import useWindowDimensions from '../../helpers/windowDimensions'
import { personItemTextField } from '../../helpers/textfields'
import { selectElement } from '../../helpers/selects'
import { getTokenFromLocalStorage } from '../../helpers/storage'

// Simulation Instance Page
const SimulationInstance = (props) => {
  
  // Navigate
  const navigate = useNavigate()
  
  // Destructure Props
  const { simulation, simulationIndex, user, fromComponent, setOpenTestInfo, setInfoObj } = props
  // console.log('simulation ->', simulation)
  // console.log('fromComponent ->', fromComponent)
  const simulationType = simulation.simulationVersion === 'choose20v1' ? '100 Companies' : '50 Companies'

  // Window Dimensions
  const { height, width } = useWindowDimensions()

  const handleViewResult = async (e) => {
    // console.log('handleViewResult runs')
    navigate(`/vc-simulation/${simulation._id}`)
  }

  const handleTakeTest = async (e) => {
    // console.log('handleTakeTest runs')
    navigate(`/vc-simulation/${simulation._id}`)
  }

  const handleShowInfo = async (e) => {
    setInfoObj(simulation)
    setOpenTestInfo(true)
  }
  
  return (
    <>
      {/* This must be first input So that the file upload only fires when you press the button */}
      <Box sx={{ width: '100%' }}>
        <Input type="text" autoFocus="autoFocus" />
      </Box>
      
      {/* Simulation Item */}
      <Box 
        sx={{ 
          backgroundColor: 'whitesmoke', 
          mt: 1, mb: 2, pb: 0.5, boxShadow: 3, borderRadius: '5px', width: '100%', 
          display: 'flex', flexDirection: width < positionChangeWidthSm ? 'column' : 'row', justifyContent: width < positionChangeWidthSm ? 'center' : 'space-between', alignItems: width < positionChangeWidthSm ? 'center' : 'center', 
        }}
      >

        {/* Person Name or Test Number */}
        <Box textAlign={'center'} sx={{ mt: 1, mb: 1, width: width < positionChangeWidthSm ? '90%' : '30%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {/* <Typography textAlign={'center'}> */}
          {user.hasOwnProperty('entityType') && user.entityType === 'Individual' 
            ? 
            `VC Test ${simulation.standardizedSimulationNumber - 10}` 
            : 
            simulation.nameOfTaker
          }
          {/* </Typography> */}
        </Box>

        {/* Date or Type */}
        <Box sx={{ mt: 1, mb: 1, width: width < positionChangeWidthSm ? '90%' : '20%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {user.hasOwnProperty('entityType') && user.entityType === 'Individual' 
            ? 
            `${simulationType}` 
            : 
            // (new Date(simulation.createdAt)).toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' })
            `VC Test ${simulation.standardizedSimulationNumber > 20 ? simulation.standardizedSimulationNumber - 20 : simulation.standardizedSimulationNumber > 10 ? simulation.standardizedSimulationNumber - 10 : simulation.standardizedSimulationNumber}`
          }
        </Box>

        {/* Decision Quality Percentile */}
        <Box textAlign={'center'} sx={{ mt: 1, mb: 1, width: width < positionChangeWidthSm ? '90%' : '20%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {simulation.hasOwnProperty('results') && simulation.results !== null ?
            `${simulation.results.importantNumbers.decisionQualityPercentile}%`
            :
            simulation.testIsFinished ? 
              <Typography
                sx={{
                  fontStyle: 'italic',
                }}
              >
                Pending
              </Typography>
              :
              <Typography
                sx={{
                  fontStyle: 'italic',
                }}
              >
                Incomplete
              </Typography>
          }
        </Box>

        {/* Results Button */}
        {user.hasOwnProperty('entityType') && user.entityType === 'Individual' && (!simulation.hasOwnProperty('results') || simulation.results === null)
          ?
          takeTestIconButton(
            simulationIndex,
            handleTakeTest,
            false
          )
          :
          (!simulation.hasOwnProperty('results') || simulation.results === null)
            ?
            viewTestInfoIconButton(
              simulationIndex, 
              handleShowInfo, 
              user.entityType !== 'Individual' && !user.subscriptionIsActive
            )
            :
            viewResultsIconButton(
              simulationIndex, 
              handleViewResult, 
              (
                (!simulation.hasOwnProperty('results') || simulation.results === null)
                || (user.entityType !== 'Individual' && !user.subscriptionIsActive)
              )
            )
        }
        
      </Box>
    </>
  )
}

export default SimulationInstance