/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

import FilterSimulations from './FilterSimulations'
import SimulationInstance from './SimulationInstance'
import NavDashboard from '../common/NavDashboard'
import Simulations from './components/Simulations'
import Customize from './components/Customize'
import MyAccount from './components/MyAccount'

import useWindowDimensions from '../../helpers/windowDimensions'
import { userIsAuthenticated, getTokenFromLocalStorage, getPayload } from '../../helpers/storage'
import { standardSpinner } from '../../helpers/spinners'
import { couldNotLoadPageError } from '../../helpers/errors'
import { navbarHeight, footerHeight } from '../../helpers/variableDefaults'
import { reloadView } from '../../helpers/globalHelpers'

// Dashboard Page
const Dashboard = () => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()
  
  // Navigate
  const navigate = useNavigate()

  // Params
  const { userId } = useParams()

  // Payload
  const payload = getPayload()

  // Dashboard Navigation
  const [user, setUser] = useState({})
  const [viewIndex, setViewIndex] = useState(0)

  // Loading
  const [loading, setLoading] = useState(false)

  // Errors
  const [errors, setErrors] = useState(false)

  // UseEffect — Send to user profile view if User is not a Person in the estate
  useEffect(() => {
    // console.log('userId ->', userId)
    // console.log('payload.sub ->', payload.sub)

    if (!userIsAuthenticated()) {
      navigate('/')
    } else if (userId !== payload.sub) {
      navigate(`/dashboard/${payload.sub}`)
      reloadView()
    } else {
      const getData = async () => {
        setLoading(true)
        try {
          const { data: retrievedUser } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/users/${userId}/not-populated`, {
            headers: {
              Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            },
          })
          // console.log('retrieved User ->', retrievedUser)


          if (payload.sub === retrievedUser._id) {
            // Set States
            setUser(retrievedUser)
            
            setErrors(false)
            setLoading(false)
          } else {
            navigate(`/`)
            setErrors(false)
            setLoading(false)
          }

        } catch (error) {
          // console.log(error)

          navigate(`/`)

          setErrors(true)
          setLoading(false)
        }
      }

      getData()
    }

  }, [])

  useEffect(() => {
    // console.log('useEffect Runs')
    // console.log('filtersObj ->', filtersObj)
    // console.log('openNewSimulationModal ->', openNewSimulationModal)
    // console.log('filteredSimulations ->', filteredSimulations)

  }, [viewIndex])


  return (
    <>
      {loading ?
        <Box sx={{ 
          width: '100vw', minWidth: '100vw',
          // minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight})`,
          minHeight: `calc(100vh - ${navbarHeight})`,
          mt: 0, mb: 0,
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
        }}>
          {standardSpinner()}
        </Box>
        :
        errors ? 
          <Box
            sx={{
              // backgroundColor: 'yellow',
              width: '100%',
              minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight})`,
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
            }}
          >
            {couldNotLoadPageError()}
          </Box>
          :
          <>
            {/* Navigation  */}
            <NavDashboard userId={userId} userIsIndividual={user.hasOwnProperty('entityType') && user.entityType === 'Individual'} viewIndex={viewIndex} setViewIndex={setViewIndex} setLoading={setLoading} />

            {/* Inactive Subscription */}
            {user.hasOwnProperty('entityType') && user.entityType !== 'Individual' && !user.subscriptionIsActive &&
              <Box
                sx={{
                  backgroundColor: 'red',
                  color: 'white',
                  py: 3, px: 3,
                  width: '100%', 
                  // height: '100px',
                  fontSize: '18px', fontWeight: 'bold',
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                }}
              >
                {`Click 'Manage Subscription' in the 'My Account' tab to reactivate subscription`}
              </Box>
            }
            
            {/* Simulations */}
            {viewIndex === 0 && <Simulations />}

            {/* Customize */}
            {viewIndex === 1 
              && user.hasOwnProperty('entityType') 
              && user.entityType !== 'Individual' 
              && <Customize />
            }

            {/* My Account */}
            {viewIndex === 1 
              && user.hasOwnProperty('entityType') 
              && user.entityType === 'Individual' 
              && <MyAccount />
            }
            {viewIndex === 2 
              && user.hasOwnProperty('entityType') 
              && user.entityType !== 'Individual' 
              && <MyAccount />
            }
          </>
      }

    </>
  )
}

export default Dashboard