

// Variable Defaults


// Widths and Heights
export const positionChangeHeightSm = 700
export const positionChangeWidthSm = 600
export const positionChangeWidthMd = 900
export const positionChangeWidthLg = 1200
export const priceCardWidth = 250
export const tooltipMinWidth = '225px'
export const tooltipInfoDimension = '25px'
export const iconImageDimension = '115px'
export const privacyPolicyMaxWidth = '600px'
export const vcSimTopBarHeight = '150px'

// Navbar and Footer
export const navbarHeight = '70px' //Height for the navigation bar
export const dashboardNavbarHeight = '65px' //Height of the navbar inside the estate
export const footerHeight = '150px' //Height for the footer bar

// Colors
export const simcapTurquoise = '#00ffff' // SimCap Turqoise
export const simcapPink = '#f06292' // SimCap Pink
export const navbarBgDefaultColor = '#000000' // black

// Images
export const profPicDefaultURL = 'https://simcap-bizops-images.s3.amazonaws.com/seed-profpic-default-2.jpg'
export const uploadImageDimension = 350

// Timers

// Arrays

// Nav menu when logged in
export const navMenuOptionsLoggedIn = [
  'Dashboard', 
  // 'My Account',
  // 'Create Estate',
  // 'About',
  // 'Pricing',
  // 'Demo',
  'Logout'
]

// Nav menu when not logged in
export const navMenuOptionsNotLoggedIn = [
  'Create Account',
  // 'About',
  // 'Pricing',
  'Demo',
  'Login'
]

// Entity Types
export const entityTypes = [
  'Business',
  'School',
  'Individual'
]

// Simulations Sort By Array
export const simulationsSortByArray = [
  'Date ⬇',
  'Date ⬆',
  'Percentile ⬇',
  'Percentile ⬆',
  'A ➡ Z',
  'Z ➡ A'
]

export const dateRangesArray = [
  'All',
  'Last 30 Days',
  'Last 6 Months',
  'Last Year'
]

export const simulationVersionsBusinessesArray = [
  'Test 1 — 50 Companies, Choose 10',
  'Test 2 — 50 Companies, Choose 10',
  'Test 3 — 50 Companies, Choose 10',
  'Test 4 — 50 Companies, Choose 10',
  'Test 5 — 50 Companies, Choose 10',
  'Test 6 — 100 Companies, Choose 20',
  'Test 7 — 100 Companies, Choose 20',
  'Test 8 — 100 Companies, Choose 20',
  'Test 9 — 100 Companies, Choose 20',
  'Test 10 — 100 Companies, Choose 20'
]

export const simulationStatuses = [
  'All Simulations',
  'Incomplete Simulations',
  'Finished With Results'
]

// Weightings
export const vcSimulationNonFinancialFactorWeightsArray = [
  {
    team: '25',
    ps: '10',
    diff: '15',
    market: '15',
    comm: '20',
    ar: '15',
    id: 't25p10d15m15c20a15',
  },
  {
    team: '30',
    ps: '20',
    diff: '15',
    market: '10',
    comm: '15',
    ar: '10',
    id: 't30p20d15m10c15a10',
  },
  {
    team: '20',
    ps: '15',
    diff: '20',
    market: '20',
    comm: '15',
    ar: '10',
    id: 't20p15d20m20c15a10',
  },
  {
    team: '15',
    ps: '15',
    diff: '20',
    market: '20',
    comm: '15',
    ar: '15',
    id: 't15p15d20m20c15a15',
  },
  {
    team: '15',
    ps: '15',
    diff: '15',
    market: '20',
    comm: '25',
    ar: '10',
    id: 't15p15d15m20c25a10',
  },
  {
    team: '25',
    ps: '10',
    diff: '10',
    market: '25',
    comm: '15',
    ar: '15',
    id: 't25p10d10m25c15a15',
  }
]

export const vcSimulationFinancialFactorWeightsArray = [
  {
    f: '10',
    nf: '90',
    id: 'f10nf90',
  },
  {
    f: '20',
    nf: '80',
    id: 'f20nf80',
  },
  {
    f: '30',
    nf: '70',
    id: 'f30nf70',
  },
  {
    f: '40',
    nf: '60',
    id: 'f40nf60',
  },
  {
    f: '50',
    nf: '50',
    id: 'f50nf50',
  },
  {
    f: '60',
    nf: '40',
    id: 'f60nf40',
  }
]

// Security Questions
export const securityQuestion1 = [
  'What were the last 5 digits of your childhood phone number?',
  'What was the name of your first pet?',
  'What was the name of your childhood best friend?'
]

export const securityQuestion2 = [
  'What is your mother\'s maiden name?',
  'What is the first name of your father\'s mother?',
  'What is the first name of your mother\'s mother?',
  'What is the first name of your father\'s father?',
  'What is the first name of your mother\'s father?'
]

export const securityQuestion3 = [
  'What was your high school\'s mascot?',
  'What was your university\'s mascot?',
  'What is your favorite color?',
  'Who was the first presidential candidate that you voted for?'
]

// Rates
export const monthlyRate = 12
export const yearlyRate = 3000
export const oneTimeRate = 300

// Subscription Types
export const subscriptionTypes = [
  {
    interval: 'one-time',
    rate: oneTimeRate,
    simulations: '10',
    freeTrial: false,
  },
  // {
  //   interval: 'month',
  //   rate: monthlyRate,
  //   freeTrial: true,
  // },
  {
    interval: 'year',
    rate: yearlyRate,
    simulations: 'Unlimited',
    freeTrial: true,
  }
]

export const exampleCompaniesObj = {
  exampleRulesChoose10: {
    totalOpportunities: 50,
    maxPortfolioCompanies: 10,
    maxFollowOnCompanies: 6,
    exampleCompaniesCount: 3,
    initialInvestmentAmount: 3,
    followOnInvestmentAmount: 3.33,
    fundSize: 62.5,
    feeBase: 62.5,
    amountToInvest: 50,
    cumulativeFeesAmount: 11.4625,
    cumulativeExpensesAmount: 1.0375,
    annualFundExpenses: 0.075,
    multipleForYear1FundExpenses: 2.5,
    month1AdditionalExpenses: 0.1,
    preCarryAnnualInterestRate: 0.08,
    preCarryMonthlyInterestRate: 0.006434,
    investmentPeriodMonths: 36,
    monthsFromInitialToFollowOn: 24,
    earliestExitMonthsAfterInitial: 36,
    fundLifeMonths: 120,
    feeForInvestmentPeriod: 0.02,
    feeForNonInvestmentPeriod: 0.0176,
    performanceFeePre3x: 0.2,
    performanceFeePost3x: 0.25,
    newFundraisingThreshold: 0.75,
    poorPacingMonthThreshold: 24,
    poorPacingExitMultipleDiscount: 0.2,
  },
  exampleRulesChoose20: {
    totalOpportunities: 100,
    maxPortfolioCompanies: 20,
    maxFollowOnCompanies: 12,
    exampleCompaniesCount: 3,
    initialInvestmentAmount: 3,
    followOnInvestmentAmount: 3.33,
    fundSize: 125,
    feeBase: 125,
    amountToInvest: 100,
    cumulativeFeesAmount: 22.925,
    cumulativeExpensesAmount: 2.075,
    annualFundExpenses: 0.15,
    multipleForYear1FundExpenses: 2.5,
    month1AdditionalExpenses: 0.2,
    preCarryAnnualInterestRate: 0.08,
    preCarryMonthlyInterestRate: 0.006434,
    investmentPeriodMonths: 36,
    monthsFromInitialToFollowOn: 24,
    earliestExitMonthsAfterInitial: 36,
    fundLifeMonths: 120,
    feeForInvestmentPeriod: 0.02,
    feeForNonInvestmentPeriod: 0.0176,
    performanceFeePre3x: 0.2,
    performanceFeePost3x: 0.25,
    newFundraisingThreshold: 0.75,
    poorPacingMonthThreshold: 24,
    poorPacingExitMultipleDiscount: 0.2,
  },
  exampleCompanies: [
    {
      id: 1,
      name: 'Window',
      description: 'Window is a property management startup that streamlines the rental experience for landlords and tenants through an intuitive digital platform, offering efficient communication, payment processing, and maintenance tracking.',
      initialInvestmentMonth: 3,
      followOnInvestmentMonth: 27,
      followOnInfo: {
        statusAtFollowOn: 'Flat',
        followOnPostMoneyValuation: 24,
        followOnRoundSize: 10,
      },
      team: {
        meanRaw: 1.0492,
        upperBoundRaw: 1.1152,
        lowerBoundRaw: 0.9892,
        meanPortfolioPercentile: 56.6486,
        upperBoundPortfolioPercentile: 65.5676,
        lowerBoundPortfolioPercentile: 48.8511,
        relationshipWithLeadership: {
          multiplier: 0.98,
          description: 'Positive Signs But Reasons for Doubt',
        },
        managementTechCapabilities: {
          multiplier: 1.06,
          description: 'Uniquely Strong',
        },
        culturalLeadership: {
          multiplier: 1,
          description: 'Fitting Backgrounds & Good Track Record',
        },
        networkingChops: {
          multiplier: 1,
          description: 'Fitting Backgrounds & Good Track Record',
        },
        boardAndAdvisors: {
          multiplier: 1.01,
          description: 'Engaged & Strong Backgrounds',
        },
        hiringDifficulty: {
          multiplier: 1,
          description: 'Strong Candidates Likely in 1-3 Months',
        },
        ceoReplacement: {
          multiplier: 1,
          description: 'Maybe In A Few Years',
        },
        strategicInvestor: {
          multiplier: 1,
          description: 'No Validated Strategic Interest',
        },
      },
      problemSolution: {
        meanRaw: 1.05,
        upperBoundRaw: 1.0863,
        lowerBoundRaw: 1.02,
        meanPortfolioPercentile: 75.0000,
        upperBoundPortfolioPercentile: 93.1500,
        lowerBoundPortfolioPercentile: 60.0000,
        problem: {
          multiplier: 1,
          description: 'Clear & Evident',
        },
        solution: {
          multiplier: 1.05,
          description: 'Clearly a Strong Solution',
        },
        sustainability: {
          multiplier: 1,
          description: 'Most Likely',
        },
      },
      differentiation: {
        meanRaw: 0.987,
        upperBoundRaw: 1.053,
        lowerBoundRaw: 0.927,
        meanPortfolioPercentile: 48.1894,
        upperBoundPortfolioPercentile: 62.3256,
        lowerBoundPortfolioPercentile: 39.8329,
        secretSauce: {
          multiplier: 1,
          description: 'Intuitive, but Validation Needed',
        },
        valueChain: {
          multiplier: 1,
          description: 'Yes for Most, But Unclear For Some',
        },
        ip: {
          multiplier: 0.94,
          description: 'Weak IP, No Clear Strategy',
        },
        landscape: {
          multiplier: 1.05,
          description: 'No Foreseen Competitors/Substitues',
        },
      },
      market: {
        meanRaw: 1.0098,
        upperBoundRaw: 1.0698,
        lowerBoundRaw: 0.9498,
        meanPortfolioPercentile: 52.1491,
        upperBoundPortfolioPercentile: 65.3070,
        lowerBoundPortfolioPercentile: 41.0357,
        size: {
          multiplier: 1.02,
          description: '$50B+',
        },
        accessibility: {
          multiplier: 1,
          description: 'Intuitively Accessible, But Validation Needed',
        },
        barriersForCompetitors: {
          multiplier: 1,
          description: 'Accessible to Those with Specific Skills & Resources',
        },
        nonCoreSize: {
          multiplier: 0.995,
          description: '$10B+',
        },
        secondaryMarketAccessibility: {
          multiplier: 1,
          description: 'Intuitively Accessible, But Validation Needed',
        },
        competitorsSecondaryMarketAccessibility: {
          multiplier: 0.995,
          description: 'Easily Accessible to Most Parties',
        },
      },
      commercialization: {
        meanRaw: 1.0763,
        upperBoundRaw: 1.1423,
        lowerBoundRaw: 1.0163,
        meanPortfolioPercentile: 70.4011,
        upperBoundPortfolioPercentile: 88.0481,
        lowerBoundPortfolioPercentile: 54.3583,
        scaleSpeed: {
          multiplier: 1.01,
          description: 'Fast Once Necessary Suppliers/Manufacturers Determined',
        },
        relationships: {
          multiplier: 1,
          description: 'Budding, Promising Early Signs',
        },
        productDevelopment: {
          multiplier: 1.05,
          description: 'Product Ready for Scale',
        },
        pricePoint: {
          multiplier: 1.02,
          description: 'Purchase Order Discussions & Validating Research',
        },
        branding: {
          multiplier: 0.995,
          description: 'Will Need to Hire, But Timing Logical',
        },
      },
      additionalRisks: {
        meanRaw: 0.7511,
        upperBoundRaw: 0.8111,
        lowerBoundRaw: 0.6805,
        meanPortfolioPercentile: 20.3690,
        upperBoundPortfolioPercentile: 27.5119,
        lowerBoundPortfolioPercentile: 11.9643,
        policyRisk: {
          multiplier: 0.85,
          description: 'Likely & Potentially Compromising The Company',
        },
        engineeringRisk: {
          multiplier: 0.94,
          description: '1-2 Major Questions Remain',
        },
        unknownRisk: {
          multiplier: 1,
          description: 'Similar To Past Deals',
        },
        otherRisk: {
          multiplier: 0.94,
          description: 'Yes, And Potentially Meaningful',
        },
      },
      financialExpectations: {
        initialPostMoneyValuation: 15,
        initialInvestmentOwnershipPercentage: {
          multiplier: 1,
          description: '20%',
          decimal: 0.2,
        },
        projectedYear1Revenues: {
          multiplier: 1.04,
          description: '$2.5M',
          value: 2.5,
        },
        projectedYear2Revenues: {
          multiplier: 1,
          description: '$30.0M',
          value: 30,
        },
        projectedYear3Revenues: {
          multiplier: 1,
          description: '$30.0M',
          value: 30,
        },
        projectedYear4Revenues: {
          multiplier: 1,
          description: '$50.0M',
          value: 50,
        },
        projectedYear5Revenues: {
          multiplier: 1,
          description: '$100.0M',
          value: 100,
        },
        projectedRevenuesPlateau: {
          multiplier: 0.99,
          description: '$400.0M',
          value: 400,
        },
        profitMarginAtPlateau: {
          multiplier: 1,
          description: '50%',
          decimal: 0.5,
        },
        anticipatedAdditionalInvestment: {
          multiplier: 0.99,
          description: '$50.0M',
          value: 50,
        },
      },
    },
    {
      id: 2,
      name: 'AllWheel',
      description: 'AllWheel is an electric vehicle startup that designs and manufactures high-performance all-wheel-drive electric cars, combining cutting-edge technology with sustainable and stylish transportation solutions.',
      initialInvestmentMonth: 8,
      followOnInvestmentMonth: 32,
      followOnInfo: {
        statusAtFollowOn: 'Strong Momentum',
        followOnPostMoneyValuation: 40,
        followOnRoundSize: 15,
      },
      team: {
        meanRaw: 1.1226,
        upperBoundRaw: 1.1952,
        lowerBoundRaw: 1.0626,
        meanPortfolioPercentile: 66.5676,
        upperBoundPortfolioPercentile: 76.3784,
        lowerBoundPortfolioPercentile: 58.4595,
        relationshipWithLeadership: {
          multiplier: 0.98,
          description: 'Positive Signs But Reasons for Doubt',
        },
        managementTechCapabilities: {
          multiplier: 1.06,
          description: 'Uniquely Strong',
        },
        culturalLeadership: {
          multiplier: 1.06,
          description: 'Uniquely Strong',
        },
        networkingChops: {
          multiplier: 0.98,
          description: 'Good Track Record but Reasons to Doubt',
        },
        boardAndAdvisors: {
          multiplier: 1.01,
          description: 'Engaged & Strong Backgrounds',
        },
        hiringDifficulty: {
          multiplier: 1,
          description: 'Strong Candidates Likely in 1-3 Months',
        },
        ceoReplacement: {
          multiplier: 1,
          description: 'Maybe In A Few Years',
        },
        strategicInvestor: {
          multiplier: 1.03,
          description: 'Yes, Strategic(s) on Board from Prior Round',
        },
      },
      problemSolution: {
        meanRaw: 1.05,
        upperBoundRaw: 1.0863,
        lowerBoundRaw: 1.02,
        meanPortfolioPercentile: 75.0000,
        upperBoundPortfolioPercentile: 93.1500,
        lowerBoundPortfolioPercentile: 60.0000,
        problem: {
          multiplier: 1,
          description: 'Clear & Evident',
        },
        solution: {
          multiplier: 1.05,
          description: 'Clearly a Strong Solution',
        },
        sustainability: {
          multiplier: 1,
          description: 'Most Likely',
        },
      },
      differentiation: {
        meanRaw: 0.94,
        upperBoundRaw: 1,
        lowerBoundRaw: 0.88,
        meanPortfolioPercentile: 41.6435,
        upperBoundPortfolioPercentile: 50.0000,
        lowerBoundPortfolioPercentile: 33.2869,
        secretSauce: {
          multiplier: 1,
          description: 'Intuitive, but Validation Needed',
        },
        valueChain: {
          multiplier: 1,
          description: 'Yes for Most, But Unclear For Some',
        },
        ip: {
          multiplier: 0.94,
          description: 'Weak IP, No Clear Strategy',
        },
        landscape: {
          multiplier: 1,
          description: 'Clear Competitors/Substitutes, But Not Many',
        },
      },
      market: {
        meanRaw: 0.9595,
        upperBoundRaw: 1.0195,
        lowerBoundRaw: 0.8995,
        meanPortfolioPercentile: 42.7679,
        upperBoundPortfolioPercentile: 54.2763,
        lowerBoundPortfolioPercentile: 32.0536,
        size: {
          multiplier: 1,
          description: '$20B+',
        },
        accessibility: {
          multiplier: 0.95,
          description: 'Unclear/Questionable',
        },
        barriersForCompetitors: {
          multiplier: 1,
          description: 'Accessible to Those with Specific Skills & Resources',
        },
        nonCoreSize: {
          multiplier: 1,
          description: '$20B+',
        },
        secondaryMarketAccessibility: {
          multiplier: 1,
          description: 'Intuitively Accessible, But Validation Needed',
        },
        competitorsSecondaryMarketAccessibility: {
          multiplier: 1.01,
          description: 'High Barriers, But Feasible to Overcome',
        },
      },
      commercialization: {
        meanRaw: 1.0149,
        upperBoundRaw: 1.0749,
        lowerBoundRaw: 0.9549,
        meanPortfolioPercentile: 53.9840,
        upperBoundPortfolioPercentile: 70.0267,
        lowerBoundPortfolioPercentile: 42.8413,
        scaleSpeed: {
          multiplier: 1,
          description: 'Will Require Substantial Hiring to Sell/Execute',
        },
        relationships: {
          multiplier: 1,
          description: 'Budding, Promising Early Signs',
        },
        productDevelopment: {
          multiplier: 1.02,
          description: 'Early Sales, Some Product Risk Still Present',
        },
        pricePoint: {
          multiplier: 1,
          description: 'Logical Based On Prices of Comparable Solutions',
        },
        branding: {
          multiplier: 0.995,
          description: 'Will Need to Hire, But Timing Logical',
        },
      },
      additionalRisks: {
        meanRaw: 0.9735,
        upperBoundRaw: 1.0395,
        lowerBoundRaw: 0.9135,
        meanPortfolioPercentile: 46.8452,
        upperBoundPortfolioPercentile: 62.5000,
        lowerBoundPortfolioPercentile: 39.7024,
        policyRisk: {
          multiplier: 0.9,
          description: 'Likely, Will Require Business Model Pivot If It Surfaces',
        },
        engineeringRisk: {
          multiplier: 1.02,
          description: 'Issues May Surface During Scaling',
        },
        unknownRisk: {
          multiplier: 1.01,
          description: 'More Straightforward Than Usual',
        },
        otherRisk: {
          multiplier: 1.05,
          description: 'Unlikely',
        },
      },
      financialExpectations: {
        initialPostMoneyValuation: 10,
        initialInvestmentOwnershipPercentage: {
          multiplier: 1.1,
          description: '30%',
          decimal: 0.3,
        },
        projectedYear1Revenues: {
          multiplier: .99,
          description: '$0.5M',
          value: 0.5,
        },
        projectedYear2Revenues: {
          multiplier: 1,
          description: '$2.0M',
          value: 2,
        },
        projectedYear3Revenues: {
          multiplier: 1,
          description: '$5.0M',
          value: 5,
        },
        projectedYear4Revenues: {
          multiplier: 1,
          description: '$10.0M',
          value: 10,
        },
        projectedYear5Revenues: {
          multiplier: 1,
          description: '$30.0M',
          value: 30,
        },
        projectedRevenuesPlateau: {
          multiplier: 1.03,
          description: '$20000.0M',
          value: 20000,
        },
        profitMarginAtPlateau: {
          multiplier: 1.02,
          description: '60%',
          decimal: 0.6,
        },
        anticipatedAdditionalInvestment: {
          multiplier: 0.99,
          description: '$50.0M',
          value: 50,
        },
      },
    },
    {
      id: 0,
      name: 'Chorizo',
      description: 'Chorizo is a food tech startup specializing in plant-based meat alternatives, offering a delicious and sustainable range of chorizo-style products to cater to the growing demand for ethical and tasty protein options.',
      initialInvestmentMonth: 20,
      followOnInvestmentMonth: 44,
      followOnInfo: {
        statusAtFollowOn: 'Surviving - Want Sim Cap Bridge Support',
        followOnPostMoneyValuation: 20,
        followOnRoundSize: 4,
      },
      team: {
        meanRaw: 1.0548,
        upperBoundRaw: 1.1274,
        lowerBoundRaw: 0.9903,
        meanPortfolioPercentile: 57.4054,
        upperBoundPortfolioPercentile: 67.2162,
        lowerBoundPortfolioPercentile: 48.9681,
        relationshipWithLeadership: {
          multiplier: 1,
          description: 'Most Likely a Strong Fit',
        },
        managementTechCapabilities: {
          multiplier: 1.06,
          description: 'Uniquely Strong',
        },
        culturalLeadership: {
          multiplier: 1.06,
          description: 'Uniquely Strong',
        },
        networkingChops: {
          multiplier: 1,
          description: 'Fitting Backgrounds & Good Track Record',
        },
        boardAndAdvisors: {
          multiplier: 0.98,
          description: 'Successful, But Unfitting Backgrounds',
        },
        hiringDifficulty: {
          multiplier: 1,
          description: 'Strong Candidates Likely in 1-3 Months',
        },
        ceoReplacement: {
          multiplier: 0.93,
          description: 'A Possibility in the Near Future (1-2 Years)',
        },
        strategicInvestor: {
          multiplier: 1.03,
          description: 'Yes, Strategic(s) (Co-)Leading This Round',
        },
      },
      problemSolution: {
        meanRaw: 0.924,
        upperBoundRaw: 0.9603,
        lowerBoundRaw: 0.8899,
        meanPortfolioPercentile: 37.3333,
        upperBoundPortfolioPercentile: 43.3833,
        lowerBoundPortfolioPercentile: 31.6500,
        problem: {
          multiplier: 1,
          description: 'Clear & Evident',
        },
        solution: {
          multiplier: 0.88,
          description: 'Questionable',
        },
        sustainability: {
          multiplier: 1.05,
          description: 'Yes',
        },
      },
      differentiation: {
        meanRaw: 0.9776,
        upperBoundRaw: 1.0436,
        lowerBoundRaw: 0.9176,
        meanPortfolioPercentile: 46.8802,
        upperBoundPortfolioPercentile: 60.1395,
        lowerBoundPortfolioPercentile: 38.5237,
        secretSauce: {
          multiplier: 0.95,
          description: 'Very Mixed',
        },
        valueChain: {
          multiplier: 0.98,
          description: 'Yes for Most, But Negative For Some',
        },
        ip: {
          multiplier: 1.05,
          description: 'Yes & Validated',
        },
        landscape: {
          multiplier: 1,
          description: 'Clear Competitors/Substitutes, But Not Many',
        },
      },
      market: {
        meanRaw: 0.9938,
        upperBoundRaw: 1.0538,
        lowerBoundRaw: 0.9338,
        meanPortfolioPercentile: 48.8929,
        upperBoundPortfolioPercentile: 61.7982,
        lowerBoundPortfolioPercentile: 38.1786,
        size: {
          multiplier: 1,
          description: '$20B+',
        },
        accessibility: {
          multiplier: 1.02,
          description: 'Strong Relationships, But Need More Revenue Traction',
        },
        barriersForCompetitors: {
          multiplier: 0.96,
          description: 'Easily Accessible to Most Parties',
        },
        nonCoreSize: {
          multiplier: 1.02,
          description: '$100B+',
        },
        secondaryMarketAccessibility: {
          multiplier: 1,
          description: 'Intuitively Accessible, But Validation Needed',
        },
        competitorsSecondaryMarketAccessibility: {
          multiplier: 0.995,
          description: 'Easily Accessible to Most Parties',
        },
      },
      commercialization: {
        meanRaw: 1.0208,
        upperBoundRaw: 1.0934,
        lowerBoundRaw: 0.9563,
        meanPortfolioPercentile: 55.5615,
        upperBoundPortfolioPercentile: 74.9733,
        lowerBoundPortfolioPercentile: 43.0635,
        scaleSpeed: {
          multiplier: 0.93,
          description: 'Will Require Substantial Capital, Manufacturing, Supply Chain Development, and Hiring to Achieve Scale',
        },
        relationships: {
          multiplier: 1.03,
          description: 'Strong, Many Already Developed',
        },
        productDevelopment: {
          multiplier: 1.05,
          description: 'Product Ready for Scale',
        },
        pricePoint: {
          multiplier: 1.02,
          description: 'Purchase Order Discussions & Validating Research',
        },
        branding: {
          multiplier: 0.995,
          description: 'Will Need to Hire, But Timing Logical',
        },
      },
      additionalRisks: {
        meanRaw: 0.9779,
        upperBoundRaw: 1.0439,
        lowerBoundRaw: 0.9179,
        meanPortfolioPercentile: 47.3690,
        upperBoundPortfolioPercentile: 63.8924,
        lowerBoundPortfolioPercentile: 40.2262,
        policyRisk: {
          multiplier: 1,
          description: 'Unlikely, But Manageable If It Surfaces',
        },
        engineeringRisk: {
          multiplier: 0.94,
          description: '1-2 Major Questions Remain',
        },
        unknownRisk: {
          multiplier: 1.03,
          description: 'Very Simple Set of Factors',
        },
        otherRisk: {
          multiplier: 1.01,
          description: 'Perhaps, But Unsure What',
        },
      },
      financialExpectations: {
        initialPostMoneyValuation: 20,
        initialInvestmentOwnershipPercentage: {
          multiplier: 0.95,
          description: '15%',
          decimal: 0.15,
        },
        projectedYear1Revenues: {
          multiplier: .99,
          description: '$0.5M',
          value: 0.5,
        },
        projectedYear2Revenues: {
          multiplier: 1,
          description: '$10.0M',
          value: 10,
        },
        projectedYear3Revenues: {
          multiplier: 1,
          description: '$10.0M',
          value: 10,
        },
        projectedYear4Revenues: {
          multiplier: 1,
          description: '$20.0M',
          value: 20,
        },
        projectedYear5Revenues: {
          multiplier: 1,
          description: '$50.0M',
          value: 50,
        },
        projectedRevenuesPlateau: {
          multiplier: 0.97,
          description: '$200.0M',
          value: 200,
        },
        profitMarginAtPlateau: {
          multiplier: 1,
          description: '50%',
          decimal: 0.5,
        },
        anticipatedAdditionalInvestment: {
          multiplier: 0.97,
          description: '$100.0M',
          value: 100,
        },
      },
    }
  ],
}

// Permitted Country Codes — https://www.iban.com/country-codes
export const permittedCountryCodes = [
  'US', // United States
  'FR', // France
  'AU', // Australia
  'AT', // Austria
  'BE', // Belgium
  'CA', // Canada
  'DK', // Denmark
  'FI', // Finland
  'DE', // Germany
  'GR', // Greece
  'HK', // Hong Kong
  'IE', // Ireland
  'IL', // Israel
  'IT', // Italy
  'JP', // Japan
  'LI', // Liechtenstein
  'LU', // Luxembourg
  'MX', // Mexico
  'NL', // The Netherlands
  'NZ', // New Zealand
  'NO', // Norwary
  'PL', // Poland
  'PT', // Portugal
  'PR', // Puerto Rico
  'SG', // Singapore
  'ES', // Spain
  'SE', // Sweden
  'CH', // Switzerland
  'GB', // Great Britain

  'AF', // Afghanistan
  'AL', // Albania
  'DZ', // Algeria
  'AM', // American Samoa
  'AD', // Andorra
  'AO', // Angola
  'AI', // Anguilla
  'AQ', // Antarctica
  'AG', // Antigua and Barbuda
  'AR', // Argentina
  'AM', // Armenia
  'AW', // Aruba
  'AZ', // Azerbaijan
  'BS', // The Bahamas
  'BH', // Bahrain
  'BD', // Bangladesh
  'BB', // Barbados
  'BY', // Belarus
  'BZ', // Belize
  'BJ', // Benin
  'BM', // Bermuda
  'BT', // Bhutan
  'BO', // Bolivia
  'BQ', // Bonaire
  'BA', // Bosnia and Herzegovina
  'BW', // Botswana
  'BV', // Bouvet Island
  'IO', // British Indian Ocean Territory
  'BN', // Brunei
  'BG', // Bulgaria
  'BF', // Burkina Faso
  'BI', // Burundi
  'CV', // Cabo Verde
  'KH', // Cambodia
  'CM', // Camaroon
  'KY', // the Cayman Islands
  'CF', // Central African Republic
  'TD', // Chad
  'CL', // Chile
  'CN', // China
  'CX', // Christmas Island
  'CC', // The Cocos Islands
  'CO', // Colombia
  'KM', // The Comoros
  'CD', // Democratic Republic of Congo
  'CG', // Congo
  'CK', // The Cook Islands
  'CR', // Costa Rica
  'HR', // Croatia
  'CU', // Cuba
  'CW', // Curacao
  'CY', // Cyprus
  'CZ', // Czech Republic
  'CI', // Ivory Coast
  'DJ', // Djibouti
  'DM', // Dominica
  'DO', // The Dominican Republic
  'EC', // Ecuador
  'EG', // Egypt
  'SV', // El Salvador
  'GQ', // Equatorial Guinea
  'ER', // Eritrea
  'EE', // Estonia
  'SZ', // Eswatini
  'ET', // Ethiopia
  'FK', // Falkland Islands
  'FO', // Faroe Islands
  'FJ', // Fiji
  'GF', // French Guiana
  'PF', // French Polynesia
  'TF', // French Southern Territories
  'GA', // Gabon
  'GM', // Gambia
  'GE', // Georgia
  'GH', // Ghana
  'GI', // Gibraltar
  'GL', // Greenland
  'GD', // Grenada
  'GP', // Guadeloupe
  'GU', // Guam
  'GT', // Guatemala
  'GG', // Guernsey
  'GN', // Guinea
  'GW', // Guinea-Bissau
  'GY', // Guyana
  'HT', // Haiti
  'HM', // Heard Island and McDonald Islands
  'VA', // The Holy See
  'HN', // Honduras
  'HU', // Hungary
  'IS', // Iceland
  'IN', // India
  'ID', // Indonesia
  'IR', // Iran
  'IQ', // Iraq
  'IM', // Isle of Man
  'JM', // Jamaica
  'JE', // Jersey
  'JO', // Jordan
  'KZ', // Kazakhstan
  'KE', // Kenya
  'KI', // Kiribati
  // 'KP', // North Korea
  'KR', // South Korea
  'KW', // Kuwait
  'KG', // Kyrgyzstan
  'LA', // Laos
  'LV', // Latvia
  'LB', // Lebanon
  'LS', // Lesotho
  'LR', // Liberia
  'LY', // Libya
  'LT', // Lithuania
  'MO', // Macao
  'MG', // Madagascar
  'MW', // Malawi
  'MY', // Malaysia
  'MV', // The Maldives
  'ML', // Mali
  'MT', // Malta
  'MH', // The Marshall Islands
  'MQ', // Martinique
  'MR', // Mauritania
  'MU', // Mauritius
  'YT', // Mayotte
  'FM', // Micronesia
  'MD', // Maldova
  'MC', // Monaco
  'MN', // Mongolia
  'ME', // Montenegro
  'MS', // Montserrat
  'MA', // Morocco
  'MZ', // Mozambique
  'MM', // Myanmar
  'NA', // Namibia
  'NR', // Nauru
  'NP', // Nepal
  'NC', // New Caledonia
  'NI', // Nicaragua
  'NE', // Niger
  'NG', // Nigeria
  'NU', // Niue
  'NF', // Norfolk Island
  'MP', // Northern Mariana Islands
  'OM', // Oman
  'PK', // Pakistan
  'PW', // Palau
  'PS', // Palestine
  'PA', // Panama
  'PG', // Papua New Guinea
  'PY', // Paraguay
  'PE', // Peru
  'PH', // The Philippines
  'PN', // Pitcairn
  'QA', // Qatar
  'MK', // Macedonia
  'RO', // Romania
  // 'RU', // Russia
  'RW', // Rwanda
  'RE', // Reunion
  'BL', // Saint Barthelemy
  'SH', // Saint Helena
  'KN', // Saint Kitts and Nevis
  'LC', // Saint Lucia
  'MF', // Saint Martin
  'PM', // Saint Pierre and Miquelon
  'VC', // Saint Vincent and the Grenadines
  'WS', // Samoa
  'SM', // San Marino
  'ST', // Sao Tome and Principe
  'SA', // Saudi Arabia
  'SN', // Senegal
  'RS', // Serbia
  'SC', // Seychelles
  'SL', // Sierra Leone
  'SX', // Sint Maarten
  'SK', // Slovakia
  'SI', // Slovenia
  'SB', // Solomon Islands
  'SO', // Somalia
  'ZA', // South Africa
  'GS', // South Georgia and the Sandwich Islands
  'SS', // South Sudan
  'LK', // Sri Lanka
  'SD', // Sudan
  'SR', // Suriname
  'SJ', // Svalbard
  'SY', // Syria
  'TW', // Taiwan
  'TJ', // Tajikistan
  'TZ', // Tanzania
  'TH', // Thailand
  'TL', // Timor-Leste
  'TG', // Togo
  'TK', // Tokelau
  'TO', // Tonga
  'TT', // Trinidad and Tobago
  'TN', // Tunisia
  'TR', // Turkey
  'TM', // Turkmenistan
  'TC', // Turks and Caicos
  'TV', // Tuvalu
  'UG', // Uganda
  'UA', // Ukraine
  'AE', // United Arab Emirates
  'UM', // United States Minor Outlying Islands
  'UY', // Uruguay
  'UZ', // Uzbekistan
  'VU', // Vanuatu
  'VE', // Venezuela
  'VN', // Vietnam
  'VG', // British Virgin Islands
  'VI', // US Virgin Islands
  'WF', // Wallis and Futuna
  'EH', // Western Sahara
  'YE', // Yemen
  'ZM', // Zambia
  'ZW', // Zimbabwe
  'AX', // Aland Islands
  ''
]