/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

import useWindowDimensions from '../../../helpers/windowDimensions'
import { standardButton, exitModalIconButton } from '../../../helpers/buttons'
import { standardSpinner } from '../../../helpers/spinners'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated } from '../../../helpers/storage'
import { simulationsSortByArray, positionChangeWidthSm, positionChangeWidthMd, simcapPink, simcapTurquoise, navbarHeight, footerHeight, dashboardNavbarHeight, vcSimulationNonFinancialFactorWeightsArray, vcSimulationFinancialFactorWeightsArray } from '../../../helpers/variableDefaults'
import { couldNotLoadPageError } from '../../../helpers/errors'
import { seoPageTags, customAnalyticsEvent } from '../../../helpers/analytics'
import { userProfilieSectionTitle } from '../../../helpers/settingsElements'
import { capitalizeFirstLetter, dummyBoxWidth, reloadView } from '../../../helpers/globalHelpers'
import { standardErrorContainer } from '../../../helpers/errors'

// Customize Page
const Customize = () => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()
  
  // Navigate
  const navigate = useNavigate()

  // Params
  const { userId } = useParams()

  // Payload
  const payload = getPayload()

  // User
  const [user, setUser] = useState({})

  // Customizations
  const [customizations, setCustomizations] = useState({
    nonFinancialFactorWeights: '',
    financialToNonFinancialWeights: '',
  })

  // Loading
  const [loading, setLoading] = useState(false)

  // Errors
  const [errors, setErrors] = useState(false)
  const [uploadError, setUploadError] = useState(false)

  // UseEffect — Send to user profile view if User is not a Person in the estate
  useEffect(() => {
    // console.log('userId ->', userId)
    // console.log('payload.sub ->', payload.sub)

    if (!userIsAuthenticated()) {
      navigate('/')
    } else if (userId !== payload.sub) {
      navigate(`/dashboard/${payload.sub}`)
      // reloadView()
    } else {
      const getData = async () => {
        setLoading(true)
        try {
          const { data: retrievedUser } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/users/${payload.sub}/not-populated`, {
            headers: {
              Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            },
          })
          // console.log('retrieved User ->', retrievedUser)


          if (payload.sub === retrievedUser._id) {
            // console.log('userId === retrievedUser._id ->', userId === retrievedUser._id )
            // Set States
            setUser(retrievedUser)
            // console.log('user.hasOwnProperty(customizations) ->', retrievedUser.hasOwnProperty('customizations'))
            // console.log('user.customizations.hasOwnProperty(vcWeightings) ->', retrievedUser.customizations.hasOwnProperty('vcWeightings'))
            // console.log('user.customizations.vcWeightings.length > 0 ->', retrievedUser.customizations.vcWeightings.length > 0)

            if (retrievedUser.hasOwnProperty('customizations') && retrievedUser.customizations.hasOwnProperty('vcWeightings') && retrievedUser.customizations.vcWeightings.length > 0) {
              // console.log('user.customizations.vcWeightings.substring(0, user.customizations.vcWeightings.length - 7) ->', retrievedUser.customizations.vcWeightings.substring(0, retrievedUser.customizations.vcWeightings.length - 7))
              // console.log('user.customizations.vcWeightings.substring(user.customizations.vcWeightings.length - 7) ->', retrievedUser.customizations.vcWeightings.substring(retrievedUser.customizations.vcWeightings.length - 7))
              setCustomizations({
                nonFinancialFactorWeights: retrievedUser.customizations.vcWeightings.substring(0, retrievedUser.customizations.vcWeightings.length - 7),
                financialToNonFinancialWeights: retrievedUser.customizations.vcWeightings.substring(retrievedUser.customizations.vcWeightings.length - 7),
              })
            }
            
            
            setErrors(false)
            setLoading(false)
          } else {
            navigate(`/`)
            setErrors(false)
            setLoading(false)
          }

        } catch (error) {
          // console.log(error)

          navigate(`/`)

          setErrors(true)
          setLoading(false)
        }
      }

      getData()
    }

  }, [])

  useEffect(() => {
    // console.log('useEffect Runs')
    // console.log('customizations ->', customizations)

  }, [customizations])

  const handleNonFinancialFactorWeightsPressed = async (e, index) => {
    // console.log('handleNonFinancialFactorWeightsPressed runs')
    if (user.subscriptionIsActive) {
      setCustomizations({ 
        ...customizations, 
        nonFinancialFactorWeights: vcSimulationNonFinancialFactorWeightsArray[index].id,
      })
    }
  }

  const handleFinancialToNonFinancialWeightsPressed = async (e, index) => {
    // console.log('handleFinancialToNonFinancialWeightsPressed runs')
    if (user.subscriptionIsActive) {
      setCustomizations({ 
        ...customizations, 
        financialToNonFinancialWeights: vcSimulationFinancialFactorWeightsArray[index].id,
      })
    }
  }

  const handleCancelPressed = () => {
    // console.log('handleCancelPressed runs')
    setUploadError(false)
    setCustomizations({
      nonFinancialFactorWeights: user.customizations.vcWeightings.substring(0, user.customizations.vcWeightings.length - 7),
      financialToNonFinancialWeights: user.customizations.vcWeightings.substring(user.customizations.vcWeightings.length - 7),
    })
  }

  const handleSavePressed = async () => {
    console.log('handleSavePressed runs')
    const objectToUpload = {
      customizations: {
        vcWeightings: customizations.nonFinancialFactorWeights + customizations.financialToNonFinancialWeights,
      },
    }
    console.log('objectToUpload ->', objectToUpload)

    // Upload Modifications
    if (objectToUpload.customizations.vcWeightings !== user.customizations.vcWeightings) {
      console.log('attempting upload')
      setLoading(true)
      setUploadError(false)
      
      try {
        // PUT request with updated profile object
        // await axios.put(`/api/users/${userId}`, newForm, {
        await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/users/${userId}`, objectToUpload, {
          headers: {
            Authorization: `Bearer ${getTokenFromLocalStorage()}`,
          },
        })

        setUser({ ...user, customizations: objectToUpload.customizations })

        setLoading(false)
        setUploadError(false)
        
      } catch (error) {
        console.log(error)
        setUploadError(true)
        setLoading(false)
      }
    }
  }

  const saveAndCancelButtonsJSX = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        {standardButton(
          'Cancel', 
          'button',
          'contained',
          false,
          'warning', 
          0,
          errors ? 0 : 2,
          2,
          '80px',
          '45px',
          handleCancelPressed
        )}
        {standardButton(
          'Save', 
          'button',
          'contained',
          customizations.nonFinancialFactorWeights + customizations.financialToNonFinancialWeights === user.customizations.vcWeightings,
          'primary', 
          0,
          errors ? 0 : 2,
          2,
          '80px',
          '45px',
          handleSavePressed
        )}
      </Box>
    )
  }
  const weightingsElementJSX = (factor, weighting, index) => {
    return (
      <Box
        key={index}
        sx={{
          px: 2,
          width: '100%',
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
        }}
      >
        {/* Factor */}
        <Typography 
          // textAlign={'left'}
          sx={{
            pt: 0, pb: 0,
            // fontSize: '14px',
            // fontStyle: 'italic',
            // color: 'blue',
            fontWeight: 'bold',
          }}
        >
          {factor.length > 2 ? capitalizeFirstLetter(factor) : factor.toUpperCase()}
        </Typography>

        {/* Weighting */}
        <Typography 
          // textAlign={'left'}
          sx={{
            pt: 0, pb: 0,
            // fontSize: '14px',
            // fontStyle: 'italic',
            fontWeight: 'bold',
            color: weighting.includes('+') ? 'blue' : weighting.includes('-') || weighting.includes('—') ? 'red' : 'black',
          }}
        >
          {weighting}
        </Typography>
      </Box>
    )
  }

  const nfWeightingsJSX = (weightsObj, index, clickHandler) => {
    
    const allFactorsArray = Object.keys(weightsObj)
    const factorsArray = allFactorsArray.filter(factor => factor !== 'id')

    return (
      <Box 
        key={index}
        onClick={(e) => clickHandler(e, index)} 
        sx={{ 
          backgroundColor: 'white',
          mt: index > 2 ? 2 : 0,
          // pl: 3, pr: 3, 
          pt: 1, pb: 1,
          width: '30%',
          borderRadius: '15px',
          boxShadow: 3,
          border: (customizations.nonFinancialFactorWeights === weightsObj.id || customizations.financialToNonFinancialWeights === weightsObj.id) ? `3px solid ${user.subscriptionIsActive ? simcapPink : 'lightgray'}` : '',
          cursor: user.subscriptionIsActive ? 'pointer' : '',
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
      >
        {factorsArray.length > 0 && factorsArray.map((factor, factorIndex) => (
          weightingsElementJSX(width < 450 ? factor.substring(0,1) : factor, weightsObj[factor], factorIndex)
        ))}
      </Box>
    )
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'My Customizations'
      )}

      {/* Body */}
      {loading ?
        <Box sx={{ 
          width: '100%',
          // minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight} - ${dashboardNavbarHeight})`,
          minHeight: `calc(100vh - ${navbarHeight} - ${dashboardNavbarHeight})`,
          mt: 0, mb: 0,
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
        }}>
          {standardSpinner()}
        </Box>
        :
        errors ? 
          <Box
            sx={{
              // backgroundColor: 'yellow',
              width: '100%',
              minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight} - ${dashboardNavbarHeight})`,
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
            }}
          >
            {couldNotLoadPageError()}
          </Box>
          :
          <Box
            sx={{
              // backgroundColor: 'green',
              pt: 3, pb: 4,
              width: '100%',
              minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight} - ${dashboardNavbarHeight})`,
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
            }}
          > 
            {/* Customize Title */}
            {/* <Typography
              sx={{
                // backgroundColor: 'yellow',
                mb: width < positionChangeWidthSm ? 0 : user.entityType === 'Individual' ? -1 : -2,
                fontSize: '24px',
                fontWeight: 'bold',
              }}
            >
              Customize
            </Typography> */}

            {/* Save and Cancel Buttons */}
            {user && user.hasOwnProperty('customizations') && user.customizations.hasOwnProperty('vcWeightings') && user.customizations.vcWeightings.length > 0 && customizations.nonFinancialFactorWeights + customizations.financialToNonFinancialWeights !== user.customizations.vcWeightings &&
              saveAndCancelButtonsJSX()
            }
            {/* Error Message */}
            {uploadError && 
              standardErrorContainer(
                '*Could not upload changes. Check Internet connection and try again.',
                2,
                0
              )
            }

            {/* Non-Financial Weightings */}
            <Box sx={{ mt: width < positionChangeWidthSm ? 0 : 0, mb: 0, pb: 4, width: '90%', maxWidth: positionChangeWidthSm }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                {userProfilieSectionTitle('Preferred Weightings — Non-Financial Factors')}
              </Box>
              <Box
                sx={{
                  width: '100%',
                  fontSize: '14px',
                  fontStyle: 'italic',
                  // color: 'blue',
                  display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >
                {`*Team, Problem/Solution, Differentiation, Market, Commercialization, Additional Risks`}
              </Box>
              <Box 
                sx={{
                  backgroundColor: 'whitesmoke',
                  mt: 1,
                  pl: 3, pr: 3, pt: 2, pb: 2,
                  borderRadius: '5px',
                  boxShadow: 3,
                  border: 2,
                  borderColor: 'black',
                  display: 'flex', flexDirection: 'row', alignItems: 'center',
                  justifyContent: vcSimulationNonFinancialFactorWeightsArray.length > 2 ? 'space-between' : 'space-around', 
                  flexWrap: 'wrap',
                }}
              >
                {vcSimulationNonFinancialFactorWeightsArray.map((weightingsObj, weightingsObjIndex) => (
                  nfWeightingsJSX(weightingsObj, weightingsObjIndex, handleNonFinancialFactorWeightsPressed)
                ))}

                {vcSimulationNonFinancialFactorWeightsArray.length % 3 === 2 &&
                  dummyBoxWidth('30%')
                }
                
              </Box>
            </Box>

            {/* Financial to Non-Financial Weightings */}
            <Box sx={{ mt: width < positionChangeWidthSm ? 0 : 0, mb: 0, pb: 4, width: '90%', maxWidth: positionChangeWidthSm }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                {userProfilieSectionTitle('Preferred Weightings — Financial-to-Non-Financial Factors')}
              </Box>
              <Box
                sx={{
                  width: '100%',
                  fontSize: '14px',
                  fontStyle: 'italic',
                  // color: 'blue',
                  display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >
                {`*Financial Factors, Non-Financial Factors`}
              </Box>
              <Box 
                sx={{
                  backgroundColor: 'whitesmoke',
                  mt: 1,
                  pl: 3, pr: 3, pt: 2, pb: 2,
                  borderRadius: '5px',
                  boxShadow: 3,
                  border: 2,
                  borderColor: 'black',
                  display: 'flex', flexDirection: 'row', alignItems: 'center',
                  justifyContent: vcSimulationNonFinancialFactorWeightsArray.length > 2 ? 'space-between' : 'space-around', 
                  flexWrap: 'wrap',
                }}
              >
                {vcSimulationFinancialFactorWeightsArray.map((weightingsObj, weightingsObjIndex) => (
                  nfWeightingsJSX(weightingsObj, weightingsObjIndex, handleFinancialToNonFinancialWeightsPressed)
                ))}

                {vcSimulationFinancialFactorWeightsArray.length % 3 === 2 &&
                  dummyBoxWidth('30%')
                }
                
              </Box>
            </Box>



            {/* Save and Cancel Buttons */}
            {user && user.hasOwnProperty('customizations') && user.customizations.hasOwnProperty('vcWeightings') && user.customizations.vcWeightings.length > 0 && customizations.nonFinancialFactorWeights + customizations.financialToNonFinancialWeights !== user.customizations.vcWeightings &&
              saveAndCancelButtonsJSX()
            }
            {/* Error Message */}
            {uploadError && 
              standardErrorContainer(
                '*Could not upload changes. Check Internet connection and try again.',
                2,
                0
              )
            }
            
          </Box>
      }
    </>
  )
}

export default Customize