/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */
import React from 'react'
// mui
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { NumericFormatCustom } from './formatting'

import { handleChange } from './globalHelpers'
import { positionChangeWidthSm } from './variableDefaults'


// Login/Register process TextFields (username, email, password, passwordConfirmation) in inProcessLoginRegister.js
export const loginTextField = (name, label, value, variant, required, fullWidth, autoFocus, autoComplete, form, setForm, setErrors, changeHandler ) => {
  return (
    <TextField
      required={required}
      fullWidth={fullWidth}
      label={label}
      variant={variant}
      name={name}
      type={name.includes('password') || name.includes('Password') || name.includes('security') || name.includes('Security') ? 'password' : ''}
      autoFocus={autoFocus}
      value={value} 
      onChange={(e) => changeHandler(e, setErrors, setForm, form)}
      inputProps={{ 
        style: { textTransform: name.includes('password') ? 'none' : 'none' }, 
        autoComplete: 'new-password',
        form: {
          autoComplete: 'off',
        },
      }}
      sx={{
        backgroundColor: 'white',
      }}
    />
  )
}

// Textfield for inputting the recipient email address in ResetPassword.js
export const emailTextField = (name, value, placeholder, variant, clickHandler, state, setState, width) => {
  return (
    <TextField 
      variant={variant}
      name={name}
      maxLength={150}
      aria-label={name}
      placeholder={placeholder}
      autoComplete='off'
      value={value}
      onChange={(e) => clickHandler(e, state, setState)}
      color="primary"
      sx={{ 
        // backgroundColor: 'yellow',
        // width: width >= 440 ? 'calc(100% - 120px)' : '95%',
        width: '100%',
        // maxWidth: '325px', 
        // ml: '45px', 
        mt: 1,
      }}
    />
  )
}

// Textfield for inputting name and email address when creating a new simulation
export const newSimulationTextField = (name, value, placeholder, variant, state, setState, setErrors, size, width, index) => {
  
  const handleNewSimRecipientChange = (e, setErrors, setFormData, formData, index) => {
    const { name, value } = e.target
    // console.log('name ->', name)
    // console.log('value ->', value)

    const recipients = [ ...formData.recipients ]
    recipients[index] = { ...formData.recipients[index], [name]: (name === 'email' || name === 'emailOfTaker') ? value.toLowerCase() : value }
  
    setErrors(false)
    setFormData({ ...formData, recipients: recipients })
    
  }

  return (
    <TextField 
      variant={variant}
      name={name}
      maxLength={150}
      label={placeholder}
      // focused={true}
      aria-label={name}
      placeholder={placeholder}
      autoComplete='off'
      // size={size}
      value={value}
      onChange={(e) => handleNewSimRecipientChange(e, setErrors, setState, state, index)}
      color="primary"
      sx={{ 
        backgroundColor: 'white',
        // width: width >= 440 ? 'calc(100% - 120px)' : '95%',
        // width: width < positionChangeWidthSm ? '90%' : 'calc(48% - 40px)', 
        width: width < positionChangeWidthSm ? '90%' : state.recipients.length <= 1 ? '48%' : '45%', 
        maxWidth: state.recipients.length <= 1 ? '290px' : '260px',
        // maxWidth: '325px', 
        // ml: '45px', 
        mt: 1,
      }}
    />
  )
}

// Textfield for inputting the reason for a decision
export const reasonForDecisionTextField = (name, value, label, placeholder, focused, variant, state, setState, setErrors, rows, width) => {
  
  return (
    <TextField 
      variant={variant}
      focused={focused}
      name={name}
      label={label}
      maxLength={150}
      aria-label={name}
      placeholder={placeholder}
      multiline
      autoComplete='off'
      rows={rows}
      value={value}
      onChange={(e) => handleChange(e, setErrors, setState, state)}
      color="primary"
      sx={{ 
        // backgroundColor: 'yellow',
        // width: width >= 440 ? 'calc(100% - 120px)' : '95%',
        width: '100%',
        // maxWidth: '325px', 
        // ml: '45px', 
        mt: 1,
      }}
    />
  )
}

// Textfield for searching name and email address in user dashboard
export const searchTextField = (name, value, placeholder, variant, state, setState, clickHandler, size, width) => {
  
  return (
    <TextField 
      variant={variant}
      name={name}
      maxLength={150}
      aria-label={name}
      placeholder={placeholder}
      autoComplete='off'
      size={size}
      value={value}
      onChange={(e) => clickHandler(e, state, setState)}
      color="primary"
      sx={{ 
        // backgroundColor: 'yellow',
        // width: width >= 440 ? 'calc(100% - 120px)' : '95%',
        width: '100%',
        // maxWidth: '325px', 
        // ml: '45px', 
        mt: 1,
      }}
    />
  )
}

// For answering Security Questions in inProcessLoginRegister.js
export const securityQuestionAnswerTextField = (name, label, value, disabled, variant, required, fullWidth, autoFocus, autoComplete, form, setForm, changeHandler, questionIndex ) => {
  return (
    <TextField
      required={required}
      fullWidth={fullWidth}
      disabled={disabled}
      label={label}
      variant={variant}
      name={name}
      // type={name.includes('password') || name.includes('Password') || name.includes('security') || name.includes('Security') ? 'password' : ''}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      value={value} 
      onChange={(e) => changeHandler(e, questionIndex, form, setForm)}
      inputProps={{ 
        style: { textTransform: name.includes('password') ? 'none' : 'none' }, 
        autoComplete: 'off',
        form: {
          autoComplete: 'off',
        },
      }}
      sx={{
        backgroundColor: 'white',
      }}
    />
  )
}

// Textfield for inputting the security code in Security.js during the unwrap process
export const securityCodeTextField = (name, label, disabled, autoComplete, autoFocus, changeHandler) => {
  return (
    <TextField
      margin="normal"
      disabled={disabled}
      required
      fullWidth
      id={name}
      label={label}
      name={name}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      onChange={(e) => changeHandler(e.target.value)}
    />
  )
}

export const onlyNumbersItemTextField = (name, label, value, placeholder, focused, changeHandler, setForm, form, itemIndex ) => {
  return (
    <TextField
      focused={focused}
      label={label}
      type={'number'}
      autoComplete='off'
      variant='outlined'
      placeholder={placeholder}
      name={name}
      value={value}
      minRows={1}
      maxRows={1}
      inputProps={{ maxLength: 75 }}
      size={'small'}
      onChange={(e) => changeHandler(e, setForm, form, itemIndex)}
      sx={{ 
        width: '90%', 
        textOverflow: 'ellipsis', 
        '.MuiInputBase-input': { 
          fontSize: '1rem', 
        }, 
      }}
    />
  )
}

// Mission Textfield, appears in the Mission.js file, for writing a longer mission statement during the onboarding
export const descriptionTextField = (name, value, label, rows, placeholder, changeHandler, setForm, form, marginTop) => {
  const upperCaseName = name.charAt(0).toUpperCase() + name.slice(1)

  return (
    <TextField
      focused={true}
      label={`${label}`}
      type= 'text'
      autoComplete='off'
      variant='outlined'
      placeholder={placeholder}
      name={name}
      value={value}
      multiline
      rows={rows}
      maxLength={1000}
      onChange={(e) => changeHandler(e, setForm, form)}
      sx={{ 
        mt: marginTop,
        backgroundColor: 'white',
        width: '90%', 
        textOverflow: 'ellipsis', 
        '.MuiInputBase-input': { 
          fontSize: '1rem', 
        }, 
      }}
    />
  )
}