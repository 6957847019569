/* eslint-disable no-prototype-builtins */
import * as React from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'

import { positionChangeHeightSm, positionChangeWidthMd, positionChangeWidthSm, simcapPink, simulationVersionsBusinessesArray } from './variableDefaults'
import { standardButton, deleteRecipientIconButton, exitModalIconButton } from './buttons'
import { modifyInDatabase } from './modifyInDatabase'
import { newSimulationTextField } from './textfields'
import { validEmail, noDuplicatesInArray } from './globalHelpers'
import { standardErrorContainer } from './errors'
import { getTokenFromLocalStorage } from './storage'
import { selectElementHandleChange } from './selects'


export const newSimulationModal = (newSimulationObj, setNewSimulationObj, open, setOpen, simulationCreated, setSimulationCreated, newSimulationErrors, setNewSimulationErrors, user, setUser, setFilteredSimulations, width) => {

  let addedSimulation = {}

  const handleClose = () => {
    // console.log('handleClose runs ->')
    // console.log('open ->', open)
    setSimulationCreated(false)
    setNewSimulationErrors(false)
    setNewSimulationObj({
      recipients: [
        {
          nameOfTaker: '',
          emailOfTaker: '',
        }
      ],
      simulationVersion: '',
    })
    setOpen(false)
  }

  const handleCreate = async () => {
    // console.log('handleContinue runs')

    try {
      // console.log('newSimulationObj ->', newSimulationObj)
      const { data: addedSimulationData } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/vc-simulations/`, newSimulationObj, {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      })

      addedSimulation = addedSimulationData
      // console.log('addedSimulation ->', addedSimulation)

      const allSimulations = [ ...user.vcSimulations, ...addedSimulationData ]
      // allSimulations.push(addedSimulation)
      // console.log('allSimulations ->', allSimulations)
      setUser({ ...user, vcSimulations: allSimulations })
      // console.log('user sets')
      setFilteredSimulations(allSimulations)
      // console.log('filtered simulations sets')
      setSimulationCreated(true)
      setNewSimulationErrors(false)

    } catch (err) {
      // console.log('err: ', err)

      setSimulationCreated(false)
      setNewSimulationErrors(true)
    }
    
  }

  const allRecipientsAdded = () => {
    // console.log('newSimulationObj.recipients ->', newSimulationObj.recipients)
    // console.log('number of unfinished recipients ->', newSimulationObj.recipients.filter(recipient => (recipient.nameOfTaker.length > 0 && validEmail(recipient.emailOfTaker))).length === newSimulationObj.recipients.length)
    return newSimulationObj.recipients.filter(recipient => (recipient.nameOfTaker.length <= 0 || !validEmail(recipient.emailOfTaker))).length === 0 && noDuplicatesInArray(newSimulationObj.recipients.map(recipient => recipient.emailOfTaker))
  }

  const createButtonDisabled = () => {
    return !(newSimulationObj.hasOwnProperty('simulationVersion') && newSimulationObj.simulationVersion.length > 0 && newSimulationObj.hasOwnProperty('recipients') && allRecipientsAdded())
  }

  // When 'Add Another Recipient' is pressed
  const handleAddRecipient = async (e) => {
    e.preventDefault()
    // setSaveSwitchSuccessful(false)

    setNewSimulationObj({
      ...newSimulationObj,
      recipients: [ ...newSimulationObj.recipients, { nameOfTaker: '', emailOfTaker: '' } ],
    })
  }

  const removeRecipient = async (e, index) => {
    e.preventDefault()
    // console.log('index to remove ->', index)
    // console.log('recipients ->', newSimulationObj.recipients.length)
    // console.log('recipients before removal ->', [ ...newSimulationObj.recipients ])
    const newRecipients = [ ...newSimulationObj.recipients ]
    newRecipients.splice(index, 1)
    // console.log('new recipients after splice ->', newRecipients)
    // console.log('length of new recipients after splice ->', newRecipients.length)


    setNewSimulationObj({
      ...newSimulationObj,
      recipients: newRecipients,
    })
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      keepMounted
      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box 
        sx={{ 
          // position: 'absolute',
          // left: '50%',
          // top: '50%',
          // transform: 'translate(-50%, -50%)',
          // width: width < positionChangeWidthSm ? '85%' : '450px',
          width: '85%',
          height: '85%',
          bgcolor: 'white',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4, 
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            WebkitAppearance: 'none',
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: 'rgba(0, 0, 0, .35)',
            webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
          },
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
      >
        {simulationCreated ?
          <>

            {/* Advisory Text */}
            <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
              Simulation Created!
            </Typography>

            {standardButton(
              'Close', 
              'button',
              'contained',
              false,
              'secondary', 
              2,
              0,
              0,
              '45%', 
              '45px', 
              handleClose
            )}
          </>
          :
          <Box
            sx={{
              width: '100%', height: '100%',
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
            }}
          >
            {/* Exit Icon Button */}
            <Box
              sx={{
                mr: 0,
                width: '100%',
                display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center',
              }}
            >
              {exitModalIconButton(handleClose, false, 0, 1, 1)}
            </Box>

            {/* Title */}
            <Typography
              sx={{
                mt: 2,
                fontSize: '22px',
                fontWeight: 'bold',
              }}
            >
              New Simulation
            </Typography>


            {/* Simulation Type*/}
            <Box
              sx={{
                mt: width < positionChangeWidthSm ? 2 : 2,
                width: '90%', 
                maxWidth: positionChangeWidthSm,
                display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
              }}
            >
              {selectElementHandleChange('simulationVersion', 'Simulation Version', newSimulationObj.simulationVersion, false, true, 'lg', simulationVersionsBusinessesArray, newSimulationObj, setNewSimulationObj, setNewSimulationErrors)}
            </Box>

            {/* Recipients Title */}
            <Typography
              sx={{
                mt: 4, mb: 0,
                width: '90%', maxWidth: positionChangeWidthSm,
                fontSize: '18',
                fontWeight: 'bold',
                display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
              }}
            >
              Recipients
            </Typography>

            {newSimulationObj.recipients.map((recipient, recipientIndex) => (

              // Person To Add
              <Box
                key={recipientIndex}
                sx={{
                  mt: width < positionChangeWidthSm ? 2 : 0.5, 
                  pt: width < positionChangeWidthSm ? 1 : 0, pb: width < positionChangeWidthSm && newSimulationObj.recipients.length === 1 ? 2 : 0, 
                  backgroundColor: width < positionChangeWidthSm ? 'whitesmoke' : '',
                  boxShadow: width < positionChangeWidthSm ? 3 : 0, borderRadius: width < positionChangeWidthSm ? '5px' : '0px',
                  width: '90%', 
                  maxWidth: newSimulationObj.recipients.length === 1 ? positionChangeWidthSm : positionChangeWidthSm,
                  display: 'flex', flexDirection: width < positionChangeWidthSm ? 'column' : 'row', justifyContent: width < positionChangeWidthSm ? 'center' : 'space-between', alignItems: width < positionChangeWidthSm ? 'center' : 'center',
                }}
              >

                {/* Name of Taker */}
                {newSimulationTextField(
                  'nameOfTaker',
                  recipient.nameOfTaker,
                  'Full Name',
                  'outlined',
                  newSimulationObj,
                  setNewSimulationObj,
                  setNewSimulationErrors,
                  'md',
                  width,
                  recipientIndex
                )}

                {/* Email of Taker */}
                {newSimulationTextField(
                  'emailOfTaker',
                  recipient.emailOfTaker,
                  'Email',
                  'outlined',
                  newSimulationObj,
                  setNewSimulationObj,
                  setNewSimulationErrors,
                  'md',
                  width,
                  recipientIndex
                )}

                {/* Delete Recipient Button */}
                {newSimulationObj.recipients.length > 1 && 
                  <Box
                    sx={{
                      // position: width < positionChangeWidthSm ? '' : 'absolute',
                      // left: width < positionChangeWidthSm ? '' : width < positionChangeWidthMd ? '82%' : `calc(50% + 290px)`,
                      // ml: width < positionChangeWidthSm ? 0 :  1,
                    }}
                  >
                    {deleteRecipientIconButton(
                      removeRecipient,
                      recipientIndex,
                      newSimulationObj.recipients.length <= 1,
                      0,
                      0,
                      0
                    )}
                  </Box>
                }
              </Box>
            ))}

            {/* Add Another Recipient */}
            <Box key={5} 
              sx={{ 
                mb: 0, mt: width < positionChangeWidthSm ? 3 : 1,
                width: '100%', 
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
              }}
            >
              {/* {console.log('allRecipientsAdded ->', allRecipientsAdded())} */}
              <Button 
                key={7} 
                disabled={(!allRecipientsAdded())}
                onClick={handleAddRecipient}
                color='primary'
              >
                ADD ANOTHER RECIPIENT
              </Button>
            </Box>

            {/* Duplicate Emails Advisory */}
            {!noDuplicatesInArray(newSimulationObj.recipients.map(recipient => recipient.emailOfTaker)) &&
              <Typography 
                sx={{
                  color: 'red',
                  width: '100%',
                  display: 'flex', flexDirection: 'row', justifyContent: 'center',
                }}
              >
                <em>The same email cannot be used for two people</em>
              </Typography>
            }

            {/* Continue and Cancel Buttons */}
            <Box
              sx={{
                mt: width < positionChangeWidthSm ? 4 : 6, pb: 6,
                width: '90%', maxWidth: '450px',
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
              }}
            >
              {standardButton(
                'Cancel', 
                'button',
                'contained',
                false,
                'secondary', 
                0,
                0,
                0,
                '45%', 
                '45px', 
                handleClose
              )}
              {standardButton(
                'Create', 
                'button',
                'contained',
                createButtonDisabled(),
                'warning', 
                0,
                0,
                0,
                '45%', 
                '45px', 
                handleCreate
              )}
            </Box>

            {newSimulationErrors &&
              standardErrorContainer(
                'There was an error creating your new simulation. Check your Internet connection and try again.',
                1,
                1
              )
            }
          </Box>
        }
      </Box>
    </Modal>
  )
}

export const incompleteVCSimInfoModal = (user , infoObj, setInfoObj, open, setOpen, linkResent, setLinkResent, resendLinkErrors, setResendLinkErrors, width) => {
  
  const handleClose = () => {
    // console.log('handleClose runs ->')
    // console.log('open ->', open)

    setInfoObj({})
    setResendLinkErrors(false)
    setLinkResent(false)
    setOpen(false)
  }

  const handleResendLink = async () => {
    // console.log('handleResendLink runs')
    setResendLinkErrors(false)
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/resend-simulation-info/${infoObj._id}`, {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      })
      
      // if (data.message === 'Simulation info resent') {
      setResendLinkErrors(false)
      setLinkResent(true)
      // }

    } catch (error) {
      setResendLinkErrors(true)
    }
    
  }

  const testDetailsElement = (element, value) => {
    // console.log('is example company ->', isExampleCompany(vcSimulation, currentCompany))
    return (
      <Box
        sx={{
          // backgroundColor: 'blue',
          mb: 0.5,
          // textWrap: 'nowrap',
          width: width < positionChangeWidthSm ? '100%' : '75%', maxWidth: positionChangeWidthSm, minWidth: '130px',
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start',
          // flexWrap: 'nowrap',
        }}
      >
        <Typography
          sx={{
            color: 'black',
            fontSize: '18px',
            fontWeight: 'bold',
          }}
        >
          {element}:
        </Typography>
        <Typography
          sx={{
            ml: 2,
            color: element === 'Link' ? 'blue' : 'black',
            fontSize: '18px',
          }}
        >
          {value}
        </Typography>
      </Box>
    )
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      keepMounted
      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box 
        sx={{ 
          // position: 'absolute',
          // left: '50%',
          // top: '50%',
          // transform: 'translate(-50%, -50%)',
          // width: width < positionChangeWidthSm ? '85%' : '450px',
          width: '85%',
          height: '85%',
          bgcolor: 'white',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4, 
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            WebkitAppearance: 'none',
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: 'rgba(0, 0, 0, .35)',
            webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
          },
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
      >
        {/* Exit Icon Button */}
        <Box
          sx={{
            position: width < positionChangeWidthSm ? 'inherit' : 'absolute',
            top: width < positionChangeWidthSm ? '0px' : '10%', left: width < positionChangeWidthSm ? '0px' : '37%',
            // mr: 0, mb: 1,
            width: '100%',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {exitModalIconButton(handleClose, false, 0, 1, 1)}
        </Box>

        {/* Name of Taker */}
        {testDetailsElement('Name', infoObj.nameOfTaker)}

        {/* Email of Taker */}
        {testDetailsElement('Email', infoObj.emailOfTaker)}

        {/* Date */}
        {testDetailsElement('Date', (new Date(infoObj.createdAt)).toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' }))}

        {/* Test */}
        {testDetailsElement('Test', `${`VC Test ${infoObj.standardizedSimulationNumber > 20 ? infoObj.standardizedSimulationNumber - 20 : infoObj.standardizedSimulationNumber > 10 ? infoObj.standardizedSimulationNumber - 10 : infoObj.standardizedSimulationNumber}`} — ${infoObj.simulationVersion === 'choose20v1' ? 100 : 50} Companies`)}

        {/* Link */}
        {testDetailsElement('Link', `${process.env.REACT_APP_CLIENT_URL}/vc-simulation/${infoObj._id}`)}

        {/* Security Code */}
        {testDetailsElement('Security Code', infoObj.securityCode)}

        {/* Resend Link Button */}
        {standardButton(
          'Resend Link', 
          'button',
          'contained',
          linkResent,
          'warning', 
          3,
          0,
          0,
          '150px', 
          '45px', 
          handleResendLink
        )}

        {/* Error */}
        {resendLinkErrors &&
          standardErrorContainer(
            'There was an error resending the link. Check your Internet connection and try again.',
            1,
            2
          )
        }

        {/* Successful Resend */}
        {linkResent &&
          <Typography 
            component='pre'
            textAlign={'center'}
            sx={{
              mt: 1,
              width: '85%', maxWidth: '400px',
              fontStyle: 'italic',
              color: simcapPink,
            }}
          >
            {`Resend Successful`.replace(/ {5} +/g, '')}
          </Typography>
        }

      </Box>
    </Modal>
  )
}