/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams, Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import blackDottedSpinnerGIF from '../../../../assets/dotted-black-trans-spinner-200.gif'
import turquoiseDottedSpinnerGIF from '../../../../assets/dotted-turquoise-trans-spinner-200.gif'
import whiteDottedSpinnerGIF from '../../../../assets/dotted-white-trans-spinner-200.gif'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'

import PortfolioItemCard from './PortfolioItemCard'

import { navbarHeight, footerHeight, positionChangeWidthSm, positionChangeWidthMd, positionChangeWidthLg } from '../../../../helpers/variableDefaults'
import { standardButton, exitModalIconButton } from '../../../../helpers/buttons'
import useWindowDimensions from '../../../../helpers/windowDimensions'
import { getCompanyOfInvestmentSelection } from '../../../../helpers/getNestedElements'

import { seoPageTags, customAnalyticsEvent } from '../../../../helpers/analytics'

// VC Sim View Portfolio
const ViewPortfolio = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()
  
  // Destructure Props
  const { vcSimulation, rules, showViewPortfolio, setShowViewPortfolio } = props

  const handleClose = () => {
    setShowViewPortfolio(false)
  }

  const summaryElement = (element, value) => {
    return (
      <Box
        sx={{
          width: '65%', maxWidth: '170px', minWidth: '150px',
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontSize: '18px',
          }}
        >
          {element}:
        </Typography>
        <Typography
          sx={{
            fontSize: '18px',
          }}
        >
          {value}
        </Typography>
      </Box>
    )
  }

  const portfolioItems = (number, company, month, initialInvestment, followOnInvestment, momentum) => {
    return (
      <Box
        key={number}
        sx={{
          // backgroundColor: 'yellow',
          mt: number === 'Number' ? 1 : 0,
          mb: 0.5,
          width: '85%',
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',
        }}
      >
        {/* Number */}
        <Box
          sx={{
            width: '80px',
            fontSize: number === 'Number' ? '18px' : '18px',
            fontWeight: number === 'Number' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {number}
        </Box>

        {/* Month */}
        <Box
          sx={{
            width: '80px',
            fontSize: month === 'Month' ? '18px' : '18px',
            fontWeight: month === 'Month' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {month}
        </Box>

        {/* Company */}
        <Box
          sx={{
            width: '200px',
            fontSize: company === 'Company' ? '18px' : '18px',
            fontWeight: company === 'Company' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: company === 'Company' ? 'center' : 'center', alignItems: 'center',
          }}
        >
          {company}
        </Box>

        {/* Initial Investment Amount */}
        <Box
          sx={{
            width: '120px',
            fontSize: initialInvestment === 'Initial' ? '18px' : '18px',
            fontWeight: initialInvestment === 'Initial' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {initialInvestment}
        </Box>

        {/* Follow On Investment */}
        <Box
          sx={{
            width: '150px',
            fontSize: followOnInvestment === 'Follow-On' ? '18px' : '18px',
            fontWeight: followOnInvestment === 'Follow-On' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {followOnInvestment}
        </Box>

        {/* Momentum at Follow-On */}
        <Box
          sx={{
            textWrap: 'nowrap',
            width: '250px',
            fontSize: momentum === 'Momentum at Follow-On' ? '18px' : '18px',
            fontWeight: momentum === 'Momentum at Follow-On' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: momentum === 'Momentum at Follow-On' ? 'center' : 'center', alignItems: 'center',
          }}
        >
          {momentum}
        </Box>

      </Box>
    )
  }

  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={showViewPortfolio ? true : false}
        closeAfterTransition
        // Backdrop={{
        //   timeout: 500,
        // }}
        keepMounted
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
      >
        <Fade in={showViewPortfolio ? true : false}>
          <Paper sx={{
            // backgroundColor: 'yellow',
            pb: 6,
            width: '85%',
            height: '85%', 
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              WebkitAppearance: 'none',
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '4px',
              backgroundColor: 'rgba(0, 0, 0, .35)',
              webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
            },
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}>
            {/* Exit Icon Button */}
            <Box
              sx={{
                // backgroundColor: 'yellow',
                mr: 0,
                width: '100%',
                display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center',
              }}
            >
              {exitModalIconButton(handleClose, false, 0, 1, 1)}
            </Box>

            {/* Title */}
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '24px',
              }}
            >
              My Portfolio
            </Typography>

            {/* Summary */}
            <Box
              sx={{
                // backgroundColor: 'yellow',
                py: 2,
                pl: 0,
                width: '85%',
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
              }}
            >
              {/* Investments */}
              {summaryElement('Investments', `${vcSimulation.investmentSelections.length}/${rules.maxPortfolioCompanies}`)}

              {/* Follow-Ons */}
              {summaryElement('Follow-Ons', `${vcSimulation.followOnSelections.length}/${rules.maxFollowOnCompanies}`)}

              {/* Total */}
              {summaryElement('Total', `$${((vcSimulation.investmentSelections.length * rules.initialInvestmentAmount) + (vcSimulation.followOnSelections.length * rules.followOnInvestmentAmount))}M`)}

              {/* Total */}
              {summaryElement('Remaining', `$${rules.amountToInvest - ((vcSimulation.investmentSelections.length * rules.initialInvestmentAmount) + (vcSimulation.followOnSelections.length * rules.followOnInvestmentAmount))}M`)}

              {/* Threshold Met */}
              {summaryElement('Threshold Met', vcSimulation.investmentSelections.length * (rules.initialInvestmentAmount + rules.followOnInvestmentAmount) >= (rules.amountToInvest * rules.newFundraisingThreshold) ? `Yes` : `No`)}
            </Box>

            {/* Chart of Investments */}
            {width >= positionChangeWidthMd &&
              <Box
                sx={{
                  width: '100%',
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >
                {portfolioItems('Number', 'Company', 'Month', 'Initial', 'Follow-On', 'Momentum at Follow-On')}
                
                {/* Investments */}
                {vcSimulation.investmentSelections.map((selection, selectionIndex) => (
                  portfolioItems(
                    selectionIndex + 1, 
                    getCompanyOfInvestmentSelection(selection.id, vcSimulation).name, // Name
                    getCompanyOfInvestmentSelection(selection.id, vcSimulation).initialInvestmentMonth, // Month
                    `$${rules.initialInvestmentAmount}M`, // Initial Investment Amount
                    vcSimulation.followOnSelections.map(followOn => followOn.id).indexOf(selection.id) > -1 ? `$${rules.followOnInvestmentAmount}M` : vcSimulation.currentFollowOnIndex > selectionIndex ? 0 : 'NA', // Follow-On Investment Amount
                    vcSimulation.currentFollowOnIndex > selectionIndex && getCompanyOfInvestmentSelection(selection.id, vcSimulation).hasOwnProperty('followOnInfo') ? getCompanyOfInvestmentSelection(selection.id, vcSimulation).followOnInfo.statusAtFollowOn : 'NA' // Momentum at Follow-On
                  )
                ))}

                {/* Placeholders for Investments not yet made */}
                {Array.from({ length: rules.maxPortfolioCompanies - vcSimulation.investmentSelections.length }, (_, i) => i + vcSimulation.investmentSelections.length + 1).map((nullSelection, nullSelectionIndex) => (
                  portfolioItems(
                    nullSelection, 
                    'NA', // Name
                    'NA', // Month
                    'NA', // Initial Investment Amount
                    'NA', // Follow-On Investment Amount
                    'NA' // Momentum at Follow-On
                  )
                ))}
              </Box>
            }

            {/* Portfolio Item Cards */}
            <Box
              sx={{
                py: 3,
                width: '100%',
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
              }}
            >
              {/* Title */}
              <Typography
                sx={{
                  mt: 4, mb: 2,
                  fontWeight: 'bold',
                  fontSize: 24,
                }}
              >
                Company Details
              </Typography>

              {/* If No Investments Yet */}
              {vcSimulation.investmentSelections.length === 0 ?
                <Box
                  sx={{
                    width: '85%',
                    display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                  }}
                >
                  No Investments Yet
                </Box>
                :
                vcSimulation.investmentSelections.map((selection, selectionIndex) => (
                  <Box
                    key={selectionIndex}
                    sx={{
                      width: '100%',
                      display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                    }}
                  >
                    <PortfolioItemCard 
                      vcSimulation={vcSimulation} 
                      currentCompany={getCompanyOfInvestmentSelection(selection.id, vcSimulation)} 
                      rules={rules} 
                      decisionNumber={selectionIndex + 1} 
                    />
                  </Box>
                ))
              }
            </Box>
            
          </Paper>
        </Fade>
      </Modal>
    </>
  )
}

export default ViewPortfolio