/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

import blackDottedSpinnerGIF from '../../assets/dotted-black-trans-spinner-200.gif'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { standardSpinner } from '../../helpers/spinners'
import useWindowDimensions from '../../helpers/windowDimensions'
import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

import AllAuth from '../auth/AllAuth.js'
import { navbarHeight, footerHeight, oneTimeRate } from '../../helpers/variableDefaults.js'


// Create Account Master Page
const CreateAccount = (props) => {

  const { registerForm, setRegisterForm } = props

  // useEffect(() => {
  // }, [])
  
  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Create Account'
      )}

      {/* Body */}
      <Box 
        sx={{ 
          minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight})`, 
          width: '100vw', 
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
        }}
      >

        <AllAuth fromCreateAccount={true} registerForm={registerForm} setRegisterForm={setRegisterForm}/>
        
      </Box>
    </>
  )
  
}

export default CreateAccount
