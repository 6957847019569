/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

import useWindowDimensions from '../../helpers/windowDimensions'
import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'
import { emailTextField, loginTextField } from '../../helpers/textfields'
import { standardButton } from '../../helpers/buttons'
import { validEmail, handleChange } from '../../helpers/globalHelpers'
import { standardErrorContainer } from '../../helpers/errors'
import { navbarHeight, footerHeight, positionChangeWidthSm } from '../../helpers/variableDefaults'
import { securityQuestionAndAnswerBoxItems } from '../../helpers/securityQuestionsAndAnswers'
import { acceptTOSCheckboxForm } from '../../helpers/checkboxes'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'


// Reset Password — route: /reset-password/
const ResetPassword = () => {

  // Params
  const { userId, token } = useParams()
  // console.log('userId ->', userId)
  // console.log('token ->', token)

  // use Naviage
  const navigate = useNavigate()

  // URL Path
  const urlPath = window.location.pathname

  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Forgot Password State
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('')

  // Link Sent
  const [linkSent, setLinkSent] = useState(false)
  const [sentLinkEmail, setSentLinkEmail] = useState('')

  // New Password
  const [newPasswordForm, setNewPasswordForm] = useState({
    newPassword: '',
    newPasswordConfirmation: '',
    userId: userId ? userId : '',
    token: token ? token : '',
    acceptedTOS: true,
  })

  // Accepted TOS
  const [accountHasSecurityQuestions, setAccountHasSecurityQuestions] = useState(true)

  // Errors
  const [emailErrors, setEmailErrors] = useState(false)
  const [newPasswordErrors, setNewPasswordErrors] = useState(false)

  // If there is a userId in the params, retrieve the User data
  useEffect(() => {
    async function fetchData() {

      if (userId && token) {
        // console.log('token ->', token)
        // console.log('userId ->', userId)
        try {
          const { data } = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/reset-password/get-user/${userId}`, { token: token }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })

          // console.log('data ->', data.acceptedTOS)

          // If accepted TOS is false, it means the User was created by someone else in Create Estate, so the User will have to create security questions and accept the TOS
          if (data.acceptedTOS === false) {
            // console.log('accepted tos false')
            const updateNewPasswordForm = { ...newPasswordForm }
            updateNewPasswordForm.acceptedTOS = false
            updateNewPasswordForm.securityQuestions = []
            setNewPasswordForm(updateNewPasswordForm)
            setAccountHasSecurityQuestions(false)
          }

        } catch (err) {
          // console.log(err)
        }
      }
    }

    fetchData()

  }, [])

  // Resets the page if it turns out the retrieved User doesn't have security questions yet
  useEffect(() => {

  }, [accountHasSecurityQuestions])

  // Handle Set Email (from email address TextField)
  const handleSetEmail = async (e) => {
    e.preventDefault()

    // get name and value from the event target
    const { name, value } = e.target

    setForgotPasswordEmail(value)

    setEmailErrors(false)

  }

  const handleSendResetLink = async (e) => {
    e.preventDefault()
    // console.log('handle send reset link runs')

    const formToUpload = { email: forgotPasswordEmail }
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/reset-password/link-request/`, formToUpload)
      
      // console.log('data ->', data)

      // Update States
      setSentLinkEmail(forgotPasswordEmail)
      setForgotPasswordEmail('')
      setLinkSent(true)
      setEmailErrors(false)


    } catch (err) {
      // console.log('err: ', err)
      setEmailErrors(true)
    }
  }

  const handlePasswordReset = async (e) => {
    e.preventDefault()
    // console.log('handle password reset runs')

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/reset-password/execute/`, newPasswordForm)
      
      // console.log('data ->', data)

      // Navigate to login screen
      navigate('/login')
      
    } catch (err) {
      // console.log('err: ', err)
    }

  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Reset Password'
      )}

      {/* Body — Reset Password Form */}
      <Box 
        sx={{ 
          // backgroundColor: 'blue',
          pl: 1, pr: 0, pb: 4, pt: 4,
          width: '100%', 
          minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight})`,
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: (width < positionChangeWidthSm || userId) ? 'flex-start' : 'center', 
        }}
      >
        <Box 
          sx={{
            // backgroundColor: 'yellow',
            width: '85%',
            height: '85%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: accountHasSecurityQuestions === false ? 'flex-start' : 'center',
            alignItems: 'center',
          }}
        >
          {/* Title */}
          <Typography sx={{ mb: 0, mt: accountHasSecurityQuestions === false ? 4 : 0 }}>
            <strong>Reset Password</strong>
          </Typography>


          {/* Useful Info */}
          <Typography 
            textAlign={'left'}
            component="pre"
            sx={{
              mt: 0, mb: 1,
              fontSize: '14px',
              fontStyle: 'italic',
              color: 'blue',
            }}
          >
            {!linkSent && !userId &&
              `*A password reset link will be sent to the submitted email address.`
            }
            {linkSent && !userId &&
              `*A password reset link was sent to ${sentLinkEmail}.
              **Link is valid for 60 minutes`.replace(/ {5} +/g, '') //the regex is for deleting the indentation spaces in the string literal`
            }
            {userId &&
              `Input a new password and then press 'Reset Password'`
            }
          </Typography>

          
          {/* TextField and Button Container */}
          <Box 
            noValidate 
            textAlign={'center'}
            sx={{ 
              mt: 1,
              width: '85%', maxWidth: '350px',
            }}
          >
            {!userId ?
              <>
                {/* Email TextField */}
                {emailTextField(
                  'forgot-password-email', 
                  forgotPasswordEmail, 
                  'email address', 
                  'outlined',
                  handleSetEmail, 
                  forgotPasswordEmail, 
                  setForgotPasswordEmail,
                  width
                )}
              </>
              :
              <>
                {/* Password TextField */}
                <Box sx={{ mt: 1 }}>
                  {loginTextField('newPassword', 'Password', newPasswordForm.newPassword, 'outlined', true, true, false, 'new-password', newPasswordForm, setNewPasswordForm, setNewPasswordErrors, handleChange )}
                </Box>
                
                {/* Password Confirmation */}
                <Box sx={{ mt: accountHasSecurityQuestions === false ? 1 : 2 }}>
                  {loginTextField('newPasswordConfirmation', 'Password Confirmation', newPasswordForm.newPasswordConfirmation, 'outlined', true, true, false, 'new-password', newPasswordForm, setNewPasswordForm, setNewPasswordErrors, handleChange )}
                </Box>

                {/* Error message — Password must be same as password confirmation */}
                {newPasswordForm.newPasswordConfirmation.length > 0 
                  && newPasswordForm.newPasswordConfirmation !== newPasswordForm.newPassword
                  &&
                    <Typography 
                      sx={{
                        fontSize: '14px',
                        fontStyle: 'italic',
                        color: 'red',
                      }}
                    >
                      *password confirmation must match password
                    </Typography>
                }
                
                {accountHasSecurityQuestions === false &&
                  <>
                    {/* Security Questions and Answers */}
                    {securityQuestionAndAnswerBoxItems(newPasswordForm, setNewPasswordForm)}
              
                    {/* Terms of Service and Privacy Policy Checkbox */}
                    <Box 
                      sx={{ 
                        width: '100%',
                        fontSize: '13px',
                        pl: 1, pr: 1, pt: 1,
                        display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap',
                        overflow: 'visible',
                      }}
                    >
                      {acceptTOSCheckboxForm('acceptedTOS', newPasswordForm.acceptedTOS, newPasswordForm, setNewPasswordForm)}
                    </Box>
                  </>
                
                }
              </>
            }
            

            {/* Button */}
            <Box 
              sx={{
                mt: 2,
                width: '100%',
                display: 'flex', flexDirection: 'row', justifyContent: 'center',
              }}
            >
              {standardButton(
                !userId ? linkSent ? 'New Reset Link' : 'Send Reset Link' : 'Reset Password', 
                'button',
                'contained',
                !userId ? 
                  !validEmail(forgotPasswordEmail) 
                  : 
                  accountHasSecurityQuestions ? 
                    !(newPasswordForm.newPassword.length > 0 && newPasswordForm.newPasswordConfirmation.length > 0 && (newPasswordForm.newPassword === newPasswordForm.newPasswordConfirmation)) 
                    : 
                    !(newPasswordForm.newPassword.length > 0 && newPasswordForm.newPasswordConfirmation.length > 0 && (newPasswordForm.newPassword === newPasswordForm.newPasswordConfirmation) && newPasswordForm.acceptedTOS && newPasswordForm.hasOwnProperty('securityQuestions') && newPasswordForm.securityQuestions.length === 3 && newPasswordForm.securityQuestions[2].q.length > 0 && newPasswordForm.securityQuestions[2].a.length > 0 && newPasswordForm.securityQuestions[1].a.length > 0 && newPasswordForm.securityQuestions[0].a.length > 0), 
                'secondary',
                2,
                8,
                0,
                '160px',
                '45px',
                !userId ? handleSendResetLink : handlePasswordReset
              )}
            </Box>
            
            {/* Error Message, if errors */}
            {((!userId && emailErrors) || (userId && newPasswordErrors)) &&
              standardErrorContainer(
                emailErrors ? '*No account associated with this email address' : '*Could not reset password. Keep in mind that the password reset link expires after 60 minutes.',
                0,
                0
              )
            }
            
          </Box>
          
        </Box>
      </Box>

    </>
  )
}

export default ResetPassword