/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams, Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import blackDottedSpinnerGIF from '../../../../assets/dotted-black-trans-spinner-200.gif'
import turquoiseDottedSpinnerGIF from '../../../../assets/dotted-turquoise-trans-spinner-200.gif'
import whiteDottedSpinnerGIF from '../../../../assets/dotted-white-trans-spinner-200.gif'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { navbarHeight, footerHeight } from '../../../../helpers/variableDefaults'
import { standardButton } from '../../../../helpers/buttons'
import { descriptionTextField } from '../../../../helpers/textfields'
import { standardErrorContainer } from '../../../../helpers/errors'
import { standardSpinner } from '../../../../helpers/spinners'

import { seoPageTags, customAnalyticsEvent } from '../../../../helpers/analytics'

// VC Sim Submitted
const Submitted = (props) => {

  const { vcSimulation, setVCSimulation, setHasResults, loading, setLoading } = props

  // const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(false)

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Simulation Submitted'
      )}

      {/* Body */}
      <Box 
        sx={{ 
          // height: `calc(100vh - ${navbarHeight} - ${footerHeight})`, 
          height: '100vh',
          width: '100vw', 
          py: 5,
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            WebkitAppearance: 'none',
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: 'rgba(0, 0, 0, .35)',
            webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
          },
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {/* Simulation Completed Title */}
          <Typography 
            sx={{
              mb: 1,
              width: '100%',
              fontWeight: 'bold', fontSize: '20px',
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            }}
          >
            Responses Submitted
          </Typography>

          <Typography 
            sx={{
              mt: 1,
              width: '100%', maxWidth: '450px',
              fontSize: '18px',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
            }}
          >
            {`Your responses have been submitted. Your detailed results will be ready within 24 hours. You will receive an email when they are ready. To view the results, return to this link and input your 6-digit security code.`}
          </Typography>
          
        </Box>

      </Box>
    </>
  )
}

export default Submitted