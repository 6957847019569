/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams, Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import EditIcon from '@mui/icons-material/Edit'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import StarIcon from '@mui/icons-material/Star'
import SendIcon from '@mui/icons-material/Send'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { ExpandMore } from '../../../../helpers/cardHelpers'
import { positionChangeHeightSm, positionChangeWidthLg, positionChangeWidthSm, simcapPink, simcapTurquoise } from '../../../../helpers/variableDefaults'
import useWindowDimensions from '../../../../helpers/windowDimensions'
import { standardButton } from '../../../../helpers/buttons'
import { dummyBox } from '../../../../helpers/globalHelpers'

const NonFinancialFactorsCard = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Destructure Props
  const { currentCompany } = props
  // console.log('currentCompany ->', currentCompany)

  // Tracks whether the card is expanded
  const [expanded, setExpanded] = useState(false)

  // Changes the card expanded state
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const factorPercentiles = (factor, percentile, upperBound, lowerBound) => {
    return (
      <Box
        sx={{
          mb: 0.5,
          width: width < positionChangeWidthSm ? '95%' : '85%',
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',
        }}
      >
        {/* Factor */}
        <Box
          sx={{
            width: '150px',
            fontSize: width < 400 ? '14px' : width < positionChangeWidthSm ? '16px' : factor === 'Factor' ? '18px' : '18px',
            fontWeight: factor === 'Factor' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: factor === 'Factor' ? 'flex-start' : 'flex-start', alignItems: 'center',
          }}
        >
          {factor}
        </Box>

        {/* Mean */}
        <Box
          sx={{
            width: '80px',
            fontSize: width < 400 ? '14px' : width < positionChangeWidthSm ? '16px' : factor === 'Factor' ? '18px' : '18px',
            fontWeight: percentile === 'Mean' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {percentile}
        </Box>

        {/* Upper Bound */}
        <Box
          sx={{
            width: '80px',
            fontSize: width < 400 ? '14px' : width < positionChangeWidthSm ? '16px' : factor === 'Factor' ? '18px' : '18px',
            fontWeight: upperBound === 'Upper' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {upperBound}
        </Box>

        {/* Lower Bound */}
        <Box
          sx={{
            width: '80px',
            fontSize: width < 400 ? '14px' : width < positionChangeWidthSm ? '16px' : factor === 'Factor' ? '18px' : '18px',
            fontWeight: lowerBound === 'Lower' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {lowerBound}
        </Box>

      </Box>
    )
  }

  const nonFinancialFactorsAccordionJSX = (title, questionsAnswersArray, expandedDefault = false) => {
    return (
      // Instantiate an Accordion
      <Accordion
        defaultExpanded={expandedDefault}
        sx={{ 
          width: '85%',
          boxShadow: 4, 
        }}
      >
        {/* Title appears on Accordion Summary */}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            backgroundColor: 'lightblue',
            fontSize: '18px',
            border: 2,
            borderBottom: 2,
            borderColor: 'black',
          }}
        >
          <Typography
            sx={{
              width: '100%',
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            }}
          >
            <strong>{title.toUpperCase()}</strong>
          </Typography>
        </AccordionSummary>

        {/* Questions and Answers */}
        <AccordionDetails
          sx={{
            backgroundColor: 'lightyellow',
            border: 2,
            borderTop: 0,
            borderColor: 'black',
            display: 'flex', flexDirection: 'column',
          }}
        >
          {questionsAnswersArray.map((qaObj, qaIndex) => (
            <Box
              key={qaIndex}
              sx={{
                mb: 1,
                width: '100%',
                display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
              }}
            >
              {/* Question */}
              <Box
                sx={{
                  mr: 0.5,
                  width: '45%',
                  // fontWeight: 'bold',
                  display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >
                {qaObj.q}
              </Box>
              
              {/* Answer */}
              <Box
                sx={{
                  width: '50%',
                  fontWeight: 'bold',
                  display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >
                {qaObj.a}
              </Box>
            </Box>
          ))}
        </AccordionDetails>

      </Accordion>
    )
  }

  const factorAccordions = () => {
    return (
      <CardContent
        sx={{
          // backgroundColor: 'yellow',
          width: '100%',
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
      >

        {/* Team */}
        {nonFinancialFactorsAccordionJSX(
          'Team', 
          [
            {
              q: `Sim Capital's relationship with leadership?`,
              a: currentCompany.team.relationshipWithLeadership.description,
            },
            {
              q: `Management team's technical capabilities?`,
              a: currentCompany.team.managementTechCapabilities.description,
            },
            {
              q: `Management team's cultural leadership capabilities?`,
              a: currentCompany.team.culturalLeadership.description,
            },
            {
              q: `Management team's networking chops?`,
              a: currentCompany.team.networkingChops.description,
            },
            {
              q: `Knowledgable, engaged, well-connected board?`,
              a: currentCompany.team.boardAndAdvisors.description,
            },
            {
              q: `Hiring difficulty for open positions?`,
              a: currentCompany.team.hiringDifficulty.description,
            },
            {
              q: `Likelihood of needing to replace CEO before exit?`,
              a: currentCompany.team.ceoReplacement.description,
            },
            {
              q: `At least one strategic investor will own equity?`,
              a: currentCompany.team.strategicInvestor.description,
            }
          ], 
          false
        )}

        {/* Problem/Solution */}
        {nonFinancialFactorsAccordionJSX(
          'Problem/Solution', 
          [
            {
              q: `Is there a clear problem being addressed?`,
              a: currentCompany.problemSolution.problem.description,
            },
            {
              q: `Is the product a solution to the problem?`,
              a: currentCompany.problemSolution.solution.description,
            },
            {
              q: `Is "why" this solution is effective sustainable?`,
              a: currentCompany.problemSolution.sustainability.description,
            }
          ], 
          false
        )}

        {/* Differentiation */}
        {nonFinancialFactorsAccordionJSX(
          'Differentiation', 
          [
            {
              q: `The value proposition has a unique 'secret sauce'?`,
              a: currentCompany.differentiation.secretSauce.description,
            },
            {
              q: `Value chain stakeholder gain on net from solution?`,
              a: currentCompany.differentiation.valueChain.description,
            },
            {
              q: `Company has strong Intellectual Property?`,
              a: currentCompany.differentiation.ip.description,
            },
            {
              q: `Is the competitive landscape intense?`,
              a: currentCompany.differentiation.landscape.description,
            }
          ], 
          false
        )}

        {/* Market */}
        {nonFinancialFactorsAccordionJSX(
          'Market', 
          [
            {
              q: `Size of addressable market for core solution?`,
              a: currentCompany.market.size.description,
            },
            {
              q: `Accessibility of the core market?`,
              a: currentCompany.market.accessibility.description,
            },
            {
              q: `Barriers to entry for competitors?`,
              a: currentCompany.market.barriersForCompetitors.description,
            },
            {
              q: `Size of most credible secondary market?`,
              a: currentCompany.market.nonCoreSize.description,
            },
            {
              q: `Accessibility of most credible secondary market?`,
              a: currentCompany.market.secondaryMarketAccessibility.description,
            },
            {
              q: `Barriers to entry of secondary market to competitors?`,
              a: currentCompany.market.competitorsSecondaryMarketAccessibility.description,
            }
          ], 
          false
        )}

        {/* Commercialization */}
        {nonFinancialFactorsAccordionJSX(
          'Commercialization', 
          [
            {
              q: `How fast can the product scale?`,
              a: currentCompany.commercialization.scaleSpeed.description,
            },
            {
              q: `How well-developed are the necessary relationships?`,
              a: currentCompany.commercialization.relationships.description,
            },
            {
              q: `How much product development remains before scaling?`,
              a: currentCompany.commercialization.productDevelopment.description,
            },
            {
              q: `Confidence that customers will pay projected price?`,
              a: currentCompany.commercialization.pricePoint.description,
            },
            {
              q: `Has the company shown it can market/brand itself?`,
              a: currentCompany.commercialization.branding.description,
            }
          ], 
          false
        )}

        {/* Additional Risk */}
        {nonFinancialFactorsAccordionJSX(
          'Additional Risk', 
          [
            {
              q: `How potent is known policy risk?`,
              a: currentCompany.additionalRisks.policyRisk.description,
            },
            {
              q: `What is the engineering risk?`,
              a: currentCompany.additionalRisks.engineeringRisk.description,
            },
            {
              q: `How wary are you of unknown unknowns?`,
              a: currentCompany.additionalRisks.unknownRisk.description,
            },
            {
              q: `Are you wary of other risks?`,
              a: currentCompany.additionalRisks.otherRisk.description,
            }
          ], 
          false
        )}
      </CardContent>
    )
  }

  return (
    <Card 
      align="left" 
      sx={{ 
        pt: 2,
        backgroundColor: 'whitesmoke',
        width: '95%', maxWidth: '600px',
        minHeight: width > positionChangeWidthLg ? '730px' : '',
        boxShadow: 3, borderRadius: 3,
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
      }}
    >
      {/* Title */}
      <Box
        sx={{
          width: '100%',
          fontWeight: 'bold',
          fontSize: '24px',
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        }}
      >
        {width >= 340 ? 'Non-Financial Information' : 'Non-Financial'}
      </Box>

      {/* Percentiles Title */}
      {width >= 340 &&
        <Box
          sx={{
            mt: 2, mb: 1,
            width: '100%',
            fontWeight: 'bold',
            fontSize: '20px',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          Percentiles
        </Box>
      }

      {/* Categories (Factor, Mean, Upper, Lower) */}
      {width >= 340 && factorPercentiles('Factor', 'Mean', 'Upper', 'Lower')}

      {/* Team */}
      {width >= 340 && factorPercentiles('Team', parseFloat(currentCompany.team.meanPortfolioPercentile).toFixed(2), parseFloat(currentCompany.team.upperBoundPortfolioPercentile).toFixed(2), parseFloat(currentCompany.team.lowerBoundPortfolioPercentile).toFixed(2))}

      {/* Problem/Solution */}
      {width >= 340 && factorPercentiles('Problem/Solution', parseFloat(currentCompany.problemSolution.meanPortfolioPercentile).toFixed(2), parseFloat(currentCompany.problemSolution.upperBoundPortfolioPercentile).toFixed(2), parseFloat(currentCompany.problemSolution.lowerBoundPortfolioPercentile).toFixed(2))}

      {/* Differentiation */}
      {width >= 340 && factorPercentiles('Differentiation', parseFloat(currentCompany.differentiation.meanPortfolioPercentile).toFixed(2), parseFloat(currentCompany.differentiation.upperBoundPortfolioPercentile).toFixed(2), parseFloat(currentCompany.differentiation.lowerBoundPortfolioPercentile).toFixed(2))}

      {/* Market */}
      {width >= 340 && factorPercentiles('Market', parseFloat(currentCompany.market.meanPortfolioPercentile).toFixed(2), parseFloat(currentCompany.market.upperBoundPortfolioPercentile).toFixed(2), parseFloat(currentCompany.market.lowerBoundPortfolioPercentile).toFixed(2))}

      {/* Commercialization */}
      {width >= 340 && factorPercentiles('Commercialization', parseFloat(currentCompany.commercialization.meanPortfolioPercentile).toFixed(2), parseFloat(currentCompany.commercialization.upperBoundPortfolioPercentile).toFixed(2), parseFloat(currentCompany.commercialization.lowerBoundPortfolioPercentile).toFixed(2))}

      {/* Additional Risk */}
      {width >= 340 && factorPercentiles('Additional Risk', parseFloat(currentCompany.additionalRisks.meanPortfolioPercentile).toFixed(2), parseFloat(currentCompany.additionalRisks.upperBoundPortfolioPercentile).toFixed(2), parseFloat(currentCompany.additionalRisks.lowerBoundPortfolioPercentile).toFixed(2))}

      {/* Advisory */}
      {width >= 340 && 
        <Typography 
          component='pre'
          textAlign={'left'}
          sx={{
            mt: 1,
            width: '85%', maxWidth: '400px',
            fontStyle: 'italic',
            color: 'blue',
          }}
        >
          {`*Percentiles are relative to SimCap's portfolio companies. Evaluated companies are in the 90th percentile or above compared to an average company.`.replace(/ {5} +/g, '')}
          {/* {`*Percentiles are relative to SimCap's portfolio companies`.replace(/ {5} +/g, '')} */}
        </Typography>
      }

      {/* Factor Accordions */}
      {factorAccordions()}
      
    </Card>
  )
}

export default NonFinancialFactorsCard