/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import Instructions from './helpers/Instructions.js'
import Submitted from './helpers/Submitted.js'
import Reflections from './helpers/Reflections.js'
import VCResults from './VCResults.js'
import LiveVCSimulation from './LiveVCSimulation.js'
import Security from './helpers/Security.js'
import PageNavbar from '../../common/PageNavbar.js'
import Footer from '../../common/Footer.js'

// mui
import Box from '@mui/material/Box'

import { positionChangeWidthSm, positionChangeWidthMd, simcapPink, navbarHeight, footerHeight, positionChangeWidthLg, exampleCompaniesObj } from '../../../helpers/variableDefaults'
import { standardSpinner, calculatingStandardSpinner } from '../../../helpers/spinners'
import { requestError } from '../../../helpers/errors'
import { getCompanyOfInvestmentSelection, getIdForCurrentFollowOnIndexCompany } from '../../../helpers/getNestedElements.js'
import { seoPageTags, customAnalyticsEvent } from '../../../helpers/analytics'
import useWindowDimensions from '../../../helpers/windowDimensions.js'
import { Typography } from '@mui/material'
import { getPayload } from '../../../helpers/storage.js'

// Unwrap Gift
const VCSimulation = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()
  const minimumScreenWidth = 350
  const hasMinimumScreenWidth = true

  // Params
  const { vcSimulationId } = useParams()

  // Payload
  const payload = getPayload()

  //loading and error state
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(false) 
  
  // States necessary for simulation process
  const [securityCode, setSecurityCode] = useState('')
  const [securityPassed, setSecurityPassed] = useState(false)
  const [showInstructions, setShowInstructions] = useState(false)
  const [preparingResults, setPreparingResults] = useState(false)
  const [testIsFinished, setTestIsFinished] = useState(false)
  const [hasResults, setHasResults] = useState(false)

  // VC Simulation
  const [vcSimulation, setVCSimulation] = useState({})
  const [currentCompany, setCurrentCompany] = useState({})
  const [rules, setRules] = useState({})
  const [exampleCurrentInvestmentIndex, setExampleCurrentInvestmentIndex] = useState(0)
  const [exampleCurrentFollowOnIndex, setExampleCurrentFollowOnIndex] = useState(-1)

  // Retrieve gift data upon pageload

  const isTestFinished = async (simulationData) => {
    // console.log('testIsFinishedSetterFunction runs')
    // console.log('simulationData.results !== null', simulationData.results !== null)
    // console.log('follow-ons complete ->', Math.floor(vcSimulation.currentFollowOnIndex) >= vcSimulation.investmentSelections.length)
    return (
      (simulationData.results !== null && simulationData.results !== undefined)
      ||
      (
        (
          simulationData.investmentSelections.length >= simulationData.standardizedSimulation.rules.maxPortfolioCompanies // Initial Investments have reached maximum
          && simulationData.followOnSelections.length >= simulationData.standardizedSimulation.rules.maxFollowOnCompanies // Follow-Ons have reached maximum
        )
        ||
        (
          (
            simulationData.currentInvestmentIndex >= simulationData.standardizedSimulation.rules.totalOpportunities // Have exhausted all investment opportunities
            || simulationData.investmentSelections.length >= simulationData.standardizedSimulation.rules.maxPortfolioCompanies
          )
          &&
          (
            Math.floor(simulationData.currentFollowOnIndex) >= simulationData.standardizedSimulation.rules.maxPortfolioCompanies // Have exhausted all follow-on opportunities
            || Math.floor(simulationData.currentFollowOnIndex) >= simulationData.investmentSelections.length // No more initial investments
            || simulationData.followOnSelections.length >= simulationData.standardizedSimulation.rules.maxFollowOnCompanies // Follow-Ons have reached maximum
          )
        )
      )
        ?
        true
        :
        false
    )
  }

  const currentCompanySetterFunction = async (simulationData) => {
    // console.log('currentCompanySetterFunction runs')
    // console.log('simulationData ->', simulationData)

    // console.log('simulationData.exampleCurrentInvestmentIndex ->', simulationData.exampleCurrentInvestmentIndex)
    // console.log('exampleCompaniesObj.exampleCompanies.length ->', exampleCompaniesObj.exampleCompanies.length)

    await setCurrentCompany(
      (simulationData.exampleCurrentInvestmentIndex < exampleCompaniesObj.exampleCompanies.length)
        ?
        exampleCompaniesObj.exampleCompanies[simulationData.exampleCurrentInvestmentIndex] // Example Initial Investment
        :
        (simulationData.exampleCurrentFollowOnIndex < exampleCompaniesObj.exampleCompanies.length)
          ?
          exampleCompaniesObj.exampleCompanies[simulationData.exampleCurrentFollowOnIndex] // Example Follow-On Investment
          :
          (simulationData.investmentSelections.length === 0 // Impossible to have follow-ons if no investments selected
            || (
              (simulationData.investmentSelections.length < simulationData.standardizedSimulation.rules.maxPortfolioCompanies) // Length of investment selections less than max portfolio companies
              && (simulationData.currentInvestmentIndex < simulationData.standardizedSimulation.rules.totalOpportunities) // Current investment index is less than the total opportunities
              && (
                simulationData.investmentSelections.length === simulationData.currentFollowOnIndex // All follow-on decisions have been made for current set of initial investments
                || getCompanyOfInvestmentSelection(getIdForCurrentFollowOnIndexCompany(simulationData), simulationData).followOnInvestmentMonth >= simulationData.standardizedSimulation.companies[simulationData.currentInvestmentIndex].initialInvestmentMonth // Follow-On Investment Month for current follow-on index is greater than or equal to the initial investment month for the current investment index
              )
            )
          )
            ?
            simulationData.standardizedSimulation.companies[simulationData.currentInvestmentIndex] // Initial Investment Company
            :
            (
              (
                simulationData.followOnSelections.length < simulationData.standardizedSimulation.rules.maxFollowOnCompanies // Follow-Ons Have Not Reached Maximum
              ) 
              && 
              (
                Math.floor(simulationData.currentFollowOnIndex) < simulationData.standardizedSimulation.rules.maxPortfolioCompanies // Follow-On Opportunities are not exhausted
              )
            )
              ?
              getCompanyOfInvestmentSelection(getIdForCurrentFollowOnIndexCompany(simulationData), simulationData) // Follow-On Investment Company
              :
              {} // Neither Initial nor Follow-On Investment
    )
  }

  useEffect(() => {

    // Get VC Simulation Data
    const getData = async () => {
      try {

        // Set Loading to true while data fetches
        setLoading(true)

        // Get data for specified vc simulation, by vc simulation id
        const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/vc-simulations/no-outcomes/${vcSimulationId}`)
        // console.log('data ->', data)
        // console.log('hasResults ->', data.results)

        setTestIsFinished(data.testIsFinished || (data.results !== null && data.results !== undefined) || (data.investmentSelections.length === data.standardizedSimulation.rules.maxPortfolioCompanies && data.followOnSelections.length === data.standardizedSimulation.rules.maxFollowOnCompanies) || (Math.floor(data.currentFollowOnIndex) >= data.standardizedSimulation.rules.maxPortfolioCompanies))
        // setTestIsFinished(true)
        setHasResults(data.results !== null && data.results !== undefined)

        // Set security code
        setSecurityCode(data.securityCode)

        // Set Rules
        setRules(data.standardizedSimulation.rules)

        // Set the vcSimulation state with the retrieved data
        setVCSimulation(data)

        // Automatically pass security if name of admin and name of taker are the same
        if (
          (data.nameOfAdmin === data.nameOfTaker) // "Individual" Users don't need to input security
          || 
          (
            payload 
            && payload.hasOwnProperty('sub') 
            && payload.sub === data.adminUserId 
            && data.results !== null 
            && data.results !== undefined
          ) // If there are results, the admin can access the test without inputting the security code
        ) {
          setSecurityPassed(true)
          setShowInstructions(data.results !== null ? false : true)
        }

        // Reset Loading and Errors
        setLoading(false)
        setErrors(false)

      } catch (error) {
        console.log(error)

        // Reset Loading and Errors
        setLoading(false)
        setErrors(true)
      }
    }

    // Call getData
    getData()

  }, [])

  useEffect(() => {
    // console.log('currentCompany ->', currentCompany)
    // console.log('vcSimulation ->', vcSimulation)
    // console.log('hasResults ->', hasResults)
    // console.log('testIsFinished ->', testIsFinished)
    setLoading(true)
    const updateSimulation = async () => {
      if (
        vcSimulation !== undefined 
        && vcSimulation !== null 
        && Object.keys(vcSimulation).length > 0 
        && !testIsFinished
      ) {
        // Is Test Finished?
        const testFinished = await isTestFinished(vcSimulation)
        // console.log('testFinished ->', testFinished)
  
        if (testFinished !== testIsFinished) {
          // Set testIsFinished and hasResults
          setHasResults(vcSimulation.results !== null && vcSimulation.results !== undefined)
          setTestIsFinished(testFinished)
        } else {
          // set currentCompany
          currentCompanySetterFunction(vcSimulation)
        }
      }

      setLoading(false)
    }
    
    updateSimulation()

  }, [vcSimulation])

  useEffect(() => {
    // console.log('currentCompany ->', currentCompany)
    // console.log('exampleCurrentFollowOnIndex ->', exampleCurrentFollowOnIndex)

  }, [currentCompany])

  useEffect(() => {
    // console.log('testIsFinished ->', testIsFinished)

  }, [testIsFinished, preparingResults])


  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {/* {seoPageTags(
        'VC Simulation'
      )} */}

      {/* VC Simulation Body */}
      <Box sx={{ 
        width: '100vw', minWidth: '100vw',
        height: hasResults && !loading ? '' : '100vh', minHeight: hasResults ? '' : '100vh',
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
      }}>
        {
          (
            loading 
            || 
            (
              (
                currentCompany === undefined 
                || currentCompany === null 
                || Object.keys(currentCompany).length === 0
              ) 
              && !testIsFinished
            )
          ) ?
            calculatingStandardSpinner(
              preparingResults && !hasResults,
              (hasResults || Object.keys(vcSimulation).length === 0)
            )
            : errors ?
              requestError()
              :
              (hasMinimumScreenWidth && width < minimumScreenWidth && !testIsFinished) ?
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    width: '85%',
                  }}
                >
                  {`Your screen or tab is too narrow. To access the simulation, your screen/tab width must be at least ${minimumScreenWidth} pixels.`}
                </Typography>
                :
                <>
                  {/* Navbar for Submitted and Results Pages */}
                  {(hasResults && testIsFinished && vcSimulation.testTakerReflectionsSubmitted) &&
                    <PageNavbar/>
                  }
                  <Box sx={{ 
                    width: '100%', 
                    minHeight: (hasResults) ? `calc(100vh - ${navbarHeight} - ${footerHeight})` : '100%', 
                  }}>

                    {/* Security Modal is open until the correct security code is input */}
                    {!securityPassed &&
                      <Security 
                        securityCode={securityCode} securityPassed={securityPassed} setSecurityPassed={setSecurityPassed} setShowInstructions={setShowInstructions} testIsFinished={testIsFinished} hasResults={hasResults} investmentSelectionsLength={vcSimulation.hasOwnProperty('investmentSelections') ? vcSimulation.investmentSelections.length : 0 }
                      />
                    }
                    
                    {/* Live Simulation */}
                    {securityPassed 
                    && !testIsFinished 
                    && !hasResults 
                    && !showInstructions 
                    && (vcSimulation.testTakerInititialReflectionsSubmitted || vcSimulation.exampleCurrentInvestmentIndex < exampleCompaniesObj.exampleCompanies.length || vcSimulation.exampleCurrentFollowOnIndex < exampleCompaniesObj.exampleCompanies.length)
                    &&
                      <LiveVCSimulation vcSimulation={vcSimulation} setVCSimulation={setVCSimulation} currentCompany={currentCompany} setCurrentCompany={setCurrentCompany} rules={rules} setShowInstructions={setShowInstructions} exampleCurrentInvestmentIndex={exampleCurrentInvestmentIndex} setExampleCurrentInvestmentIndex={setExampleCurrentInvestmentIndex} exampleCurrentFollowOnIndex={exampleCurrentFollowOnIndex} setExampleCurrentFollowOnIndex={setExampleCurrentFollowOnIndex} loading={loading} setLoading={setLoading} setPreparingResults={setPreparingResults} isDemo={false} />
                    }

                    {/* Reflections */}
                    {securityPassed &&
                      (
                        (
                          vcSimulation.exampleCurrentInvestmentIndex >= exampleCompaniesObj.exampleCompanies.length
                          && vcSimulation.exampleCurrentFollowOnIndex >= exampleCompaniesObj.exampleCompanies.length
                          && !vcSimulation.testTakerInititialReflectionsSubmitted
                        ) // Pre-Test Reflections
                        ||
                        (
                          testIsFinished
                          && !vcSimulation.testTakerReflectionsSubmitted
                        ) // Post-Test Reflections
                      )
                      &&
                      <Reflections vcSimulation={vcSimulation} setVCSimulation={setVCSimulation} setHasResults={setHasResults} loading={loading} setLoading={setLoading} />
                    }

                    {/* Submitted — Test and Reflections Finished but no results */}
                    {securityPassed 
                      && testIsFinished 
                      && (!hasResults && vcSimulation.testTakerReflectionsSubmitted) 
                      &&
                        <Submitted vcSimulation={vcSimulation} setVCSimulation={setVCSimulation} setHasResults={setHasResults} loading={loading} setLoading={setLoading} />
                    }

                    {/* VC Results */}
                    {securityPassed 
                      && hasResults 
                      && testIsFinished 
                      && vcSimulation.testTakerReflectionsSubmitted 
                      &&
                        <VCResults vcSimulation={vcSimulation} rules={rules} loading={loading} />
                    }

                    {/* Spinner — For the blip of time during the results calculation when hasResults is set to true but testIsFinished has not yet been set */}
                    {securityPassed 
                      && hasResults 
                      && !testIsFinished 
                      &&
                      // standardSpinner()
                      calculatingStandardSpinner()
                    }


                    {/* Modals */}

                    {/* Show Instructions */}
                    {securityPassed && showInstructions &&
                      <Instructions vcSimulation={vcSimulation} setVCSimulation={setVCSimulation} rules={rules} showInstructions={showInstructions} setShowInstructions={setShowInstructions} width={width} />
                    }

                  </Box>

                  {/* Footer for Submitted and Results Pages*/}
                  {hasResults && testIsFinished && vcSimulation.testTakerReflectionsSubmitted &&
                    <Footer/>
                  }
                </>
        }
      </Box>
    </>
  )
}

export default VCSimulation