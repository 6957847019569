
// Gets token from local storage
export const getTokenFromLocalStorage = () => {
  return window.localStorage.getItem('simcap')
}

// Set new token to local storage
export const setTokenToLocalStorage = (token) => {
  window.localStorage.setItem('simcap', token)
}

// Retrieving profile pic from local storage
export const getProfPicFromLocalStorage = () => {
  return window.localStorage.getItem('profPic')
}

// Setting profile pic to local storage
export const setProfPicToLocalStorage = (imageURL) => {
  window.localStorage.setItem('profPic', imageURL)
}

// Retrieving email from local storage
export const getEmailFromLocalStorage = () => {
  return window.localStorage.getItem('email')
}

// Setting email to local storage
export const setEmailToLocalStorage = (emailString) => {
  window.localStorage.setItem('email', emailString)
}

// Splits the token then returns the payload encoded using base64
export const getPayload = () => {
  const token = getTokenFromLocalStorage()
  if (!token) return
  const payload = token.split('.')[1]
  return JSON.parse(atob(payload))
}

// Get User Id from Token
export const getUserIdFromToken = (token) => {
  const payload = token.split('.')[1]
  return JSON.parse(atob(payload)).sub
}

// Checks if user is authenticated
export const userIsAuthenticated = () => {
  const payload = getPayload()
  if (!payload) return false
  const currentTime = Math.floor(Date.now() / 1000)
  return currentTime < payload.exp
}