import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { standardButton } from '../../helpers/buttons'
import { priceCardWidth, simcapPink, simcapTurquoise } from '../../helpers/variableDefaults'

const PriceCard = (props) => {

  // Destructure Props
  const { price } = props

  // Navigate
  const navigate = useNavigate()

  // URL Path
  const urlPath = window.location.pathname

  // Navigate to /create-account when Create Account button Clicked
  const handleCreateAccount = async (e) => {
    e.preventDefault()
    navigate('/create-account')
  }

  return (
    <Card 
      variant="outlined"
      sx={{ 
        width: '100%', maxWidth: priceCardWidth,
        border: `solid 2px black`, borderRadius: '20px',
        boxShadow: 6,
      }}
    >
      <CardContent>
        {/* <Typography variant="h5" component="div" textAlign={'center'}
          sx={{
            mb: 2,
            fontWeight: 'bold',
            color: simcapPink,
          }}
        >
          {price}
        </Typography> */}

        {/* <Typography component="pre"
          textAlign={'center'}
          sx={{
            mb: 1,
            fontSize: '20px',
            fontWeight: 'bolder',
            // fontStyle: 'italic',
            color: 'black',
          }}
        >
          {
            `Pricing`.replace(/ {4} +/g, '')
          }
        </Typography> */}

        {/* <Typography component="pre"
          sx={{
            mb: 2,
            fontWeight: 'bold',
            fontStyle: 'italic',
            // color: simcapPink,
          }}
        >
          {
            `— 7-day Free Trial
            — Unlimited Simulations`.replace(/ {4} +/g, '')
          }
        </Typography> */}

        <Typography component="pre"
          textAlign={'center'}
          sx={{
            mb: 1,
            fontSize: '20px',
            fontWeight: 'bolder',
            // fontStyle: 'italic',
            color: 'black',
          }}
        >
          {
            `Features`.replace(/ {4} +/g, '')
          }
        </Typography>

        <Typography component="pre" 
          sx={{
            fontStyle: 'italic',
            fontWeight: 'bold',
          }}
        >
          {/* the regex is for deleting the indentation spaces in the string literal` */}
          {
            `— Unlimited Simulations
            — Detailed Results
            — Intuitive Interface
            — Interview Candidates
            — Teach the Craft
            — Two-Factor Authentication`.replace(/ {4} +/g, '')
          }
        </Typography>

      </CardContent>
      <CardActions 
        sx={{
          mb: 2,
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        }}
      >
        {standardButton(
          'Create Accont', 
          'button',
          'contained',
          false,
          'primary', 
          0,
          0,
          0,
          '150px', 
          '45px', 
          handleCreateAccount
        )}
      </CardActions>
    </Card>
  )
}

export default PriceCard