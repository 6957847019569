import React from 'react'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

import { privacyPolicyMaxWidth } from '../../helpers/variableDefaults'

import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

// TermsOfService Page
const TermsOfService = () => {
  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'TOS'
      )}

      {/* TOS Body */}
      <Container sx={{ width: '100vw', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
        
        {/* Header */}
        <Box 
          sx={{
            width: '100%',
            maxWidth: privacyPolicyMaxWidth,
            display: 'flex', justifyContent: 'center',
          }}
        >
          <h1>Terms of Service</h1>
        </Box>

        {/* Body */}
        <Box 
          sx={{
            pb: 8,
            width: '100%',
            maxWidth: privacyPolicyMaxWidth,
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: privacyPolicyMaxWidth,
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <h2>1. Terms</h2>
          </Box>
          
          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: privacyPolicyMaxWidth,
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <p>By accessing and using this Website, accessible from www.simcap.vc, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.</p>
          </Box>
          


          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: privacyPolicyMaxWidth,
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <h2>2. Short and Sweet</h2>
          </Box>
          
          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: privacyPolicyMaxWidth,
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
            }}
          >
            <p>All personal data provided to the Website by a User has been provided voluntarily, and the Website has no liability for anything that may happen with that data after it is uploaded.</p>
          </Box>

          


          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: privacyPolicyMaxWidth,
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <h2>3. Disclaimer</h2>
          </Box>
          
          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: privacyPolicyMaxWidth,
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <p>All the materials on SimCap&apos;s Website are provided as is. SimCap makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, SimCap does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.</p>
          </Box>


          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: privacyPolicyMaxWidth,
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <h2>4. Limitations</h2>
          </Box>
          
          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: privacyPolicyMaxWidth,
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <p>SimCap will not be hold accountable for any damages that will arise with the use or inability to use the materials on SimCap&apos;s Website, even if SimCap or an authorized representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>
          </Box>

          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: privacyPolicyMaxWidth,
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <h2>5. Revisions and Errata</h2>
          </Box>
          
          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: privacyPolicyMaxWidth,
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <p>The materials appearing on SimCap&apos;s Website may include technical, typographical, or photographic errors. SimCap will not promise that any of the materials in this Website are accurate, complete, or current. SimCap may change the materials contained on its Website at any time without notice. SimCap does not make any commitment to update the materials.</p>
          </Box>


          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: privacyPolicyMaxWidth,
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <h2>6. Links</h2>
          </Box>

          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: privacyPolicyMaxWidth,
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <p>SimCap has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by SimCap of the site. The use of any linked website is at the User&apos;s own risk.</p>
          </Box>


          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: privacyPolicyMaxWidth,
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <h2>7. Site Terms of Use Modifications</h2>
          </Box>

          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: privacyPolicyMaxWidth,
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <p>SimCap may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>
          </Box>

          


          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: privacyPolicyMaxWidth,
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <h2>8. Your Privacy</h2>
          </Box>

          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: privacyPolicyMaxWidth,
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <p>Please read our <a href='/privacy' target="#" style={{ cursor: 'pointer', textDecoration: 'underline', color: 'mediumorchid' }} >Privacy Policy</a>.</p>
          </Box>


          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: privacyPolicyMaxWidth,
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <h2>9. Governing Law</h2>
          </Box>

          <Box 
            sx={{
              // mt: 2,
              width: '100%',
              maxWidth: privacyPolicyMaxWidth,
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
            }}
          >
            <p>Any claim related to SimCap&apos;s Website shall be governed by the laws of us without regards to its conflict of law provisions.</p>
          </Box>

        </Box>
      </Container>
    </>
  )
}

export default TermsOfService