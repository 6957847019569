/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Divider from '@mui/material/Divider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import { navbarHeight, footerHeight, positionChangeWidthSm, positionChangeWidthLg, simcapTurquoise } from '../../../../helpers/variableDefaults'
import { standardSpinner } from '../../../../helpers/spinners'
import { couldNotLoadPageError } from '../../../../helpers/errors'
import { makeTabIndex } from '../../../../helpers/formatting'
import { TabPanel } from '../../../../helpers/formatting'
import { standardInfoTooltip } from '../../../../helpers/tooltips'


const analysisElementAccordionJSX = (title, labelsValuesArray, expandedDefault = false) => {
  return (
    // Instantiate an Accordion
    <Accordion
      defaultExpanded={expandedDefault}
      sx={{ 
        width: '85%',
        boxShadow: 4, 
      }}
    >
      {/* Title appears on Accordion Summary */}
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          backgroundColor: 'lightblue',
          // backgroundColor: simcapTurquoise,
          fontSize: '18px',
          border: 2,
          borderBottom: 2,
          borderColor: 'black',
        }}
      >
        <Typography
          sx={{
            width: '100%',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          <strong>{title.toUpperCase()}</strong>
        </Typography>
      </AccordionSummary>

      {/* Labels and Values */}
      <AccordionDetails
        sx={{
          backgroundColor: 'lightyellow',
          // backgroundColor: 'white',
          border: 2,
          borderTop: 0,
          borderColor: 'black',
          display: 'flex', flexDirection: 'column',
        }}
      >
        {labelsValuesArray.map((labelsValuesObj, labelsValuesIndex) => (
          typeof labelsValuesObj.value !== 'object'
            ?
            <Box
              key={labelsValuesIndex}
              sx={{
                mb: 1, 
                mt: labelsValuesIndex === 0 ? 1 : 0,
                width: '100%',
                display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
              }}
            >
              {/* {console.log('typeof labelsValuesObj.value 1 ->', typeof labelsValuesObj.value)} */}
              {/* Label */}
              <Box
                sx={{
                  mr: 1,
                  width: 
                    labelsValuesObj.label.includes('Positive Luck') || labelsValuesObj.label.includes('Negative Luck') 
                      ? 
                      '75%' 
                      : 
                      labelsValuesObj.label.includes('Poor Pacing Penalty')
                        ?
                        '65%'
                        :
                        '45%',
                  // fontWeight: 'bold',
                  display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '18px',
                  }}
                >
                  {labelsValuesObj.label}
                </Typography>
                {labelsValuesObj.hasOwnProperty('tooltip') && standardInfoTooltip(labelsValuesObj.tooltip, 1)}
              </Box>
              
              {/* Value */}
              <Box
                sx={{
                  width: 
                    labelsValuesObj.label.includes('Positive Luck') || labelsValuesObj.label.includes('Negative Luck') 
                      ? 
                      '20%' 
                      : 
                      labelsValuesObj.label.includes('Poor Pacing Penalty')
                        ?
                        '30%'
                        :
                        '50%',
                  fontWeight: 'bold',
                  fontSize: '18px',
                  display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >
                {labelsValuesObj.value}
              </Box>
            </Box>
            :
            <Box
              key={labelsValuesIndex}
              sx={{
                mb: 1,
                mt: labelsValuesIndex === 0 ? 1 : 0,
                width: '100%',
                display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
              }}
            >
              {/* {console.log('typeof labelsValuesObj.value 2 ->', typeof labelsValuesObj.value)} */}
              {/* Subtitle */}
              <Box
                sx={{
                  mb: 1,
                  width: '100%',
                  fontSize: '18px',
                  display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >
                {labelsValuesObj.label}
              </Box>

              {/* Sublabel and Subvalues */}
              {labelsValuesObj.value.map((subLabelValueItem, subLabelValueItemIndex) => (
                <Box
                  key={subLabelValueItemIndex}
                  sx={{
                    mb: 1, ml: 6, pr: 2,
                    width: '100%',
                    display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start',
                  }}
                >
                  {/* Sub-Label */}
                  <Box
                    sx={{
                      mr: 0.5,
                      width: '100%',
                      // fontWeight: 'bold',
                      display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                    }}
                  >
                    {subLabelValueItem.subLabel}
                  </Box>
              
                  {/* Sub-Value */}
                  <Box
                    sx={{
                      width: '100%',
                      fontWeight: 'bold',
                      display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                    }}
                  >
                    {subLabelValueItem.subValue}
                  </Box>
                </Box>
              ))}

            </Box>
        ))}
      </AccordionDetails>

    </Accordion>
  )
}

export const vcResultsAnalysisTabJSX = (value, index, results, vcSimulation, loading, width) => {
  return (
    <TabPanel value={value} index={index}>
      {loading ?
        standardSpinner()
        : 
        <Box sx={{ 
          // backgroundColor: 'yellow',
          mt: 0, mb: 0, 
          width: '90vw', maxWidth: positionChangeWidthSm, 
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
        }}>
          <Box
            sx={{
              mt: 2, mb: 8, py: 4,
              backgroundColor: 'whitesmoke',
              // backgroundColor: 'black',
              width: '100%', maxWidth: '600px',
              boxShadow: 3, borderRadius: 3,
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
            }}
          >

            {/* Relative Results */}
            {analysisElementAccordionJSX(
              'Relative Results', 
              [
                
                {
                  label: `Decision Quality:`,
                  value: `${results.importantNumbers.decisionQualityPercentile}%`,
                  tooltip: `Measures how good your decisions were, independent of luck`,
                },
                {
                  label: `LP Multiple Percentile:`,
                  value: `${results.importantNumbers.lpMultiplePercentile}%`,
                  tooltip: `The percentile your portfolio’s LP Multiple lands in when compared to 4k portfolios of randomly selected companies`,
                },
                {
                  label: `Luck Percentile:`,
                  value: `${results.importantNumbers.luckPercentile}%`,
                  tooltip: `Measures how lucky or unlucky your chosen investments were`,
                }
              ], 
              true
            )}


            {/* Fund-Level Results */}
            {analysisElementAccordionJSX(
              'Fund-Level Results', 
              [
                {
                  label: `LP Multiple:`,
                  value: `${results.importantNumbers.lpMultiple}x`,
                  tooltip: `The multiple Sim Cap investors attain on the full $${vcSimulation.standardizedSimulation.rules.fundSize}M invested into Sim Cap (including fees and expenses)`,
                },
                {
                  label: `LP IRR:`,
                  value: `${(results.importantNumbers.lpIRR * 100).toFixed(2)}%`,
                  tooltip: `The interest rate at which an LPs investment would be breakeven (higher is better). Fund's shoot for 30%+, but 20%+ is considered very good`,
                },
                {
                  label: `Gross Multiple:`,
                  value: `${results.importantNumbers.grossMultiple}x`,
                  tooltip: `The multiple Sim Cap attains on the $${vcSimulation.standardizedSimulation.rules.fundSize}M invested into companies (excludes fees and expenses)`,
                },
                {
                  label: `Gross IRR:`,
                  value: `${(results.importantNumbers.grossIRR * 100).toFixed(2)}%`,
                  tooltip: `The interest rate at which the Fund's investments would be breakeven (excludes fees and expenses)`,
                },
                {
                  label: `Carried Interest:`,
                  value: `$${results.importantNumbers.totalCarry}M`,
                  tooltip: `Dollars the Fund returns to its managers. The rate assumed here is 20% once initial investment plus 8% annual fee is returned to LPs, increasing to 25% if LP multiple is 3x+.`,
                },
                {
                  label: `Cash Invested:`,
                  value: `$${results.importantNumbers.amountInvested}M`,
                  // tooltip: ``,
                },
                {
                  label: `Amount Returned:`,
                  value: `$${results.importantNumbers.totalReturned}M`,
                  // tooltip: ``,
                }
              ], 
              true
            )}


            {/* Decisions */}
            {analysisElementAccordionJSX(
              'Decisions', 
              [
                {
                  label: `Initial Decision Quality`,
                  value: [
                    {
                      subLabel: `Excellent:`,
                      subValue: `${results.behavior.initialInvestmentDecisionQuality.excellent}`,
                    },
                    {
                      subLabel: `Good:`,
                      subValue: `${results.behavior.initialInvestmentDecisionQuality.good}`,
                    },
                    {
                      subLabel: `Understandable:`,
                      subValue: `${results.behavior.initialInvestmentDecisionQuality.understandable}`,
                    },
                    {
                      subLabel: `Unwise:`,
                      subValue: `${results.behavior.initialInvestmentDecisionQuality.unwise}`,
                    },
                    {
                      subLabel: `Boneheaded:`,
                      subValue: `${results.behavior.initialInvestmentDecisionQuality.boneheaded}`,
                    }
                  ],
                },
                {
                  label: `Momentum for Follow-Ons`,
                  value: [
                    {
                      subLabel: `Strong:`,
                      subValue: `${results.behavior.followOnsByMomentum.strong}`,
                    },
                    {
                      subLabel: `Forward Progress:`,
                      subValue: `${results.behavior.followOnsByMomentum.forward}`,
                    },
                    {
                      subLabel: `Flat:`,
                      subValue: `${results.behavior.followOnsByMomentum.flat}`,
                    },
                    {
                      subLabel: `Surviving:`,
                      subValue: `${results.behavior.followOnsByMomentum.surviving}`,
                    },
                    {
                      subLabel: `Existential:`,
                      subValue: `${results.behavior.followOnsByMomentum.existential}`,
                    }
                  ],
                },
                {
                  label: `Results`,
                  value: [
                    {
                      subLabel: `Homerun:`,
                      subValue: `${results.resultsSummary.filter(item => item.result === 'Homerun').length}`,
                    },
                    {
                      subLabel: `Good:`,
                      subValue: `${results.resultsSummary.filter(item => item.result === 'Good').length}`,
                    },
                    {
                      subLabel: `Moderate:`,
                      subValue: `${results.resultsSummary.filter(item => item.result === 'Moderate').length}`,
                    },
                    {
                      subLabel: `Single:`,
                      subValue: `${results.resultsSummary.filter(item => item.result === 'Single').length}`,
                    },
                    {
                      subLabel: `Forced Sale:`,
                      subValue: `${results.resultsSummary.filter(item => item.result === 'Forced Sale').length}`,
                    },
                    {
                      subLabel: `Zero:`,
                      subValue: `${results.resultsSummary.filter(item => item.result === 'Zero').length}`,
                    }
                  ],
                }
              ], 
              false
            )}


            {/* Decision Factor Priorities */}
            {analysisElementAccordionJSX(
              'Decision Factor Priorities', 
              [
                {
                  label: `Initial Investments by Average Factor Percentiles`,
                  value: [
                    {
                      subLabel: `${results.behavior.relativePriorityInitialDecisionFactors[0].factor}`,
                      subValue: `${results.behavior.relativePriorityInitialDecisionFactors[0].percentile}%`,
                    },
                    {
                      subLabel: `${results.behavior.relativePriorityInitialDecisionFactors[1].factor}`,
                      subValue: `${results.behavior.relativePriorityInitialDecisionFactors[1].percentile}%`,
                    },
                    {
                      subLabel: `${results.behavior.relativePriorityInitialDecisionFactors[2].factor}`,
                      subValue: `${results.behavior.relativePriorityInitialDecisionFactors[2].percentile}%`,
                    },
                    {
                      subLabel: `${results.behavior.relativePriorityInitialDecisionFactors[3].factor}`,
                      subValue: `${results.behavior.relativePriorityInitialDecisionFactors[3].percentile}%`,
                    },
                    {
                      subLabel: `${results.behavior.relativePriorityInitialDecisionFactors[4].factor}`,
                      subValue: `${results.behavior.relativePriorityInitialDecisionFactors[4].percentile}%`,
                    },
                    {
                      subLabel: `${results.behavior.relativePriorityInitialDecisionFactors[5].factor}`,
                      subValue: `${results.behavior.relativePriorityInitialDecisionFactors[5].percentile}%`,
                    },
                    {
                      subLabel: `${results.behavior.relativePriorityInitialDecisionFactors[6].factor}`,
                      subValue: `${results.behavior.relativePriorityInitialDecisionFactors[6].percentile}%`,
                    }
                  ],
                },
                {
                  label: `Strengths User Prioritizes`,
                  value: [
                    {
                      subLabel: `Team:`,
                      subValue: `${results.behavior.topStrengths.team[0].description}, ${results.behavior.topStrengths.team[1].description}, ${results.behavior.topStrengths.team[2].description}`,
                    },
                    {
                      subLabel: `Problem/Solution:`,
                      subValue: `${results.behavior.topStrengths.problemSolution[0].description}`,
                    },
                    {
                      subLabel: `Differentiation:`,
                      subValue: `${results.behavior.topStrengths.differentiation[0].description}, ${results.behavior.topStrengths.differentiation[1].description}`,
                    },
                    {
                      subLabel: `Market:`,
                      subValue: `${results.behavior.topStrengths.market[0].description}, ${results.behavior.topStrengths.market[1].description}`,
                    },
                    {
                      subLabel: `Commercialization:`,
                      subValue: `${results.behavior.topStrengths.commercialization[0].description}, ${results.behavior.topStrengths.commercialization[1].description}`,
                    },
                    {
                      subLabel: `Additional Risks:`,
                      subValue: `${results.behavior.topStrengths.additionalRisks[0].description}, ${results.behavior.topStrengths.additionalRisks[1].description}`,
                    },
                    {
                      subLabel: `Financial Expectations:`,
                      subValue: `${results.behavior.topStrengths.financialExpectations[0].description}, ${results.behavior.topStrengths.financialExpectations[1].description}`,
                    }
                  ],
                },
                {
                  label: `Risks User Accepts`,
                  value: [
                    {
                      subLabel: `Team:`,
                      subValue: `${results.behavior.biggestRisks.team[0].description}, ${results.behavior.biggestRisks.team[1].description}, ${results.behavior.biggestRisks.team[2].description}`,
                    },
                    {
                      subLabel: `Problem/Solution:`,
                      subValue: `${results.behavior.biggestRisks.problemSolution[0].description}`,
                    },
                    {
                      subLabel: `Differentiation:`,
                      subValue: `${results.behavior.biggestRisks.differentiation[0].description}, ${results.behavior.biggestRisks.differentiation[1].description}`,
                    },
                    {
                      subLabel: `Market:`,
                      subValue: `${results.behavior.biggestRisks.market[0].description}, ${results.behavior.biggestRisks.market[1].description}`,
                    },
                    {
                      subLabel: `Commercialization:`,
                      subValue: `${results.behavior.biggestRisks.commercialization[0].description}, ${results.behavior.biggestRisks.commercialization[1].description}`,
                    },
                    {
                      subLabel: `Additional Risks:`,
                      subValue: `${results.behavior.biggestRisks.additionalRisks[0].description}, ${results.behavior.biggestRisks.additionalRisks[1].description}`,
                    },
                    {
                      subLabel: `Financial Expectations:`,
                      subValue: `${results.behavior.biggestRisks.financialExpectations[0].description}, ${results.behavior.biggestRisks.financialExpectations[1].description}`,
                    }
                  ],
                }
              ], 
              false
            )}


            {/* Pacing */}
            {analysisElementAccordionJSX(
              'Pacing', 
              [
                {
                  label: `Poor Pacing Penalty Investments:`,
                  value: results.behavior.poorPacingInvestmentIds.length,
                  tooltip: `A ${vcSimulation.standardizedSimulation.rules.poorPacingExitMultipleDiscount * 100}% reduction in expected dollars is applied to all initial investments for which the ${vcSimulation.standardizedSimulation.rules.newFundraisingThreshold * 100}% threshold is not met after ${vcSimulation.standardizedSimulation.rules.poorPacingMonthThreshold} months has passed, until the ${vcSimulation.standardizedSimulation.rules.newFundraisingThreshold * 100}% threshold has been met. If you have invested in ${vcSimulation.simulationVersion === 'choose20v1' ? 12 : 6} or more companies by month ${vcSimulation.standardizedSimulation.rules.poorPacingMonthThreshold}, no penalty is applied; if you have invested in fewer companies by the threshold month, the penalty is applied to each investment outcome until ${vcSimulation.simulationVersion === 'choose20v1' ? 12 : 6} investments is reached.`,
                },
                {
                  label: `Initial Investments by Decision Number`,
                  value: [
                    {
                      subLabel: `${results.behavior.initialInvestmentsByDecisionNumber[0].decisionNumber}`,
                      subValue: `${results.behavior.initialInvestmentsByDecisionNumber[0].investmentsMade}`,
                    },
                    {
                      subLabel: `${results.behavior.initialInvestmentsByDecisionNumber[1].decisionNumber}`,
                      subValue: `${results.behavior.initialInvestmentsByDecisionNumber[1].investmentsMade}`,
                    },
                    {
                      subLabel: `${results.behavior.initialInvestmentsByDecisionNumber[2].decisionNumber}`,
                      subValue: `${results.behavior.initialInvestmentsByDecisionNumber[2].investmentsMade}`,
                    },
                    {
                      subLabel: `${results.behavior.initialInvestmentsByDecisionNumber[3].decisionNumber}`,
                      subValue: `${results.behavior.initialInvestmentsByDecisionNumber[3].investmentsMade}`,
                    },
                    {
                      subLabel: `${results.behavior.initialInvestmentsByDecisionNumber[4].decisionNumber}`,
                      subValue: `${results.behavior.initialInvestmentsByDecisionNumber[4].investmentsMade}`,
                    }
                  ],
                },
                {
                  label: `Initial Investments by Month Number`,
                  value: [
                    {
                      subLabel: `${results.behavior.initialInvestmentsByMonth[0].monthNumber}`,
                      subValue: `${results.behavior.initialInvestmentsByMonth[0].investmentsMade}`,
                    },
                    {
                      subLabel: `${results.behavior.initialInvestmentsByMonth[1].monthNumber}`,
                      subValue: `${results.behavior.initialInvestmentsByMonth[1].investmentsMade}`,
                    },
                    {
                      subLabel: `${results.behavior.initialInvestmentsByMonth[2].monthNumber}`,
                      subValue: `${results.behavior.initialInvestmentsByMonth[2].investmentsMade}`,
                    },
                    {
                      subLabel: `${results.behavior.initialInvestmentsByMonth[3].monthNumber}`,
                      subValue: `${results.behavior.initialInvestmentsByMonth[3].investmentsMade}`,
                    },
                    {
                      subLabel: `${results.behavior.initialInvestmentsByMonth[4].monthNumber}`,
                      subValue: `${results.behavior.initialInvestmentsByMonth[4].investmentsMade}`,
                    }
                  ],
                }
              ], 
              false
            )}


            {/* Luck */}
            {analysisElementAccordionJSX(
              'Luck', 
              [
                {
                  label: `Positive Luck Initial Investments:`,
                  value: `${results.importantNumbers.luck.positiveLuckInitialInvestments}`,
                  tooltip: `Number of 'Unwise' or 'Boneheaded' decisions that became 'Good' or 'Homerun' results`,
                },
                {
                  label: `Positive Luck Follow-On Investments:`,
                  value: `${results.importantNumbers.luck.positiveLuckFollowOnInvestments}`,
                  tooltip: `Number of 'Surviving' or 'Existential' follow-ons that became 'Good' or 'Homerun' results`,
                },
                {
                  label: `Negative Luck Initial Investments:`,
                  value: `${results.importantNumbers.luck.negativeLuckInitialInvestments}`,
                  tooltip: `Number of 'Excellent' or 'Good' decisions that became 'Zero' or 'Forced Sale' results`,
                },
                {
                  label: `Negative Luck Follow-On Investments:`,
                  value: `${results.importantNumbers.luck.negativeLuckFollowOnInvestments}`,
                  tooltip: `Number of 'Strong Momentum' or 'Forward Progress' follow-ons that became 'Zero' or 'Forced Sale' results`,
                }
              ], 
              false
            )}


            {/* Team Factors */}
            {analysisElementAccordionJSX(
              'Team Factors', 
              [
                {
                  label: `Relationship With Leadership`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.team.relationshipWithLeadership[0].description}`,
                      subValue: `${results.factorSummaries.team.relationshipWithLeadership[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.relationshipWithLeadership[1].description}`,
                      subValue: `${results.factorSummaries.team.relationshipWithLeadership[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.relationshipWithLeadership[2].description}`,
                      subValue: `${results.factorSummaries.team.relationshipWithLeadership[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.relationshipWithLeadership[3].description}`,
                      subValue: `${results.factorSummaries.team.relationshipWithLeadership[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.relationshipWithLeadership[4].description}`,
                      subValue: `${results.factorSummaries.team.relationshipWithLeadership[4].count}`,
                    }
                  ],
                },
                {
                  label: `Management Tech Capabilities`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.team.managementTechCapabilities[0].description}`,
                      subValue: `${results.factorSummaries.team.managementTechCapabilities[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.managementTechCapabilities[1].description}`,
                      subValue: `${results.factorSummaries.team.managementTechCapabilities[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.managementTechCapabilities[2].description}`,
                      subValue: `${results.factorSummaries.team.managementTechCapabilities[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.managementTechCapabilities[3].description}`,
                      subValue: `${results.factorSummaries.team.managementTechCapabilities[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.managementTechCapabilities[4].description}`,
                      subValue: `${results.factorSummaries.team.managementTechCapabilities[4].count}`,
                    }
                  ],
                },
                {
                  label: `Cultural Leadership`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.team.culturalLeadership[0].description}`,
                      subValue: `${results.factorSummaries.team.culturalLeadership[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.culturalLeadership[1].description}`,
                      subValue: `${results.factorSummaries.team.culturalLeadership[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.culturalLeadership[2].description}`,
                      subValue: `${results.factorSummaries.team.culturalLeadership[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.culturalLeadership[3].description}`,
                      subValue: `${results.factorSummaries.team.culturalLeadership[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.culturalLeadership[4].description}`,
                      subValue: `${results.factorSummaries.team.culturalLeadership[4].count}`,
                    }
                  ],
                },
                {
                  label: `Networking Chops`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.team.networkingChops[0].description}`,
                      subValue: `${results.factorSummaries.team.networkingChops[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.networkingChops[1].description}`,
                      subValue: `${results.factorSummaries.team.networkingChops[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.networkingChops[2].description}`,
                      subValue: `${results.factorSummaries.team.networkingChops[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.networkingChops[3].description}`,
                      subValue: `${results.factorSummaries.team.networkingChops[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.networkingChops[4].description}`,
                      subValue: `${results.factorSummaries.team.networkingChops[4].count}`,
                    }
                  ],
                },
                {
                  label: `Board and Advisors`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.team.boardAndAdvisors[0].description}`,
                      subValue: `${results.factorSummaries.team.boardAndAdvisors[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.boardAndAdvisors[1].description}`,
                      subValue: `${results.factorSummaries.team.boardAndAdvisors[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.boardAndAdvisors[2].description}`,
                      subValue: `${results.factorSummaries.team.boardAndAdvisors[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.boardAndAdvisors[3].description}`,
                      subValue: `${results.factorSummaries.team.boardAndAdvisors[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.boardAndAdvisors[4].description}`,
                      subValue: `${results.factorSummaries.team.boardAndAdvisors[4].count}`,
                    }
                  ],
                },
                {
                  label: `Hiring Difficulty`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.team.hiringDifficulty[0].description}`,
                      subValue: `${results.factorSummaries.team.hiringDifficulty[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.hiringDifficulty[1].description}`,
                      subValue: `${results.factorSummaries.team.hiringDifficulty[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.hiringDifficulty[2].description}`,
                      subValue: `${results.factorSummaries.team.hiringDifficulty[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.hiringDifficulty[3].description}`,
                      subValue: `${results.factorSummaries.team.hiringDifficulty[3].count}`,
                    }
                  ],
                },
                {
                  label: `CEO Replacement`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.team.ceoReplacement[0].description}`,
                      subValue: `${results.factorSummaries.team.ceoReplacement[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.ceoReplacement[1].description}`,
                      subValue: `${results.factorSummaries.team.ceoReplacement[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.ceoReplacement[2].description}`,
                      subValue: `${results.factorSummaries.team.ceoReplacement[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.ceoReplacement[3].description}`,
                      subValue: `${results.factorSummaries.team.ceoReplacement[3].count}`,
                    }
                  ],
                },
                {
                  label: `Strategic Investor`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.team.strategicInvestor[0].description}`,
                      subValue: `${results.factorSummaries.team.strategicInvestor[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.strategicInvestor[1].description}`,
                      subValue: `${results.factorSummaries.team.strategicInvestor[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.strategicInvestor[2].description}`,
                      subValue: `${results.factorSummaries.team.strategicInvestor[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.team.strategicInvestor[3].description}`,
                      subValue: `${results.factorSummaries.team.strategicInvestor[3].count}`,
                    }
                  ],
                }
              ], 
              false
            )}


            {/* Problem/Solution Factors */}
            {analysisElementAccordionJSX(
              'Problem/Solution Factors', 
              [
                {
                  label: `Problem`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.problemSolution.problem[0].description}`,
                      subValue: `${results.factorSummaries.problemSolution.problem[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.problemSolution.problem[1].description}`,
                      subValue: `${results.factorSummaries.problemSolution.problem[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.problemSolution.problem[2].description}`,
                      subValue: `${results.factorSummaries.problemSolution.problem[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.problemSolution.problem[3].description}`,
                      subValue: `${results.factorSummaries.problemSolution.problem[3].count}`,
                    }
                  ],
                },
                {
                  label: `Solution`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.problemSolution.solution[0].description}`,
                      subValue: `${results.factorSummaries.problemSolution.solution[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.problemSolution.solution[1].description}`,
                      subValue: `${results.factorSummaries.problemSolution.solution[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.problemSolution.solution[2].description}`,
                      subValue: `${results.factorSummaries.problemSolution.solution[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.problemSolution.solution[3].description}`,
                      subValue: `${results.factorSummaries.problemSolution.solution[3].count}`,
                    }
                  ],
                },
                {
                  label: `Sustainability`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.problemSolution.sustainability[0].description}`,
                      subValue: `${results.factorSummaries.problemSolution.sustainability[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.problemSolution.sustainability[1].description}`,
                      subValue: `${results.factorSummaries.problemSolution.sustainability[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.problemSolution.sustainability[2].description}`,
                      subValue: `${results.factorSummaries.problemSolution.sustainability[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.problemSolution.sustainability[3].description}`,
                      subValue: `${results.factorSummaries.problemSolution.sustainability[3].count}`,
                    }
                  ],
                }
              ], 
              false
            )}


            {/* Differentiation Factors */}
            {analysisElementAccordionJSX(
              'Differentiation Factors', 
              [
                {
                  label: `Secret Sauce`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.differentiation.secretSauce[0].description}`,
                      subValue: `${results.factorSummaries.differentiation.secretSauce[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.differentiation.secretSauce[1].description}`,
                      subValue: `${results.factorSummaries.differentiation.secretSauce[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.differentiation.secretSauce[2].description}`,
                      subValue: `${results.factorSummaries.differentiation.secretSauce[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.differentiation.secretSauce[3].description}`,
                      subValue: `${results.factorSummaries.differentiation.secretSauce[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.differentiation.secretSauce[4].description}`,
                      subValue: `${results.factorSummaries.differentiation.secretSauce[4].count}`,
                    }
                  ],
                },
                {
                  label: `Value Chain`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.differentiation.valueChain[0].description}`,
                      subValue: `${results.factorSummaries.differentiation.valueChain[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.differentiation.valueChain[1].description}`,
                      subValue: `${results.factorSummaries.differentiation.valueChain[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.differentiation.valueChain[2].description}`,
                      subValue: `${results.factorSummaries.differentiation.valueChain[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.differentiation.valueChain[3].description}`,
                      subValue: `${results.factorSummaries.differentiation.valueChain[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.differentiation.valueChain[4].description}`,
                      subValue: `${results.factorSummaries.differentiation.valueChain[4].count}`,
                    }
                  ],
                },
                {
                  label: `Intellectual Property`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.differentiation.ip[0].description}`,
                      subValue: `${results.factorSummaries.differentiation.ip[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.differentiation.ip[1].description}`,
                      subValue: `${results.factorSummaries.differentiation.ip[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.differentiation.ip[2].description}`,
                      subValue: `${results.factorSummaries.differentiation.ip[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.differentiation.ip[3].description}`,
                      subValue: `${results.factorSummaries.differentiation.ip[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.differentiation.ip[4].description}`,
                      subValue: `${results.factorSummaries.differentiation.ip[4].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.differentiation.ip[5].description}`,
                      subValue: `${results.factorSummaries.differentiation.ip[5].count}`,
                    }
                  ],
                },
                {
                  label: `Landscape`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.differentiation.landscape[0].description}`,
                      subValue: `${results.factorSummaries.differentiation.landscape[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.differentiation.landscape[1].description}`,
                      subValue: `${results.factorSummaries.differentiation.landscape[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.differentiation.landscape[2].description}`,
                      subValue: `${results.factorSummaries.differentiation.landscape[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.differentiation.landscape[3].description}`,
                      subValue: `${results.factorSummaries.differentiation.landscape[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.differentiation.landscape[4].description}`,
                      subValue: `${results.factorSummaries.differentiation.landscape[4].count}`,
                    }
                  ],
                }
              ], 
              false
            )}


            {/* Market Factors */}
            {analysisElementAccordionJSX(
              'Market Factors', 
              [
                {
                  label: `Size`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.market.size[0].description}`,
                      subValue: `${results.factorSummaries.market.size[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.size[1].description}`,
                      subValue: `${results.factorSummaries.market.size[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.size[2].description}`,
                      subValue: `${results.factorSummaries.market.size[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.size[3].description}`,
                      subValue: `${results.factorSummaries.market.size[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.size[4].description}`,
                      subValue: `${results.factorSummaries.market.size[4].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.size[5].description}`,
                      subValue: `${results.factorSummaries.market.size[5].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.size[6].description}`,
                      subValue: `${results.factorSummaries.market.size[6].count}`,
                    }
                  ],
                },
                {
                  label: `Accessibility`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.market.accessibility[0].description}`,
                      subValue: `${results.factorSummaries.market.accessibility[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.accessibility[1].description}`,
                      subValue: `${results.factorSummaries.market.accessibility[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.accessibility[2].description}`,
                      subValue: `${results.factorSummaries.market.accessibility[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.accessibility[3].description}`,
                      subValue: `${results.factorSummaries.market.accessibility[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.accessibility[4].description}`,
                      subValue: `${results.factorSummaries.market.accessibility[4].count}`,
                    }
                  ],
                },
                {
                  label: `Barriers for Competitors`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.market.barriersForCompetitors[0].description}`,
                      subValue: `${results.factorSummaries.market.barriersForCompetitors[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.barriersForCompetitors[1].description}`,
                      subValue: `${results.factorSummaries.market.barriersForCompetitors[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.barriersForCompetitors[2].description}`,
                      subValue: `${results.factorSummaries.market.barriersForCompetitors[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.barriersForCompetitors[3].description}`,
                      subValue: `${results.factorSummaries.market.barriersForCompetitors[3].count}`,
                    }
                  ],
                },
                {
                  label: `Secondary Market Size`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.market.nonCoreSize[0].description}`,
                      subValue: `${results.factorSummaries.market.nonCoreSize[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.nonCoreSize[1].description}`,
                      subValue: `${results.factorSummaries.market.nonCoreSize[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.nonCoreSize[2].description}`,
                      subValue: `${results.factorSummaries.market.nonCoreSize[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.nonCoreSize[3].description}`,
                      subValue: `${results.factorSummaries.market.nonCoreSize[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.nonCoreSize[4].description}`,
                      subValue: `${results.factorSummaries.market.nonCoreSize[4].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.nonCoreSize[5].description}`,
                      subValue: `${results.factorSummaries.market.nonCoreSize[5].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.nonCoreSize[6].description}`,
                      subValue: `${results.factorSummaries.market.nonCoreSize[6].count}`,
                    }
                  ],
                },
                {
                  label: `Secondary Market Accessibility`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.market.secondaryMarketAccessibility[0].description}`,
                      subValue: `${results.factorSummaries.market.secondaryMarketAccessibility[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.secondaryMarketAccessibility[1].description}`,
                      subValue: `${results.factorSummaries.market.secondaryMarketAccessibility[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.secondaryMarketAccessibility[2].description}`,
                      subValue: `${results.factorSummaries.market.secondaryMarketAccessibility[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.secondaryMarketAccessibility[3].description}`,
                      subValue: `${results.factorSummaries.market.secondaryMarketAccessibility[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.secondaryMarketAccessibility[4].description}`,
                      subValue: `${results.factorSummaries.market.secondaryMarketAccessibility[4].count}`,
                    }
                  ],
                },
                {
                  label: `Secondary Market Accessibility for Competitors`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.market.competitorsSecondaryMarketAccessibility[0].description}`,
                      subValue: `${results.factorSummaries.market.competitorsSecondaryMarketAccessibility[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.competitorsSecondaryMarketAccessibility[1].description}`,
                      subValue: `${results.factorSummaries.market.competitorsSecondaryMarketAccessibility[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.competitorsSecondaryMarketAccessibility[2].description}`,
                      subValue: `${results.factorSummaries.market.competitorsSecondaryMarketAccessibility[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.market.competitorsSecondaryMarketAccessibility[3].description}`,
                      subValue: `${results.factorSummaries.market.competitorsSecondaryMarketAccessibility[3].count}`,
                    }
                  ],
                }
              ], 
              false
            )}


            {/* Commercialization Factors */}
            {analysisElementAccordionJSX(
              'Commercialization Factors', 
              [
                {
                  label: `Scale Speed`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.commercialization.scaleSpeed[0].description}`,
                      subValue: `${results.factorSummaries.commercialization.scaleSpeed[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.commercialization.scaleSpeed[1].description}`,
                      subValue: `${results.factorSummaries.commercialization.scaleSpeed[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.commercialization.scaleSpeed[2].description}`,
                      subValue: `${results.factorSummaries.commercialization.scaleSpeed[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.commercialization.scaleSpeed[3].description}`,
                      subValue: `${results.factorSummaries.commercialization.scaleSpeed[3].count}`,
                    }
                  ],
                },
                {
                  label: `Relationships`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.commercialization.relationships[0].description}`,
                      subValue: `${results.factorSummaries.commercialization.relationships[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.commercialization.relationships[1].description}`,
                      subValue: `${results.factorSummaries.commercialization.relationships[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.commercialization.relationships[2].description}`,
                      subValue: `${results.factorSummaries.commercialization.relationships[2].count}`,
                    }
                  ],
                },
                {
                  label: `Product Development`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.commercialization.productDevelopment[0].description}`,
                      subValue: `${results.factorSummaries.commercialization.productDevelopment[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.commercialization.productDevelopment[1].description}`,
                      subValue: `${results.factorSummaries.commercialization.productDevelopment[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.commercialization.productDevelopment[2].description}`,
                      subValue: `${results.factorSummaries.commercialization.productDevelopment[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.commercialization.productDevelopment[3].description}`,
                      subValue: `${results.factorSummaries.commercialization.productDevelopment[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.commercialization.productDevelopment[4].description}`,
                      subValue: `${results.factorSummaries.commercialization.productDevelopment[4].count}`,
                    }
                  ],
                },
                {
                  label: `Price Point`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.commercialization.pricePoint[0].description}`,
                      subValue: `${results.factorSummaries.commercialization.pricePoint[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.commercialization.pricePoint[1].description}`,
                      subValue: `${results.factorSummaries.commercialization.pricePoint[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.commercialization.pricePoint[2].description}`,
                      subValue: `${results.factorSummaries.commercialization.pricePoint[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.commercialization.pricePoint[3].description}`,
                      subValue: `${results.factorSummaries.commercialization.pricePoint[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.commercialization.pricePoint[4].description}`,
                      subValue: `${results.factorSummaries.commercialization.pricePoint[4].count}`,
                    }
                  ],
                },
                {
                  label: `Branding`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.commercialization.branding[0].description}`,
                      subValue: `${results.factorSummaries.commercialization.branding[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.commercialization.branding[1].description}`,
                      subValue: `${results.factorSummaries.commercialization.branding[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.commercialization.branding[2].description}`,
                      subValue: `${results.factorSummaries.commercialization.branding[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.commercialization.branding[3].description}`,
                      subValue: `${results.factorSummaries.commercialization.branding[3].count}`,
                    }
                  ],
                }
              ], 
              false
            )}


            {/* Additional Risks Factors */}
            {analysisElementAccordionJSX(
              'Additional Risk Factors', 
              [
                {
                  label: `Policy Risk`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.policyRisk[0].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.policyRisk[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.policyRisk[1].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.policyRisk[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.policyRisk[2].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.policyRisk[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.policyRisk[3].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.policyRisk[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.policyRisk[4].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.policyRisk[4].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.policyRisk[5].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.policyRisk[5].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.policyRisk[6].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.policyRisk[6].count}`,
                    }
                  ],
                },
                {
                  label: `Engineering Risk`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.engineeringRisk[0].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.engineeringRisk[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.engineeringRisk[1].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.engineeringRisk[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.engineeringRisk[2].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.engineeringRisk[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.engineeringRisk[3].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.engineeringRisk[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.engineeringRisk[4].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.engineeringRisk[4].count}`,
                    }
                  ],
                },
                {
                  label: `Unknown Risk`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.unknownRisk[0].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.unknownRisk[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.unknownRisk[1].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.unknownRisk[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.unknownRisk[2].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.unknownRisk[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.unknownRisk[3].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.unknownRisk[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.unknownRisk[4].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.unknownRisk[4].count}`,
                    }
                  ],
                },
                {
                  label: `Other Risk`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.otherRisk[0].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.otherRisk[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.otherRisk[1].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.otherRisk[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.otherRisk[2].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.otherRisk[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.otherRisk[3].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.otherRisk[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.additionalRisks.otherRisk[4].description}`,
                      subValue: `${results.factorSummaries.additionalRisks.otherRisk[4].count}`,
                    }
                  ],
                }
              ], 
              false
            )}


            {/* Financial Factors */}
            {analysisElementAccordionJSX(
              'Financial Factors', 
              [
                {
                  label: `Equity Stake`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.initialInvestmentOwnershipPercentage[0].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.initialInvestmentOwnershipPercentage[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.initialInvestmentOwnershipPercentage[1].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.initialInvestmentOwnershipPercentage[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.initialInvestmentOwnershipPercentage[2].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.initialInvestmentOwnershipPercentage[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.initialInvestmentOwnershipPercentage[3].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.initialInvestmentOwnershipPercentage[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.initialInvestmentOwnershipPercentage[4].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.initialInvestmentOwnershipPercentage[4].count}`,
                    }
                  ],
                },
                {
                  label: `Projected Year 1 Revenue`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.projectedYear1Revenues[0].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.projectedYear1Revenues[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.projectedYear1Revenues[1].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.projectedYear1Revenues[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.projectedYear1Revenues[2].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.projectedYear1Revenues[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.projectedYear1Revenues[3].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.projectedYear1Revenues[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.projectedYear1Revenues[4].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.projectedYear1Revenues[4].count}`,
                    }
                  ],
                },
                {
                  label: `Projected Revenues at Plateau`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.projectedRevenuesPlateau[0].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.projectedRevenuesPlateau[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.projectedRevenuesPlateau[1].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.projectedRevenuesPlateau[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.projectedRevenuesPlateau[2].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.projectedRevenuesPlateau[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.projectedRevenuesPlateau[3].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.projectedRevenuesPlateau[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.projectedRevenuesPlateau[4].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.projectedRevenuesPlateau[4].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.projectedRevenuesPlateau[5].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.projectedRevenuesPlateau[5].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.projectedRevenuesPlateau[6].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.projectedRevenuesPlateau[6].count}`,
                    }
                  ],
                },
                {
                  label: `Profit Margin at Plateau`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.profitMarginAtPlateau[0].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.profitMarginAtPlateau[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.profitMarginAtPlateau[1].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.profitMarginAtPlateau[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.profitMarginAtPlateau[2].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.profitMarginAtPlateau[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.profitMarginAtPlateau[3].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.profitMarginAtPlateau[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.profitMarginAtPlateau[4].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.profitMarginAtPlateau[4].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.profitMarginAtPlateau[5].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.profitMarginAtPlateau[5].count}`,
                    }
                  ],
                },
                {
                  label: `Anticipated Additional Investment`,
                  value: [
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.anticipatedAdditionalInvestment[0].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.anticipatedAdditionalInvestment[0].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.anticipatedAdditionalInvestment[1].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.anticipatedAdditionalInvestment[1].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.anticipatedAdditionalInvestment[2].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.anticipatedAdditionalInvestment[2].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.anticipatedAdditionalInvestment[3].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.anticipatedAdditionalInvestment[3].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.anticipatedAdditionalInvestment[4].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.anticipatedAdditionalInvestment[4].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.anticipatedAdditionalInvestment[5].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.anticipatedAdditionalInvestment[5].count}`,
                    },
                    {
                      subLabel: `${results.factorSummaries.financialExpectations.anticipatedAdditionalInvestment[6].description}`,
                      subValue: `${results.factorSummaries.financialExpectations.anticipatedAdditionalInvestment[6].count}`,
                    }
                  ],
                }
              ], 
              false
            )}


          </Box>
        </Box>
      }
    </TabPanel>
  )
}