/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import ReplayIcon from '@mui/icons-material/Replay'
import RefreshIcon from '@mui/icons-material/Refresh'

import FilterSimulations from '../FilterSimulations'
import SimulationInstance from '../SimulationInstance'

import useWindowDimensions from '../../../helpers/windowDimensions'
import { standardButton, exitModalIconButton } from '../../../helpers/buttons'
import { standardSpinner } from '../../../helpers/spinners'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated } from '../../../helpers/storage'
import { simulationsSortByArray, positionChangeWidthSm, positionChangeWidthMd, simcapPink, simcapTurquoise, navbarHeight, footerHeight, dashboardNavbarHeight } from '../../../helpers/variableDefaults'
import { selectSortCategory } from '../../../helpers/selects'
import { couldNotLoadPageError } from '../../../helpers/errors'
import { switchWithTitle } from '../../../helpers/switches'
import { modifyInDatabase } from '../../../helpers/modifyInDatabase'
import { newSimulationModal, incompleteVCSimInfoModal } from '../../../helpers/modals'
import { seoPageTags, customAnalyticsEvent } from '../../../helpers/analytics'
import { searchTextField } from '../../../helpers/textfields'
import { standardPagination } from '../../../helpers/pagination'
import { reloadView, numberWithCommas } from '../../../helpers/globalHelpers'

// Dashboard Page
const Simulations = () => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()
  
  // Navigate
  const navigate = useNavigate()

  // Params
  const { userId } = useParams()

  // Payload
  const payload = getPayload()

  // User
  const [user, setUser] = useState({})

  // Sorting
  const [simulationsSortBy, setSimulationsSortBy] = useState('Date ⬇') // How the items are sorted
  const [filteredSimulations, setFilteredSimulations] = useState([]) // Simulations after filters are added
  const [filtersObj, setFiltersObj] = useState({
    searchText: '',
    minDate: 'All',
    simulationVersions: [],
    statusOfSimulation: 'All Simulations',
    minDecisionQualityPercentile: 0,
    maxDecisionQualityPercentile: 100,
  })
  const [showFilters, setShowFilters] = useState(false) // If true, the FilterItemsIndex Modal pops up

  // Pagination
  const [activePage, setActivePage] = useState(1)
  const itemsPerPage = 20
  const pageRangeDisplayed = 3

  // New Simulation
  const [newSimulationObj, setNewSimulationObj] = useState({
    recipients: [
      {
        nameOfTaker: '',
        emailOfTaker: '',
      }
    ],
    simulationVersion: '',
  })
  const [openNewSimulationModal, setOpenNewSimulationModal] = useState(false)
  const [simulationCreated, setSimulationCreated] = useState(false)

  // Incomplete Test Info
  const [infoObj, setInfoObj] = useState({})
  const [openTestInfo, setOpenTestInfo] = useState(false)
  const [linkResent, setLinkResent] = useState(false)

  // Loading
  const [loading, setLoading] = useState(false)

  // Errors
  const [errors, setErrors] = useState(false)
  const [newSimulationErrors, setNewSimulationErrors] = useState(false)
  const [resendLinkErrors, setResendLinkErrors] = useState(false)

  // UseEffect — Send to user profile view if User is not a Person in the estate
  useEffect(() => {

    if (!userIsAuthenticated()) {
      navigate('/login')
    } else if (userId !== payload.sub) {
      navigate(`/dashboard/${payload.sub}`)
      // reloadView()
    } else {
      const getData = async () => {
        setLoading(true)
        try {
          const { data: retrievedUser } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/users/${payload.sub}`, {
            headers: {
              Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            },
          })
          // console.log('retrieved User ->', retrievedUser)

          if (payload.sub === retrievedUser._id) {
            // Set States
            setUser(retrievedUser)
            setFilteredSimulations(retrievedUser.hasOwnProperty('vcSimulations') && retrievedUser.vcSimulations.length > 0 ? retrievedUser.vcSimulations : [])
            
            setErrors(false)
            setLoading(false)
          } else {
            navigate(`/`)
            setErrors(false)
            setLoading(false)
          }

        } catch (error) {
          // console.log(error)

          navigate(`/`)

          setErrors(true)
          setLoading(false)
        }
      }

      getData()
    }

  }, [])

  useEffect(() => {
    // console.log('useEffect Runs')
    // console.log('filtersObj ->', filtersObj)
    // console.log('openNewSimulationModal ->', openNewSimulationModal)
    // console.log('filteredSimulations ->', filteredSimulations)

  }, [showFilters, filtersObj, simulationCreated, openNewSimulationModal, openTestInfo, linkResent, resendLinkErrors])

  useEffect(() => {
    // Scroll to top of page when pagination changes
    window.scrollTo(0, 0)

  }, [activePage])

  useEffect(() => {
    // console.log('simulationsSortBy useEffect ->', simulationsSortBy)
    if (
      user 
      && user.hasOwnProperty('vcSimulations') 
      && filteredSimulations
      && user.vcSimulations.length !== filteredSimulations.length 
      && simulationsSortBy !== 'Percentile ⬇' 
      && simulationsSortBy !== 'Percentile ⬆'
    ) {
      // console.log('this runs 2')
      handleResetPressed()
    }
  }, [simulationsSortBy])

  // If true, then 'Reset' button will show
  const filtersInUse = () => {
    return !(
      filtersObj.searchText === '' 
      && filtersObj.minDate === 'All' 
      && filtersObj.simulationVersions.length === 0 
      && filtersObj.statusOfSimulation === 'All Simulations' 
      && filtersObj.minDecisionQualityPercentile === 0 
      && filtersObj.maxDecisionQualityPercentile === 100 
    )
  }

  // New Simulation Pressed button handler
  const handleNewSimulationPressed = () => {
    // console.log('handleNewSimulationPressed runs')
    setOpenNewSimulationModal(true)
  }

  // Filter Icon Pressed button handler
  const handleFilterIconPressed = () => {
    // console.log('handleFilterIconPressed')

    setShowFilters(true)
  }

  // Reset Pressed button handler
  const handleResetPressed = () => {
    // console.log('handleResetPressed')

    setFilteredSimulations(user.vcSimulations.length > 0 ? user.vcSimulations : [])
    if (
      simulationsSortBy === 'Percentile ⬇' 
      && simulationsSortBy === 'Percentile ⬆'
    ) {
      // console.log('this runs 3')
      setSimulationsSortBy('Date ⬇')
    }
    setActivePage(1)
    setFiltersObj({
      searchText: '',
      minDate: 'All',
      simulationVersions: [],
      statusOfSimulation: 'All Simulations',
      minDecisionQualityPercentile: 0,
      maxDecisionQualityPercentile: 100,
    })
  }

  const handleSearchText = (e) => {
    // console.log('handleSearchText runs')
    // console.log('e.target.value ->', e.target.value)
    const value = e.target.value.toLowerCase()
    
    setActivePage(1)

    setFilteredSimulations(user.vcSimulations.filter(simulation => simulation.nameOfTaker.toLowerCase().includes(value) || simulation.emailOfTaker.toLowerCase().includes(value)))
    setFiltersObj({ ...filtersObj, searchText: e.target.value })
  }

  const headersJSX = () => {
    return (
      <Box 
        sx={{ 
          // backgroundColor: 'whitesmoke', 
          // mt: 1, mb: 2, 
          // boxShadow: 3, borderRadius: '5px', 
          width: '100%', 
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', 
        }}
      >
        <Box textAlign={'center'} sx={{ mt: 1, mb: 1, width: '30%', fontWeight: 'bold', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {user.hasOwnProperty('entityType') && user.entityType === 'Individual' ? 'Test' : 'Name'}
        </Box>

        {/* Type or Test */}
        <Box sx={{ mt: 1, mb: 1, width: '20%', fontWeight: 'bold', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {/* {user.hasOwnProperty('entityType') && user.entityType === 'Individual' ? 'Type' : 'Date'} */}
          {user.hasOwnProperty('entityType') && user.entityType === 'Individual' ? 'Type' : 'Test'}
        </Box>



        {/* Decision Quality Percentile */}
        <Box textAlign={'center'} sx={{ mt: 1, mb: 1, width: '20%', fontWeight: 'bold', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          Percentile
        </Box>

        {/* Results */}
        <Box
          sx={{
            width: '65px',
          }}
        >

        </Box>
      </Box>
    )
  }

  const simulationInActivePage = (simulationIndexPlusOne) => {
    return (activePage * itemsPerPage >= simulationIndexPlusOne) 
      && (simulationIndexPlusOne >= (((activePage - 1) * itemsPerPage) + 1))
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'My Simulations'
      )}

      {/* Body */}
      {loading ?
        <Box sx={{ 
          width: '100%',
          // minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight} - ${dashboardNavbarHeight})`,
          minHeight: `calc(100vh - ${navbarHeight} - ${dashboardNavbarHeight})`,
          mt: 0, mb: 0,
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
        }}>
          {standardSpinner()}
        </Box>
        :
        errors ? 
          <Box
            sx={{
              // backgroundColor: 'yellow',
              width: '100%',
              minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight} - ${dashboardNavbarHeight})`,
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
            }}
          >
            {couldNotLoadPageError()}
          </Box>
          :
          <Box
            sx={{
              // backgroundColor: 'green',
              pt: 3, pb: 4,
              width: '100%',
              minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight} - ${dashboardNavbarHeight})`,
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
            }}
          > 
            {/* Show Filters Modal */}
            {showFilters ?
              <FilterSimulations fromTab={'dashboard'} user={user} filteredSimulations={filteredSimulations} setFilteredSimulations={setFilteredSimulations} filtersObj={filtersObj} setFiltersObj={setFiltersObj} showFilters={showFilters} setShowFilters={setShowFilters} setActivePage={setActivePage}  />
              :
              <>
                {/* Dashboard Title */}
                {user.hasOwnProperty('entityType') 
                // && user.entityType !== 'Individual' 
                && 
                  <Box
                    sx={{
                      mb: 2,
                      width: '100%',
                      display: 'flex', flexDirection: width < positionChangeWidthSm ? 'column' : 'column', justifyContent: 'center', alignItems: 'center',
                    }}
                  >
                    {/* <Typography
                      sx={{
                        // backgroundColor: 'yellow',
                        mb: width < positionChangeWidthSm ? 0 : user.entityType === 'Individual' ? -1 : -2,
                        fontSize: '24px',
                        fontWeight: 'bold',
                      }}
                    >
                      Index of Simulations
                    </Typography> */}
                    
                    {user.hasOwnProperty('entityType') && user.entityType !== 'Individual' && 
                      standardButton(
                        'New Simulation', 
                        'button',
                        'text',
                        !user.subscriptionIsActive,
                        'secondary', 
                        width >= positionChangeWidthSm ? 1 : 1,
                        0,
                        0,
                        '150px', 
                        '45px', 
                        handleNewSimulationPressed
                      )
                    }
                  </Box>
                }

                {/* Simulations */}
                {!user.hasOwnProperty('vcSimulations') || user.vcSimulations.length === 0 ?
                  <Typography 
                    sx={{ 
                      width: '95%', maxWidth: '600px',
                      color: 'black', mt: 3, fontWeight: 'bold', 
                    }}
                  >
                    No simulations have been created yet. Click the &apos;New Simulation&apos; button above to get started.
                  </Typography>
                  :
                  <Box sx={{ mt: 0, mb: 2, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                    
                    {user.hasOwnProperty('subscriptionIsActive') && user.subscriptionIsActive && user.hasOwnProperty('entityType') && user.entityType !== 'Individual' && 
                      <Box
                        sx={{
                          mb: width < positionChangeWidthSm ? 1 : 1,
                          width: width < positionChangeWidthSm ? '80%' : '98%',
                          // maxWidth: '300px',
                          display: 'flex', flexDirection: 'row', justifyContent: width < positionChangeWidthSm ? 'space-between' : 'space-between', alignItems: 'center',
                        }}
                      >
                        {/* Sort Select */}
                        {selectSortCategory(
                          'sortSimulations', 
                          'Sort by:', 
                          false, 
                          simulationsSortBy, 
                          simulationsSortByArray, 
                          setSimulationsSortBy, 
                          filtersObj, 
                          setFiltersObj, 
                          filteredSimulations, 
                          setFilteredSimulations,
                          activePage,
                          setActivePage
                        )}

                        {/* Search Bar */}
                        {width > positionChangeWidthSm &&
                          <Box
                            sx={{
                              mt: 0, mb: 0,
                              width: 'calc(100% - 150px)',
                              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                            }}
                          >
                            {/* Search Textfield */}
                            <Box
                              sx={{
                                width: '80%',
                                maxWidth: '350px',
                                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                              }}
                            >
                              {searchTextField(
                                'searchText',
                                filtersObj.searchText,
                                'Search name or email',
                                'outlined',
                                filtersObj,
                                setFiltersObj,
                                handleSearchText,
                                'small',
                                width
                              )}
                            </Box>
                            

                            {/* Reset Search Button */}
                            {/* {filtersObj.searchText.length > 0 && 
                              exitModalIconButton(
                                handleResetPressed,
                                filtersObj.searchText.length === 0,
                                1
                              )
                            } */}

                          </Box>
                        }

                        <Box
                          sx={{
                            display: 'flex', flexDirection: 'row', justifyContent: width < positionChangeWidthSm ? 'center' : 'flex-end', alignItems: 'center',
                          }}
                        >
                          {/* Filter Button */}
                          <IconButton
                            onClick={handleFilterIconPressed} 
                            disabled={!user.subscriptionIsActive}
                            sx={{ 
                              width: '30px', height: '30px', 
                              mb: 1, mt: 1, mr: width < positionChangeWidthSm ? 1 : 1, ml: 1,bottom: 0, left: 0, 
                              border: 2, borderColor: 'white', boxShadow: 3, 
                            }} 
                          >
                            <FilterAltIcon 
                              fontSize="small" 
                              color="primary"
                              sx={{ 

                              }} 
                            />
                          </IconButton>

                          {/* Reset Button */}
                          {filtersInUse() &&
                            standardButton(
                              'Reset', 
                              'button',
                              'text',
                              false,
                              'primary', 
                              0,
                              0,
                              width < positionChangeWidthSm ? -1 : 0,
                              '55px', 
                              '30px', 
                              handleResetPressed
                            )
                          }

                        </Box>
                        
                      </Box>
                    }

                    {/* Number of Simulations that meet criteria */}
                    {user.hasOwnProperty('subscriptionIsActive') && user.subscriptionIsActive && user.hasOwnProperty('entityType') && user.entityType !== 'Individual' && 
                      <Box 
                        sx={{
                          // backgroundColor: 'yellow',
                          width: width < positionChangeWidthSm ? '80%' : '98%', maxWidth: '600px',
                          mb: 1.2, mr: width < positionChangeWidthSm ? 2 : 4, ml: 1, mt: -1, pr: 0,
                          fontSize: '12px',
                          fontStyle: 'italic',
                          display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',
                        }}
                      >
                        {`${numberWithCommas(filteredSimulations.length)} ${filteredSimulations.length === 1 ? 'item' : 'items'}`}
                      </Box>
                    }

                    {/* Headers */}
                    {user.hasOwnProperty('vcSimulations') && filteredSimulations.length > 0 && width >= positionChangeWidthSm &&
                      headersJSX()
                    }

                    {/* The Simulations that meet the criteria are sorted and mapped */}
                    {user.hasOwnProperty('vcSimulations') && filteredSimulations.length > 0 && 
                      filteredSimulations.sort((a, b) => user.hasOwnProperty('entityType') && user.entityType === 'Individual' ? a.standardizedSimulationNumber - b.standardizedSimulationNumber : simulationsSortBy === 'Date ⬇' ? Date.parse(b.createdAt) - Date.parse(a.createdAt) : simulationsSortBy === 'Date ⬆' ? Date.parse(a.createdAt) - Date.parse(b.createdAt) : (simulationsSortBy === 'Percentile ⬇' && filteredSimulations.filter(simulation => simulation.results === null).length === 0) ? b.results.importantNumbers.decisionQualityPercentile - a.results.importantNumbers.decisionQualityPercentile : (simulationsSortBy === 'Percentile ⬆' && filteredSimulations.filter(simulation => simulation.results === null).length === 0) ? a.results.importantNumbers.decisionQualityPercentile - b.results.importantNumbers.decisionQualityPercentile : simulationsSortBy === 'A ➡ Z' ? a.nameOfTaker.localeCompare(b.nameOfTaker) : b.nameOfTaker.localeCompare(a.nameOfTaker)).map((simulation, simulationIndex) => (
                        
                        <Box 
                          key={simulationIndex}
                          sx={{
                            width: '100%',
                            mb: 0,
                            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                          }}
                        >
                          {/* Simulations */}
                          {
                            simulationInActivePage(simulationIndex + 1) &&
                            <SimulationInstance simulation={simulation} simulationIndex={simulationIndex} user={user} fromComponent={'dashboard'} setOpenTestInfo={setOpenTestInfo} setInfoObj={setInfoObj} />
                          }
                        </Box>
                      ))
                    }

                    {/* Message if no simulations meet search criteria */}
                    {filtersInUse() && filteredSimulations.length === 0 &&
                      <Typography 
                        sx={{ 
                          color: 'black', mt: 4, fontWeight: 'bold', 
                        }}
                      >
                        No simulations fit your search criteria
                      </Typography>
                    }

                    {/* Message if no simulations */}
                    {!filtersInUse() && filteredSimulations.length === 0 &&
                      <Typography 
                        sx={{ 
                          color: 'black', mt: 4, fontWeight: 'bold', 
                        }}
                      >
                        {`No simulations have been created. To do so press the 'New Simulation' button above.`}
                      </Typography>
                    }

                    {/* Pagination */}
                    {filteredSimulations.length > itemsPerPage &&
                      standardPagination(activePage, setActivePage, itemsPerPage, pageRangeDisplayed, filteredSimulations.length, user.subscriptionIsActive, width)
                    }

                  </Box>
                } 

                {/* New Simulation Modal */} 
                {openNewSimulationModal &&
                  newSimulationModal(
                    newSimulationObj,
                    setNewSimulationObj,
                    openNewSimulationModal,
                    setOpenNewSimulationModal,
                    simulationCreated,
                    setSimulationCreated,
                    newSimulationErrors,
                    setNewSimulationErrors,
                    user,
                    setUser,
                    setFilteredSimulations,
                    width
                  )
                }

                {/* Incomplete Test Info Modal */}
                {openTestInfo && 
                  incompleteVCSimInfoModal(
                    user, 
                    infoObj, 
                    setInfoObj, 
                    openTestInfo, 
                    setOpenTestInfo, 
                    linkResent, 
                    setLinkResent, 
                    resendLinkErrors, 
                    setResendLinkErrors, 
                    width
                  )
                }
              </>
            }
          </Box>
      }
    </>
  )
}

export default Simulations