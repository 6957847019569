// React
import React from 'react'

// Import spinner image
import blackDottedSpinnerGIF from '../assets/dotted-black-trans-spinner-200.gif'
import turquoiseDottedSpinnerGIF from '../assets/dotted-turquoise-trans-spinner-200.gif'
import whiteDottedSpinnerGIF from '../assets/dotted-white-trans-spinner-200.gif'

// mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

// Standard spinner using logo GIF
export const standardSpinner = () => {
  // console.log('standardSpinner runs')
  return (
    <Box
      sx={{ 
        width: '100%', height: '100%', maxWidth: '250px',
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
      }}
    >

      {/* GIF of present wrapping */}
      <Box component='img' src={blackDottedSpinnerGIF} alt="Spinner" className="spinner" sx={{ maxWidth: 250 }} />

    </Box>  
  )
}

// Calculating spinner using logo GIF
export const calculatingStandardSpinner = (preparingResults = false, hasResults = false) => {
  // console.log('calculatingStandardSpinner runs')
  // console.log('preparing results ->', preparingResults)
  return (
    <Box
      sx={{ 
        width: '100%', height: '100%', maxWidth: '250px',
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
      }}
    >

      {/* GIF of present wrapping */}
      <Box
        sx={{
          width: '100%', maxWidth: '250px',
          height: '120px', minHeight: '120px',
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
      >
        <Box component='img' src={blackDottedSpinnerGIF} alt="Spinner" className="spinner" 
          sx={{ 
            maxWidth: 250,
          }} 
        />
      </Box>
      

      {/* Calculating Text */}
      {hasResults === false &&
        <Box
          sx={{
            mt: 0,
            width: '100%',
            fontSize: '18px',
            fontFamily: 'Raleway',
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              width: '100%',
              fontSize: '18px',
              fontFamily: 'Raleway',
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            }}
          >
            {preparingResults ? `Preparing Results` : `Calculating`}
          </Typography>
          
          {preparingResults &&
            <Typography
              sx={{
                width: '100%',
                fontSize: '16px',
                fontFamily: 'Raleway',
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
              }}
            >
              {`(It might take a minute)`}
            </Typography>
          }
          
        </Box>
      }

    </Box>  
  )
}

// Circular Progress Spinner
export const circularSpinner = () => {
  return (
    <Box
      sx={{ 
        width: '100%', height: '100%',
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
      }}
    >

      {/* circular spinner animation */}
      <CircularProgress color="primary" />

    </Box>  
  )
}