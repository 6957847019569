

export const getCompanyOfInvestmentSelection = (selectionId, vcSimulation) => {
  return vcSimulation.standardizedSimulation.companies[vcSimulation.standardizedSimulation.companies.map(company => company.id).indexOf(selectionId)]
}

export const getOutcomesOfInvestmentSelection = (selectionId, vcSimulation) => {
  // console.log('outcomes ->', vcSimulation.standardizedSimulation)
  return vcSimulation.standardizedSimulation.outcomes[vcSimulation.standardizedSimulation.outcomes.map(outcome => outcome.id).indexOf(selectionId)]
}

export const getIdForCurrentFollowOnIndexCompany = (vcSimulation) => {
  return vcSimulation.investmentSelections[vcSimulation.currentFollowOnIndex].id
}