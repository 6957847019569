/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

import blackDottedSpinnerGIF from '../../assets/dotted-black-trans-spinner-200.gif'
import turquoiseDottedSpinnerGIF from '../../assets/dotted-turquoise-trans-spinner-200.gif'
import whiteDottedSpinnerGIF from '../../assets/dotted-white-trans-spinner-200.gif'

// mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import Slider from '@mui/material/Slider'

import { userIsAuthenticated, getPayload } from '../../helpers/storage.js'
import { standardErrorContainer } from '../../helpers/errors'

import { dummyBox, validEmail, reloadView } from '../../helpers/globalHelpers'
import { standardButton } from '../../helpers/buttons'
import { standardSpinner } from '../../helpers/spinners'
import { handleChange } from '../../helpers/globalHelpers'
import { selectMultiple, selectElementHandleChange } from '../../helpers/selects.js'
import { dateRangesArray, simulationVersionsBusinessesArray, simulationStatuses, positionChangeWidthSm } from '../../helpers/variableDefaults.js'

import useWindowDimensions from '../../helpers/windowDimensions'

// Filter Simulations Page
const FilterSimulations = (props) => {

  // Destructure Props
  const { fromTab, user, filteredSimulations, setFilteredSimulations, filtersObj, setFiltersObj, showFilters, setShowFilters, setActivePage } = props

  // Dynamic Window Dimensions
  const { height, width } = useWindowDimensions()

  // Navigate
  const navigate = useNavigate()
  const urlPath = window.location.pathname

  // Payload
  const payload = getPayload()

  // Loading and Errors
  const [loading, setLoading] = useState(false)
  const [filtersErrors, setFiltersErrors] = useState(false)

  // When 'X' button pressed, filters Modal is exited
  const handleExit = () => {
    setShowFilters(false)
  }

  // Saves filter criteria and sets a new array of filtered simulations
  const handleSavePressed = () => {
    const today = new Date()
    const daysAgo30 = Date.parse(new Date(new Date().setDate(today.getDate() - 30)))
    const monthsAgo6 = Date.parse(new Date(new Date().setMonth(today.getMonth() - 6)))
    const yearsAgo1 = Date.parse(new Date(new Date().setFullYear(today.getFullYear() - 1)))

    const standardizedSimulationNumbers = []
    if (filtersObj.simulationVersions.length > 0){
      for (let i = 0; i < filtersObj.simulationVersions.length; i++) {
        const testNumber = parseInt(filtersObj.simulationVersions[i].split(' ')[1])
        // console.log('testNumber ->', testNumber)
        const standardizedSimulationNumber = user.entityType === 'Business' ? testNumber : user.entityType === 'Individual' ? testNumber + 10 : testNumber + 20
        // console.log('standardizedSimulationNumber ->', standardizedSimulationNumber)
        standardizedSimulationNumbers.push(standardizedSimulationNumber)
      }
    }

    const filteredSims = user.vcSimulations.filter(simulation => {
      // console.log('simulation ->', simulation)
      // console.log('min decision quality ->', filtersObj.minDecisionQualityPercentile)
      // console.log('min decision quality ->', parseFloat(simulation.results.importantNumbers.decisionQualityPercentile))
      // console.log('min decision quality <= sim decision quality ->', filtersObj.minDecisionQualityPercentile <= parseFloat(simulation.results.importantNumbers.decisionQualityPercentile))
      // console.log('results keys length ->', Object.keys(simulation.results).length > 0)
      // console.log('has results property ->', simulation.hasOwnProperty('results'))
      // console.log('test is finished ->', simulation.testIsFinished)
      return (
        (filtersObj.minDate === 'All' ? simulation : filtersObj.minDate === 'Last 30 Days' ? Date.parse(simulation.createdAt) > daysAgo30 : filtersObj.minDate === 'Last 6 Months' ? Date.parse(simulation.createdAt) > monthsAgo6 : Date.parse(simulation.createdAt) > yearsAgo1)
        && (filtersObj.simulationVersions.length === 0 ? simulation : standardizedSimulationNumbers.includes(simulation.standardizedSimulationNumber))
        && (filtersObj.statusOfSimulation === 'All Simulations' ? simulation : filtersObj.statusOfSimulation === 'Incomplete Simulations' ? simulation.testIsFinished === false : simulation.testIsFinished === true && simulation.hasOwnProperty('results') && simulation.results !== null)
        && (filtersObj.minDecisionQualityPercentile === 0 || ((simulation.testIsFinished === true && simulation.hasOwnProperty('results') && simulation.results !== null) && filtersObj.minDecisionQualityPercentile <= parseFloat(simulation.results.importantNumbers.decisionQualityPercentile)))
        && (filtersObj.maxDecisionQualityPercentile === 100 || ((simulation.testIsFinished === true && simulation.hasOwnProperty('results') && simulation.results !== null) && filtersObj.maxDecisionQualityPercentile >= parseFloat(simulation.results.importantNumbers.decisionQualityPercentile)))
      )
    })
    // console.log('filteredSims ->', filteredSims)
    setFilteredSimulations(filteredSims)
    setActivePage(1)
    setShowFilters(false)
  }

  // Handles sliding on the min/max valuation sliders
  const handlePercentileChange = (e, setErrors, setFormData, formData) => {
    // console.log('handleMinPercentileChange runs')
    
    const minValue = e.target.value[0]
    const maxValue = e.target.value[1]

    let minName = ''
    let maxName = ''

    if (e.target.name === 'DecisionQuality') {
      minName = 'minDecisionQualityPercentile'
      maxName = 'maxDecisionQualityPercentile'
    } else {
      return
    }
    
    setErrors(false)
    setFormData({ ...formData, [minName]: minValue, [maxName]: maxValue })
  }

  return (
    <>

      {/* Show Filters Body */}
      <Box sx={{ 
        width: '100vw', height: '100vh',
        display: 'flex', flexDirection: 'row', justifyContent: 'stretch', alignItems: 'stretch',
      }}>
        <Modal
          disableAutoFocus={true}
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={showFilters ? true : false}
          closeAfterTransition
          keepMounted
          sx={{ 
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
          }}
        >
          <Paper
            sx={{
              width: '85vw',
              height: '85vh',
              maxHeight: '85vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'stretch',
            }}
          >
            {
              loading ?
                standardSpinner()
                :
                <>
                  {/* Main Elements */}
                  <Box 
                    id='scroller' 
                    sx={{
                      pb: 2,
                      height: '100%',
                      maxHeight: '100%',
                      // overflow: 'auto',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        WebkitAppearance: 'none',
                        width: '8px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        borderRadius: '4px',
                        backgroundColor: 'rgba(0, 0, 0, .35)',
                        webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
                      },
                      width: '100%',
                      mb: 0, mt: 0, 
                      display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                    }}
                  >
                    
                    {/*'X' button */}
                    <Box 
                      onClick={handleExit} 
                      sx={{
                        width: '100%',
                        display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center',
                      }}
                    >
                      <IconButton 
                        sx={{ 
                          width: '30px', height: '30px', 
                          mt: 2, mr: 2, 
                          border: 2, borderColor: 'white', boxShadow: 3, 
                        }} 
                      >
                        <CloseOutlinedIcon 
                          fontSize="small" 
                          color='primary' 
                          sx={{  }} 
                        />
                      </IconButton>
                    </Box>

                    {/* Title */}
                    <Typography
                      sx={{
                        mb: width < positionChangeWidthSm ? 0 : 0,
                        fontSize: '24px',
                        fontWeight: 'bold',
                      }}
                    >
                      Filters
                    </Typography>

                    {/* Minimum Date */}
                    <Box
                      sx={{
                        mt: width < positionChangeWidthSm ? 3 : 4,
                        width: '80%', maxWidth: '600px',
                        display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                      }}
                    >
                      {selectElementHandleChange('minDate', 'Date Range', filtersObj.minDate, false, true, 'lg', dateRangesArray, filtersObj, setFiltersObj, setFiltersErrors)}
                    </Box>

                    {/* Status of Simulation*/}
                    <Box
                      sx={{
                        mt: width < positionChangeWidthSm ? 3 : 4,
                        width: '80%', maxWidth: '600px',
                        display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                      }}
                    >
                      {selectElementHandleChange('statusOfSimulation', 'Simulation Status', filtersObj.statusOfSimulation, false, true, 'lg', simulationStatuses, filtersObj, setFiltersObj, setFiltersErrors)}
                    </Box>
                    
                    {/* Simulation Versions */}
                    <Box
                      sx={{
                        mt: width < positionChangeWidthSm ? 3 : 4,
                        width: '80%', maxWidth: '600px',
                        display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                      }}
                    >
                      {selectMultiple('simulationVersions', 'Simulation Versions', false, filtersObj.simulationVersions, simulationVersionsBusinessesArray, handleChange, filtersObj, setFiltersObj, setFiltersErrors)}
                    </Box>


                    {/* Minimum Decision Quality Percentile Slider */}
                    <Typography sx={{ pl: 0, mt: 5, mb: 0, fontSize: '16px', fontWeight: 'bold' }}>
                      Decision Quality Percentile
                    </Typography>
                    <Box sx={{ mt: width < positionChangeWidthSm ? 0 : 0, mb: 0, width: '80%', maxWidth: '600px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                      <Slider
                        value={[filtersObj.minDecisionQualityPercentile, filtersObj.maxDecisionQualityPercentile]}
                        onChange={(e) => handlePercentileChange(e, setFiltersErrors, setFiltersObj, filtersObj)}
                        // valueLabelDisplay="auto"
                        name='DecisionQuality'
                        size="medium"
                        min={0}
                        max={100}
                        // marks
                        step={1}
                        sx={{ width: '100%', align: 'center' }}
                      />
                    </Box>
                    <Typography id="height-slider" gutterBottom sx={{ pl: 0, mt: 0 }}>
                      {filtersObj.minDecisionQualityPercentile.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}{filtersObj.minDecisionQualityPercentile === 0 ? '' : 'th'} - {filtersObj.maxDecisionQualityPercentile.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}{filtersObj.maxDecisionQualityPercentile === 0 ? '' : 'th'}
                    </Typography>


                    {/* Save Button */}
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      {standardButton(
                        'Save', 
                        'button',
                        'contained',
                        (
                          filtersObj.minDate === 'All' 
                          && filtersObj.simulationVersions.length === 0 
                          && filtersObj.statusOfSimulation === 'All Simulations' 
                          && filtersObj.minDecisionQualityPercentile === 0 
                          && filtersObj.maxDecisionQualityPercentile === 100 
                          && filteredSimulations.length === user.vcSimulations.length
                        ),
                        'primary', 
                        6,
                        2,
                        2,
                        '150px', 
                        '45px', 
                        handleSavePressed
                      )}
                    </Box>

                  </Box>
                </>
            }
          </Paper>
        </Modal>
      </Box>
    </>
  )
}

export default FilterSimulations