/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

// MUI
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import GroupIcon from '@mui/icons-material/Group'
import BarChartIcon from '@mui/icons-material/BarChart'
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline'
import PieChartIcon from '@mui/icons-material/PieChart'
import CategoryIcon from '@mui/icons-material/Category'
import AssignmentIcon from '@mui/icons-material/Assignment'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import PersonIcon from '@mui/icons-material/Person'

// Helper methods
import { dashboardNavbarHeight, positionChangeWidthSm, simcapTurquoise, simcapPink } from '../../helpers/variableDefaults'

import useWindowDimensions from '../../helpers/windowDimensions'

// The navbar appears at the top of the website on all pages except for unwrap views
const NavDashboard  = (props) => {

  const { userId, viewIndex, userIsIndividual, setViewIndex, setLoading } = props

  // Navigate
  const navigate = useNavigate()

  // Payload
  // const payload = getPayload()

  // URL Path
  const urlPath = window.location.pathname

  // Dynamic Window Dimensions
  const { height, width } = useWindowDimensions()

  // Params
  // const { userId } = useParams()

  // States

  const handleNavSimulations = async (event) => {
    // setLoading(true)
    setViewIndex(0)
    // setLoading(false)

  }

  const handleNavCustomize = async (event) => {
    // setLoading(true)
    setViewIndex(1)
    // setLoading(false)
  }

  const handleNavMyAccount = async (event) => {
    // setLoading(true)
    setViewIndex(!userIsIndividual ? 2 : 1)
    // setLoading(false)
  }

  return (
    <>
      <AppBar position="static" component="nav"
        style={{ 
          background: 'lightgray',
          boxShadow: 'none', 
        }}
      >
        <Toolbar 
          sx={{ 
            width: '100%',
            height: dashboardNavbarHeight,
            fontFamily: 'Lato',
            display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: !userIsIndividual ? 'space-around' : 'center', 
          }}
        >

          {/* For Businesses and Schools — Contains a 'Customize' Section */}
          {!userIsIndividual && ['Simulations', 'Customize', 'My Account'].map((text, index) => (
            <Box key={text} sx={{ }}>
              
              {width < positionChangeWidthSm ?
                // Icon Button if smaller screen width
                <Box>
                  <IconButton
                    size="large"
                    aria-label="inside account navigation"
                    aria-haspopup="true"
                    onClick={index === 0 ? handleNavSimulations : index === 1 ? handleNavCustomize : handleNavMyAccount}
                    color="inherit"
                  >
                    {index === 0 ? <BarChartIcon sx={{ color: viewIndex === 0 ? simcapPink : 'black' }}/> : index === 1 ? <CategoryIcon sx={{ color: viewIndex === 1 ? simcapPink : 'black' }}/> : <PersonIcon sx={{ color: viewIndex === 2 ? simcapPink : 'black' }}/> }
                  </IconButton>
                </Box>
                :
                // Words if larger screen width
                <Box
                  onClick={index === 0 ? handleNavSimulations : index === 1 ? handleNavCustomize : handleNavMyAccount}
                  sx={{
                    cursor: 'pointer',
                    color: (index === 0 && viewIndex === 0) || (index === 1 && viewIndex === 1) || (index === 2 && viewIndex === 2) ? simcapPink : 'black',
                    fontWeight: 'bold',
                  }}
                >
                  {text}
                </Box>
              }
              
            </Box>
          ))}

          {/* For Individuals — No 'Customize' Section */}
          {userIsIndividual && ['Simulations', 'My Account'].map((text, index) => (
            <Box key={text} sx={{ mx: 4 }}>
              
              {width < positionChangeWidthSm ?
                // Icon Button if smaller screen width
                <Box>
                  <IconButton
                    size="large"
                    aria-label="inside account navigation"
                    aria-haspopup="true"
                    onClick={index === 0 ? handleNavSimulations : handleNavMyAccount}
                    color="inherit"
                  >
                    {index === 0 ? <BarChartIcon sx={{ color: viewIndex === 0 ? simcapPink : 'black' }}/> : <PersonIcon sx={{ color: viewIndex === 1 ? simcapPink : 'black' }}/> }
                  </IconButton>
                </Box>
                :
                // Words if larger screen width
                <Box
                  onClick={index === 0 ? handleNavSimulations : handleNavMyAccount}
                  sx={{
                    cursor: 'pointer',
                    color: (index === 0 && viewIndex === 0) || (index === 1 && viewIndex === 1) ? simcapPink : 'black',
                    fontWeight: 'bold',
                  }}
                >
                  {text}
                </Box>
              }
              
            </Box>
          ))}
          
        </Toolbar>
      </AppBar>
    </>
  )
}

export default NavDashboard