/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import blackDottedSpinnerGIF from '../../../assets/dotted-black-trans-spinner-200.gif'
import turquoiseDottedSpinnerGIF from '../../../assets/dotted-turquoise-trans-spinner-200.gif'
import whiteDottedSpinnerGIF from '../../../assets/dotted-white-trans-spinner-200.gif'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import InfoIcon from '@mui/icons-material/Info'
import MenuBookIcon from '@mui/icons-material/MenuBook'

import FinancialFactorsCard from './helpers/FinancialFactorsCard.js'
import NonFinancialFactorsCard from './helpers/NonFinancialFactorsCard.js'
import ViewPortfolio from './helpers/ViewPortfolio.js'

import { navbarHeight, footerHeight, vcSimTopBarHeight, positionChangeWidthSm, simcapTurquoise, exampleCompaniesObj, positionChangeWidthLg } from '../../../helpers/variableDefaults'
import { standardButton } from '../../../helpers/buttons'
import { standardErrorContainer } from '../../../helpers/errors.js'
import useWindowDimensions from '../../../helpers/windowDimensions.js'
import { reasonForDecisionTextField } from '../../../helpers/textfields.js'
import { handleChange } from '../../../helpers/globalHelpers.js'
import { getCompanyOfInvestmentSelection } from '../../../helpers/getNestedElements.js'

import { seoPageTags, customAnalyticsEvent } from '../../../helpers/analytics'
import { standardSpinner } from '../../../helpers/spinners.js'

// Live VC Simulation
const LiveVCSimulation = (props) => {

  const { vcSimulation, setVCSimulation, currentCompany, setCurrentCompany, exampleCurrentInvestmentIndex, setExampleCurrentInvestmentIndex, exampleCurrentFollowOnIndex, setExampleCurrentFollowOnIndex, rules, setShowInstructions, loading, setLoading, setPreparingResults, isDemo, setDemoIsFinished } = props
  // console.log('vcSimulation ->', vcSimulation)
  // console.log('currentCompany ->', currentCompany)
  // const rules = vcSimulation.standardizedSimulation.rules

  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // States
  const [investmentDecision, setInvestmentDecision] = useState({
    willInvest: '',
    reasonForDecision: '',
  })
  const [showViewPortfolio, setShowViewPortfolio] = useState(false)
  // const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(false)

  const isFollowOn = (sim, company) => {
    return !isDemo && sim.investmentSelections.length > 0 
      && sim.investmentSelections.filter(selection => selection.id === company.id).length > 0
  }

  const isExampleCompany = (sim, company) => {
    return isDemo 
    ||
    ( 
      (sim.exampleCurrentInvestmentIndex < exampleCompaniesObj.exampleCompanies.length || sim.exampleCurrentFollowOnIndex < exampleCompaniesObj.exampleCompanies.length)
      && (exampleCompaniesObj.exampleCompanies.filter(selection => selection.id === company.id).length > 0)
    )
  }

  const handleInstructions = () => {
    setShowInstructions(true)
  }

  const handleViewPortfolio = () => {
    // console.log('handleViewPortfolio pressed')
    setShowViewPortfolio(true)
  }

  const handleSubmit = async () => {
    // console.log('handleSubmit Pressed')
    setLoading(true)
    setErrors(false)

    if (
      isExampleCompany(vcSimulation, currentCompany)
      && 
      exampleCurrentFollowOnIndex + 1 < exampleCompaniesObj.exampleCompanies.length
    ) {

      // Reset Investment Decision
      setInvestmentDecision({
        willInvest: '',
        reasonForDecision: '',
      })

      // setVCSimulation({ ...vcSimulation, objectToUpload })
      if (exampleCurrentInvestmentIndex + 1 < exampleCompaniesObj.exampleCompanies.length) {
        // console.log('new example initial')
        setExampleCurrentInvestmentIndex(exampleCurrentInvestmentIndex + 1)
        setCurrentCompany(exampleCompaniesObj.exampleCompanies[exampleCurrentInvestmentIndex + 1])
      } else {
        // console.log('new example follow on')
        // console.log('exampleCurrentFollowOnIndex ->', exampleCurrentFollowOnIndex)
        setExampleCurrentInvestmentIndex(exampleCompaniesObj.exampleCompanies.length)
        setExampleCurrentFollowOnIndex(exampleCurrentFollowOnIndex + 1)
        setCurrentCompany(exampleCompaniesObj.exampleCompanies[exampleCurrentFollowOnIndex + 1])
      }

      setLoading(false)
      setErrors(false)
    
    } else if (isDemo) {
      setExampleCurrentInvestmentIndex(exampleCompaniesObj.exampleCompanies.length)
      setExampleCurrentFollowOnIndex(exampleCompaniesObj.exampleCompanies.length)
      setDemoIsFinished(true)
    } else {

      const objectToUpload = {}
      if (isExampleCompany(vcSimulation, currentCompany)) {
        setExampleCurrentInvestmentIndex(exampleCompaniesObj.exampleCompanies.length)
        setExampleCurrentFollowOnIndex(exampleCompaniesObj.exampleCompanies.length)
        objectToUpload.exampleCurrentInvestmentIndex = exampleCompaniesObj.exampleCompanies.length
        objectToUpload.exampleCurrentFollowOnIndex = exampleCompaniesObj.exampleCompanies.length

      } else if (isFollowOn(vcSimulation, currentCompany)) {
        if (
          (
            vcSimulation.investmentSelections.length >= vcSimulation.standardizedSimulation.rules.maxPortfolioCompanies
            && vcSimulation.currentFollowOnIndex + 1 >= vcSimulation.standardizedSimulation.rules.maxFollowOnCompanies
          )
          ||
          (
            (
              vcSimulation.currentInvestmentIndex >= vcSimulation.standardizedSimulation.rules.totalOpportunities // Have exhausted all investment opportunities
              || vcSimulation.investmentSelections.length >= vcSimulation.standardizedSimulation.rules.maxPortfolioCompanies
            )
            &&
            (
              Math.floor(vcSimulation.currentFollowOnIndex) + 1 >= vcSimulation.standardizedSimulation.rules.maxPortfolioCompanies // Have exhausted all follow-on opportunities
              || Math.floor(vcSimulation.currentFollowOnIndex) + 1 >= vcSimulation.investmentSelections.length // No more initial investments
            )
          )
        ) {
          setPreparingResults(true)
        }

        // console.log('vcSimulation.currentFollowOnIndex ->', vcSimulation.currentFollowOnIndex)
        objectToUpload.currentFollowOnIndex = vcSimulation.currentFollowOnIndex + 1
        // console.log('objectToUpload.currentFollowOnIndex ->', objectToUpload.currentFollowOnIndex)

        if (investmentDecision.willInvest.toLowerCase() === 'yes') {
          const newFollowOnsArray = [ ...vcSimulation.followOnSelections ]
          newFollowOnsArray.push({
            id: currentCompany.id,
            reason: investmentDecision.reasonForDecision === '' ? 'No Reason' : investmentDecision.reasonForDecision,
          })

          objectToUpload.followOnSelections = newFollowOnsArray

        } else {
          const newNotFollowingOnArray = [ ...vcSimulation.notFollowingOnSelections ]

          newNotFollowingOnArray.push({
            id: currentCompany.id,
            reason: investmentDecision.reasonForDecision === '' ? 'No Reason' : investmentDecision.reasonForDecision,
          })

          objectToUpload.notFollowingOnSelections = newNotFollowingOnArray
        }
      } else {

        // console.log('vcSimulation.currentFollowOnIndex ->', vcSimulation.currentInvestmentIndex)
        objectToUpload.currentInvestmentIndex = vcSimulation.currentInvestmentIndex + 1
        // console.log('objectToUpload.currentInvestmentIndex ->', objectToUpload.currentInvestmentIndex)

        if (investmentDecision.willInvest.toLowerCase() === 'yes') {
          const newInvestmentsArray = [ ...vcSimulation.investmentSelections ]

          newInvestmentsArray.push({
            id: currentCompany.id,
            reason: investmentDecision.reasonForDecision === '' ? 'No Reason' : investmentDecision.reasonForDecision,
          })

          objectToUpload.investmentSelections = newInvestmentsArray

        } else {
          const newNotInvestingArray = [ ...vcSimulation.notInvestingSelections ]

          newNotInvestingArray.push({
            id: currentCompany.id,
            reason: investmentDecision.reasonForDecision === '' ? 'No Reason' : investmentDecision.reasonForDecision,
          })

          objectToUpload.notInvestingSelections = newNotInvestingArray
        }
      }

      // console.log('objectToUpload ->', objectToUpload)

      if (Object.keys(objectToUpload).length > 0) {
        
        try {
          // console.log('objectToUpload ->', objectToUpload)
          const { data } = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/vc-simulations/${vcSimulation._id}`, objectToUpload)
          // console.log('data ->', data)
          // console.log('investement selections length ->', data.investmentSelections.length)

          // Reset Investment Decision
          setInvestmentDecision({
            willInvest: '',
            reasonForDecision: '',
          })

          setVCSimulation(data)

          // setLoading(false)
          setErrors(false)


        } catch (error) {
          // console.log('error modifying vcSimulation')
          setErrors(true)
          setLoading(false)
        }
      } else {
        setErrors(true)
        setLoading(false)
      }
    }

  }

  const topBarButtons = () => {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        }}
      >
        {/* Instructions Button */}
        <IconButton
          onClick={handleInstructions} 
          sx={{ 
            // width: '30px', height: '30px', 
            mb: 0, mt: 1, mr: width < positionChangeWidthSm ? 0 : 0, ml: 0, bottom: 0, left: 0, 
            // border: 2, borderColor: 'white', boxShadow: 3, 
            
          }} 
        >
          <InfoIcon 
            fontSize="medium" 
            // color="primary"
            sx={{ 
              color: simcapTurquoise,
              '&:hover': { color: 'white' },
            }} 
          />
        </IconButton>
        {/* {isDemo && 
          standardButton(
            'Instructions', 
            'button',
            'text',
            false,
            'primary', 
            2,
            0,
            0,
            '125px', 
            '45px', 
            handleInstructions
          )
        } */}

        {/* View Portfolio Button */}
        {!isDemo &&
          <IconButton
            onClick={handleViewPortfolio} 
            sx={{ 
              // width: '30px', height: '30px', 
              mb: 0, mt: 1, mr: width < positionChangeWidthSm ? 0 : 0, ml: 0, bottom: 0, left: 0, 
              // border: 2, borderColor: 'white', boxShadow: 3, 
            }} 
          >
            <MenuBookIcon 
              fontSize="medium" 
              // color="primary"
              sx={{ 
                color: simcapTurquoise,
                '&:hover': { color: 'white' },
              }} 
            />
          </IconButton>
        }
        {/* {standardButton(
          'View Portfolio', 
          'button',
          'text',
          false,
          'primary', 
          2,
          0,
          0,
          '135px', 
          '45px', 
          handleViewPortfolio
        )} */}

      </Box>
    )
  }

  const topBarElement = (element, value) => {
    // console.log('is example company ->', isExampleCompany(vcSimulation, currentCompany))
    return (
      <Box
        sx={{
          // backgroundColor: 'blue',
          textWrap: 'nowrap',
          width: '55%', maxWidth: isExampleCompany(vcSimulation, currentCompany) || width <= 450 ? '250px' : '170px', minWidth: '130px',
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            mr: 2,
            color: 'white',
            fontSize: '18px',
          }}
        >
          {element}:
        </Typography>
        <Typography
          sx={{
            color: value.includes('Example') ? 'deeppink' : value === 'Follow-On' ? 'yellow' : 'white',
            fontSize: '18px',
          }}
        >
          {value}
        </Typography>
      </Box>
    )
  }

  const companyDetailsElement = (label, description) => {
    return (
      <Box
        sx={{
          // backgroundColor: 'yellow',
          // mb: 1,
          width: '85%',
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start',
        }}
      >
        {/* Description Label */}
        <Typography
          sx={{
            // backgroundColor: 'orange',
            mr: 1,
            width: width < 400 ? '160px' : width < positionChangeWidthSm ? '170px' : '190px', minWidth: width < 400 ? '160px' : width < positionChangeWidthSm ? '170px' : '190px',
            fontWeight: 'bold',
            fontSize: width < 400 ? '14px' : width < positionChangeWidthSm ? '16px' : '18px',
            // display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start',
          }}
        >
          {label}
        </Typography>

        {/* Name of Company*/}
        <Typography
          sx={{
            // backgroundColor: 'blue',
            ml: 1,
            // width: '70px',
            fontSize: width < 400 ? '14px' : width < positionChangeWidthSm ? '16px' : '18px',
          }}
        >
          {description}
        </Typography>
      </Box>
    )
  }


  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        isDemo ? 'Demo' : 'Live VC Simulation'
      )}

      {/* Body */}
      <Box 
        sx={{ 
          // backgroundColor: 'black',
          height: `100vh`, minHeight: '100vh',
          width: '100vw', minWidth: '100vw',
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
        }}
      >
        {/* Top Bar */}
        <Box
          sx={{
            backgroundColor: 'black',
            width: '100%',
            height: vcSimTopBarHeight,
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          {/* Buttons (Instructions, View Profile) */}
          {topBarButtons()}

          {/* Relevant Test Info */}
          <Box
            sx={{
              // backgroundColor: 'orange',
              width: '100%',
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',
            }}
          >
            {/* Left Side (Decision Number, Month, Type) */}
            <Box
              sx={{
                pr: 5,
                // backgroundColor: 'green',
                width: isExampleCompany(vcSimulation, currentCompany) || width <= 450 ? '90%' : '45%',
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: isExampleCompany(vcSimulation, currentCompany) || width <= 450 ? 'center' : 'flex-end',
              }}
            >
              {/* Decision Number */}
              {topBarElement(
                'Decision', 
                `
                  ${isExampleCompany(vcSimulation, currentCompany) && exampleCurrentInvestmentIndex < exampleCompaniesObj.exampleCompanies.length ? exampleCurrentInvestmentIndex + 1 : isExampleCompany(vcSimulation, currentCompany) && exampleCurrentFollowOnIndex < exampleCompaniesObj.exampleCompanies.length ? exampleCurrentFollowOnIndex + 1 : isFollowOn(vcSimulation, currentCompany) ? vcSimulation.currentFollowOnIndex + 1 : vcSimulation.currentInvestmentIndex + 1 }
                  /
                  ${isExampleCompany(vcSimulation, currentCompany) && exampleCurrentInvestmentIndex < exampleCompaniesObj.exampleCompanies.length ? exampleCompaniesObj.exampleCompanies.length : isExampleCompany(vcSimulation, currentCompany) && exampleCurrentFollowOnIndex < exampleCompaniesObj.exampleCompanies.length ? exampleCompaniesObj.exampleCompanies.length : isFollowOn(vcSimulation, currentCompany) ? rules.maxPortfolioCompanies : rules.totalOpportunities}
                `.replace(/\s/g, '')
              )}
              
              {/* Month Number */}
              {topBarElement('Month', `${isExampleCompany(vcSimulation, currentCompany) ? 1 : isFollowOn(vcSimulation, currentCompany) ? currentCompany.followOnInvestmentMonth : currentCompany.initialInvestmentMonth}`)}

              {/* Type (Example Initial, Example Follow-On, Initial, or Follow-On) */}
              {topBarElement('Type', `${isExampleCompany(vcSimulation, currentCompany) && exampleCurrentInvestmentIndex < exampleCompaniesObj.exampleCompanies.length ? 'Example Initial' : isExampleCompany(vcSimulation, currentCompany) && exampleCurrentFollowOnIndex < exampleCompaniesObj.exampleCompanies.length ? 'Example Follow-On' : isFollowOn(vcSimulation, currentCompany) ? 'Follow-On' : 'Initial' }`)}

            </Box>


            {/* Right Side (Investments, Follow-Ons, Threshold Met) */}
            {!(isExampleCompany(vcSimulation, currentCompany)) && width > 450 &&
              <Box
                sx={{
                  pl: 5,
                  width: '45%',
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',
                }}
              >
                {/* Investments */}
                {topBarElement('Investments', `${vcSimulation.investmentSelections.length}/${rules.maxPortfolioCompanies}`)}

                {/* Follow-Ons */}
                {topBarElement('Follow-Ons', `${vcSimulation.followOnSelections.length}/${rules.maxFollowOnCompanies}`)}

                {/* Threshold Met */}
                {topBarElement('Threshold Met', vcSimulation.investmentSelections.length * (rules.initialInvestmentAmount + rules.followOnInvestmentAmount) >= (rules.amountToInvest * rules.newFundraisingThreshold) ? `Yes` : `No`)}
              </Box>
            }

          </Box>

        </Box>

        {/* Company to Decide on */}
        <Box
          sx={{
            // backgroundColor: 'lightpink',
            pb: 5,
            width: '100%',
            height: `calc(100vh - ${vcSimTopBarHeight})`,
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              WebkitAppearance: 'none',
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '4px',
              backgroundColor: 'rgba(0, 0, 0, .35)',
              webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
            },
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          {/* The Decision */}
          <Box
            sx={{
              mt: 3, mb: 3,
              width: '100%',
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
            }}
          >
            {/* Will You Invest */}
            <Box
              sx={{
                width: '95%', maxWidth: '300px', 
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  // color: 'white',
                }}
              >
                Will you invest?
              </Typography>

              <ToggleButtonGroup
                value={investmentDecision.willInvest}
                color="primary"
                exclusive
                onChange={(e) => handleChange(e, setErrors, setInvestmentDecision, investmentDecision)}
                aria-label="Will Invest"
                sx={{
                  // color: 'white',
                  // border: '2px solid white',
                }}
              >
                <ToggleButton name={'willInvest'} value="YES" aria-label="YES" 
                  sx={{ 
                    // color: 'white', 
                    // border: investmentDecision.willInvest === 'YES' ? '2px solid #00ffff' : '2px solid white', 
                  }}
                >
                  YES
                </ToggleButton>
                <ToggleButton name={'willInvest'} value="NO" aria-label="NO" 
                  sx={{ 
                    // color: 'white', 
                    // border: investmentDecision.willInvest === 'NO' ? '2px solid #00ffff' : '2px solid white', 
                  }}
                >
                  NO
                </ToggleButton>
              </ToggleButtonGroup>

            </Box>

            {/* Reason for Decision */}
            <Box
              sx={{
                mt: 1,
                width: '95%', maxWidth: '300px',
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
              }}
            >
              {reasonForDecisionTextField('reasonForDecision', investmentDecision.reasonForDecision, 'Reason', 'Reason (optional)', true, 'outlined', investmentDecision, setInvestmentDecision, setErrors, 2, width )}
            </Box>

            {/* Submit Button */}
            {standardButton(
              'Submit', 
              'button',
              'contained',
              investmentDecision.willInvest === '',
              'secondary', 
              2,
              0,
              0,
              '180px', 
              '45px', 
              handleSubmit
            )}

            {errors && 
              standardErrorContainer('There was an error uploading your selection. Please check your Internet connection', 0, 1)
            }

          </Box>

          {/* Company Essentials */}
          <Box
            sx={{
              mt: 2, mb: 2, py: 2,
              backgroundColor: 'whitesmoke',
              width: '95%', maxWidth: '600px',
              boxShadow: 3, borderRadius: 3,
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
            }}
          >
            {/* Title */}
            <Box
              sx={{
                width: '100%',
                fontWeight: 'bold',
                fontSize: '24px',
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
              }}
            >
              {currentCompany.name}
            </Box>

            {/* Description */}
            <Box
              sx={{
                mt: 1, mb: 1,
                // backgroundColor: 'yellow',
                // px: 2,
                width: '100%',
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  width: '85%',
                  display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >
                {currentCompany.description}
              </Typography>
            </Box>

            {/* Details */}
            <Box
              sx={{
                // backgroundColor: 'yellow',
                mt: 1,
                width: '95%',
                display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
              }}
            >

              {/* Investment Type */}
              {(isFollowOn(vcSimulation, currentCompany) || (isExampleCompany(vcSimulation, currentCompany) && exampleCurrentInvestmentIndex >= exampleCompaniesObj.exampleCompanies.length && exampleCurrentFollowOnIndex < exampleCompaniesObj.exampleCompanies.length)) && companyDetailsElement('Investment Type:', 'Follow-On')}
              

              {/* Investment Amount */}
              {(isFollowOn(vcSimulation, currentCompany) || (isExampleCompany(vcSimulation, currentCompany) && exampleCurrentInvestmentIndex >= exampleCompaniesObj.exampleCompanies.length && exampleCurrentFollowOnIndex < exampleCompaniesObj.exampleCompanies.length)) && companyDetailsElement('Investment Amount:', `$${rules.followOnInvestmentAmount}M`)}
              
              {/* Momentum */}
              {(isFollowOn(vcSimulation, currentCompany) || (isExampleCompany(vcSimulation, currentCompany) && exampleCurrentInvestmentIndex >= exampleCompaniesObj.exampleCompanies.length && exampleCurrentFollowOnIndex < exampleCompaniesObj.exampleCompanies.length)) && currentCompany.hasOwnProperty('followOnInfo') && companyDetailsElement('Momentum:', currentCompany.followOnInfo.statusAtFollowOn)}
              
              {/* Post-Money Valuation */}
              {(isFollowOn(vcSimulation, currentCompany) || (isExampleCompany(vcSimulation, currentCompany) && exampleCurrentInvestmentIndex >= exampleCompaniesObj.exampleCompanies.length && exampleCurrentFollowOnIndex < exampleCompaniesObj.exampleCompanies.length)) && currentCompany.hasOwnProperty('followOnInfo') && companyDetailsElement('Post-Money Valuation:', `$${parseFloat(currentCompany.followOnInfo.followOnPostMoneyValuation).toFixed(2)}M`)}
              
              {/* Follow-On Round Size */}
              {(isFollowOn(vcSimulation, currentCompany) || (isExampleCompany(vcSimulation, currentCompany) && exampleCurrentInvestmentIndex >= exampleCompaniesObj.exampleCompanies.length && exampleCurrentFollowOnIndex < exampleCompaniesObj.exampleCompanies.length)) && currentCompany.hasOwnProperty('followOnInfo') && companyDetailsElement('Follow-On Round Size:', `$${currentCompany.followOnInfo.followOnRoundSize}M`)}
              
            </Box>

          </Box>

          {/* Financial and Non-Financial Info */}
          <Box
            sx={{
              width: '100%',
              display: 'flex', flexDirection: width < positionChangeWidthLg ? 'column' : 'row', justifyContent: width < positionChangeWidthLg ? 'flex-start' : 'center', alignItems: width < positionChangeWidthLg ? 'center' : 'flex-start',
            }}
          >
            {/* Non-Financial Information */}
            <Box
              sx={{ 
                mt: 2, mb: 2,
                width: width < positionChangeWidthLg ? '100%' : '45%',
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',
              }}
            >
              <NonFinancialFactorsCard currentCompany={currentCompany} rules={rules} />
            </Box>

            {/* Financial Information */}
            <Box
              sx={{ 
                mt: 2, mb: 2,
                width: width < positionChangeWidthLg ? '100%' : '45%',
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',
              }}
            >
              <FinancialFactorsCard currentCompany={currentCompany} rules={rules} />
            </Box>
            
          </Box>

        </Box>
        
        {!isDemo &&
          <ViewPortfolio vcSimulation={vcSimulation} rules={rules} showViewPortfolio={showViewPortfolio} setShowViewPortfolio={setShowViewPortfolio} />
        }

      </Box>
    </>
  )
}

export default LiveVCSimulation