/* eslint-disable no-prototype-builtins */
import * as React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { tooltipMinWidth, tooltipInfoDimension } from './variableDefaults'
import InfoIcon from '@mui/icons-material/Info'

export const TooltipPeopleChatJSX = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
  // maxWidth: '220px',
}))

export const standardInfoTooltip = (textHTML, marginLeft, color = 'NA') => {
  return (
    <Box 
      sx={{ 
        color: color !== 'NA' ? color : 'black',
        ml: marginLeft,
        width: tooltipInfoDimension, height: tooltipInfoDimension, 
      }}
    >
      <TooltipPeopleChatJSX 
        disableFocusListener 
        enterTouchDelay={0}
        leaveTouchDelay={5000}
        title={
          <Box
            sx={{
              py: 0.25,
              width: '100%',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
            }}
          >
            {/* Description */}
            <Typography
              textAlign={'left'}
              sx={{
                // backgroundColor: 'yellow',
                width: tooltipMinWidth,
                fontSize: '14px',
                // overflow: 'hidden',
                // textOverflow: 'ellipsis',
                // display: '-webkit-box',
                // WebkitLineClamp: '1',
                // WebkitBoxOrient: 'vertical',
              }}
            >
              {ReactHtmlParser(textHTML)}
            </Typography>
          </Box>
        }
      >
        <InfoIcon />
      </TooltipPeopleChatJSX>
    </Box>
  )
}