/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Divider from '@mui/material/Divider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import { navbarHeight, footerHeight, positionChangeWidthSm, positionChangeWidthLg } from '../../../../helpers/variableDefaults'
import { standardSpinner } from '../../../../helpers/spinners'
import { couldNotLoadPageError } from '../../../../helpers/errors'
import { makeTabIndex } from '../../../../helpers/formatting'
import { TabPanel } from '../../../../helpers/formatting'
import { standardInfoTooltip } from '../../../../helpers/tooltips'


const strategyElementAccordionJSX = (title, reflection, expandedDefault = false) => {
  return (
    // Instantiate an Accordion
    <Accordion
      defaultExpanded={expandedDefault}
      sx={{ 
        width: '85%',
        boxShadow: 4, 
      }}
    >
      {/* Title appears on Accordion Summary */}
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          backgroundColor: 'lightblue',
          fontSize: '18px',
          border: 2,
          borderBottom: 2,
          borderColor: 'black',
        }}
      >
        <Typography
          sx={{
            width: '100%',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          <strong>{title.toUpperCase()}</strong>
        </Typography>
      </AccordionSummary>

      {/* Labels and Values */}
      <AccordionDetails
        sx={{
          backgroundColor: 'lightyellow',
          border: 2,
          borderTop: 0,
          borderColor: 'black',
          display: 'flex', flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            mr: 0.5, mb: 1, mt: 1,
            width: '100%',
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '18px',
            }}
          >
            {reflection === '' ? 'No response given' : reflection}
          </Typography>
        </Box>
      </AccordionDetails>

    </Accordion>
  )
}

export const vcResultsStrategyTabJSX = (value, index, vcSimulation, loading, width) => {
  return (
    <TabPanel value={value} index={index}
      sx={{
        // width: '100%',
        // backgroundColor: 'green',
      }}
    >
      {loading ?
        standardSpinner()
        : 
        <Box sx={{ 
          // backgroundColor: 'yellow',
          mt: 0, mb: 0, 
          width: '90vw', maxWidth: positionChangeWidthSm, 
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
        }}>
          
          <Box
            sx={{
              mt: 2, mb: 8, py: 4,
              backgroundColor: 'whitesmoke',
              width: '100%', maxWidth: '600px',
              boxShadow: 3, borderRadius: 3,
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
            }}
          >
            {/* Initial Investment Strategy */}
            {strategyElementAccordionJSX(
              'Initial Investment Strategy', 
              vcSimulation.testTakerReflections.initialInvestmentStrategy, 
              true
            )}

            {/* Initial Investment Strategy Adherence */}
            {strategyElementAccordionJSX(
              'Initial Investment Strategy Adherence', 
              vcSimulation.testTakerReflections.initialInvestmentStrategyAdherence, 
              true
            )}

            {/* Follow-On Investment Strategy */}
            {strategyElementAccordionJSX(
              'Follow-On Investment Strategy', 
              vcSimulation.testTakerReflections.followOnInvestmentStrategy, 
              true
            )}

            {/* Follow-On Investment Strategy Adherence */}
            {strategyElementAccordionJSX(
              'Follow-On Investment Strategy Adherence', 
              vcSimulation.testTakerReflections.followOnStrategyAdherence, 
              true
            )}

            {/* Other Reflections */}
            {strategyElementAccordionJSX(
              'Other Reflections', 
              vcSimulation.testTakerReflections.otherReflections, 
              true
            )}


          </Box>
        </Box>
      }
    </TabPanel>
  )
}