/* eslint-disable no-prototype-builtins */
import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import SettingsIcon from '@mui/icons-material/Settings'

import profPicDefault from '../assets/seed-profpic-default-2.jpg'
import { standardButton } from './buttons'

import { loginTextField } from './textfields'
import { handleChange, validEmail } from './globalHelpers'
import { positionChangeWidthSm, entityTypes } from './variableDefaults'
import { selectElementHandleChange } from './selects'

// User Profile Section Title
export const userProfilieSectionTitle = (title) => {
  return (
    <Typography sx={{ mt: 1, fontSize: '18px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
      <strong>{title}</strong>
    </Typography>
  )
}

// User Profile Title and Edit/Cancel/Save Button JSX
export const titleAndEditButton = (title, inEditMode, user, savePressed, editPressed ) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
      {userProfilieSectionTitle(title)}
      {inEditMode ?
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
          {standardButton(
            'Cancel', 
            'button',
            'contained',
            false,
            'warning', 
            0,
            0,
            1,
            '60px',
            '30px',
            editPressed
          )}
          {standardButton(
            'Save', 
            'button',
            'contained',
            title === 'Security Questions' ? 
              !(user.hasOwnProperty('securityQuestions') && user.securityQuestions.length === 3 && user.securityQuestions[0].q.length > 0 && user.securityQuestions[0].a.length > 0 && user.securityQuestions[1].q.length > 0 && user.securityQuestions[1].a.length > 0 && user.securityQuestions[2].q.length > 0 && user.securityQuestions[2].a.length > 0)
              :
              !(user.hasOwnProperty('entityName') && user.entityName.replace(/\s/g, '').length > 0 && user.entityName.charAt(0) !== ' ' && user.hasOwnProperty('entityType') && user.entityType.replace(/\s/g, '').length > 0 && user.entityType.charAt(0) !== ' ' && user.hasOwnProperty('email') && validEmail(user.email)),
            'primary', 
            0,
            0,
            0,
            '60px',
            '30px',
            savePressed
          )}
        </Box>
        :
        standardButton(
          title === 'Security Questions' ? 'Edit' : 'Edit', 
          'button',
          'text',
          !user.subscriptionIsActive,
          'primary', 
          0,
          0,
          0,
          '60px',
          '30px',
          editPressed
        )
      }
    </Box>
  )
}

// Personal Details Typography
export const personalDetailsTypography = (title) => {
  return (
    <Typography variant='p' sx={{ 
      // backgroundColor: 'orange',
      mb: 1, mt: 1, 
      width: '50%', minWidth: '50px',
      fontSize: '18px',
    }}>
      <strong>{title}: </strong>
    </Typography>
  )
}

// Personal Details TextField or Typography (depending on whether in edit mode or not)
export const personalDetailsTextFieldOrTypography = (name, title, user, setUser, inEditMode, setErrors) => {
  return (
    <Box 
      sx={{
        width: '100%',
        display: 'flex', flexDirection: 'row', justifyContent: 'center',
      }}
    >
      {inEditMode ?
        name === 'entityType' ? 
          <Box sx={{ width: '100%', mt: 1, mb: 1 }}>
            {selectElementHandleChange('entityType', 'Entity Type', user[name], true, true, 'lg', entityTypes, user, setUser, setErrors)}
          </Box>
          :
          <Box sx={{ width: '100%', mt: 1, mb: 1 }}>
            {loginTextField(name, title, user[name], 'outlined', true, true, true, 'new-password', user, setUser, setErrors, handleChange )}
          </Box>
        :
        <>
          {personalDetailsTypography(title)}
          
          <Typography textAlign={'right'} variant='p' sx={{ 
            // backgroundColor: 'yellow',
            mb: 1, mt: 1, 
            width: '50%', minWidth: name === 'email' ? '170px' : '100px',
            fontSize: '18px',
          }}>
            {user[name]}
          </Typography>
        </>
      }
    </Box>
  )
}
