/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams, Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { LineChart } from '@mui/x-charts/LineChart'
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis'
import EditIcon from '@mui/icons-material/Edit'
import StarIcon from '@mui/icons-material/Star'
import SendIcon from '@mui/icons-material/Send'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { ExpandMore } from '../../../../helpers/cardHelpers'
import { positionChangeHeightSm, positionChangeWidthSm, positionChangeWidthLg, simcapPink, simcapTurquoise } from '../../../../helpers/variableDefaults'
import useWindowDimensions from '../../../../helpers/windowDimensions'
import { standardButton } from '../../../../helpers/buttons'
import { dummyBox } from '../../../../helpers/globalHelpers'

const FinancialFactorsCard = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Destructure Props
  const { currentCompany, rules } = props
  // console.log('company ->', company)

  // Tracks whether the card is expanded
  const [expanded, setExpanded] = useState(false)

  // Changes the card expanded state
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const financialDetailsElement = (label, description) => {
    return (
      <Box
        sx={{
          mb: 0.5,
          width: width < positionChangeWidthSm ? '95%' : '85%',
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start',
        }}
      >
        {/* Description Label */}
        <Typography
          sx={{
            mr: 1,
            width: width < 400 ? '200px' : width < positionChangeWidthSm ? '250px' : '350px', minWidth: width < 400 ? '200px' : width < positionChangeWidthSm ? '250px' : '350px',
            // fontWeight: 'bold',
            fontSize: width < 400 ? '14px' : width < positionChangeWidthSm ? '16px' : '18px',
          }}
        >
          {label}
        </Typography>

        {/* Description*/}
        <Typography
          sx={{
            // backgroundColor: 'blue',
            ml: 1,
            fontWeight: 'bold',
            fontSize: width < 400 ? '14px' : width < positionChangeWidthSm ? '16px' : '18px',
          }}
        >
          {description}
        </Typography>
      </Box>
    )
  }

  const lineGraph5YearRevenue = () => {
    return (
      <CardContent>
        <Typography
          component="pre"
          sx={{
            mb: -3, mt: 2,
            width: '100%',
            fontFamily: 'Lato',
            fontSize: '18px',
            fontWeight: 'bold',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          Projected Revenue Next 5 Years
        </Typography>
        {/* Graph of Revenue Projections */}
        <LineChart
          xAxis={[{ 
            scaleType: 'point',
            data: ['0', 'Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5'],
            label: 'Year',
            labelFontSize: 16,
            labelStyle: { 
              marginTop: 2, 
              // fontWeight: 'bold', 
            },
            tickMinStep: 1,
          }]}
          yAxis={[{
            id: 'revenueAxis',
            label: '($M)',
            labelStyle: { 
              // fontWeight: 'bold', 
              fontSize: '16px', 
            },
          }]}
          // leftAxis={'DEFAULT_Y_AXIS_KEY'}
          series={[
            {
              data: [
                0, // Year 0
                currentCompany.financialExpectations.projectedYear1Revenues.value, // Year 1
                currentCompany.financialExpectations.projectedYear2Revenues.value, // Year 2
                currentCompany.financialExpectations.projectedYear3Revenues.value, // Year 3
                currentCompany.financialExpectations.projectedYear4Revenues.value, // Year 4
                currentCompany.financialExpectations.projectedYear5Revenues.value // Year 5
              ],
              area: true,
            }
          ]}
          width={500}
          height={300}
          sx={{
            // Label Style Y Axis
            '& .MuiChartsAxis-directionY .MuiChartsAxis-label': {
              // mr: 1,
              transform: 'translateX(-5px)',
              // transform: 'translateX(-5px) scale(1.15)',
              // transform: 'scale(1.3)',
            },
            // leftAxis Line Styles
            // '& .MuiChartsAxis-directionY .MuiChartsAxis-line': {
            //   stroke: '#00000FF',
            //   strokeWidth: 0.4,
            // },
            // Tick Label Y Axis
            // '& .MuiChartsAxis-directionY .MuiChartsAxis-tickLabel': {
            //   strokeWidth: '0.4',
            //   fill: '#ff0000',
            // },
            // All tick labels font family
            // '& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel': {
            //   fontFamily: 'Roboto',
            // },
            // Label Style X Axis
            // '& .MuiChartsAxis-directionX .MuiChartsAxis-label': {
            //   // mr: 1,
            //   transform: 'translateY(-5px) scale(1.1)',
            //   // transform: 'scale(1.3)',
            // },
            // change bottom tick label styles
            // '& .MuiChartsAxis-directionX .MuiChartsAxis-tickLabel': {
            //   strokeWidth: '0.5',
            //   fill: '#0000FF',
            // },
            // bottomAxis Line Styles
            // '& .MuiChartsAxis-directionX .MuiChartsAxis-line': {
            //   stroke: '#0000FF',
            //   strokeWidth: 0.4,
            // },
          }}
        />
      </CardContent>
    )
  }

  return (
    <Card 
      align="left" 
      sx={{ 
        pt: 2, 
        pb: width > positionChangeWidthLg ? 6.75 : 2,
        backgroundColor: 'whitesmoke',
        width: '95%', maxWidth: '600px',
        minHeight: width > positionChangeWidthLg ? '730px' : '',
        boxShadow: 3, borderRadius: 3,
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
      }}
    >
      {/* Title */}
      <Box
        sx={{
          mb: 4,
          width: '100%',
          fontWeight: 'bold',
          fontSize: '24px',
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        }}
      >
        {width >= 340 ? 'Financial Information' : 'Financial'}
      </Box>

      {/* Initial Investment Amount */}
      {financialDetailsElement(`Initial Investment Amount:`, `$${rules.initialInvestmentAmount}M`)}


      {/* Initial Post-Financing Ownership Share */}
      {financialDetailsElement(`Initial Post-Financing Ownership Share:`, currentCompany.financialExpectations.initialInvestmentOwnershipPercentage.description)}


      {/* Post-Money Valuation, Initial Investment */}
      {financialDetailsElement(`Post-Money Valuation, Initial Investment:`, `$${currentCompany.financialExpectations.initialPostMoneyValuation}M`)}


      {/* Forecasted Revenue at Plateau */}
      {financialDetailsElement(`Forecasted Revenue at Plateau:`, currentCompany.financialExpectations.projectedRevenuesPlateau.description)}


      {/* Forecasted Margin at Plateau */}
      {financialDetailsElement(`Forecasted Margin at Plateau:`, currentCompany.financialExpectations.profitMarginAtPlateau.description)}


      {/* Anticipated Additional Investment */}
      {financialDetailsElement(`Anticipated Additional Investment:`, currentCompany.financialExpectations.anticipatedAdditionalInvestment.description)}


      {/* Anticipated Additional Investment */}
      {financialDetailsElement(`Year 1 Revenue Projection:`, currentCompany.financialExpectations.projectedYear1Revenues.description)}
      
      {/* Graph */}
      {width > positionChangeWidthSm && lineGraph5YearRevenue()}
      
    </Card>
  )
}

export default FinancialFactorsCard