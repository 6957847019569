/* eslint-disable no-prototype-builtins */
import React from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Chip from '@mui/material/Chip'

import { Input } from './globalHelpers'
import { handleChange } from './globalHelpers'
import { positionChangeHeightSm, positionChangeWidthSm } from './variableDefaults'



// Common Select Element (with Labels)
export const selectElement = (elementName, elementLabel, elementValue, disabled, focused, elementArrayOptions, changeHandler, form, setForm, index) => {
  // const upperCaseElementName = elementName.charAt(0).toUpperCase() + elementName.slice(1)
  return (
    <Box
      sx={{
        width: '90%',
      }}
    >
      <FormControl required fullWidth size="small" focused={focused}>
        <InputLabel id={`${elementName}-label`}>{elementLabel}</InputLabel>
        <Select
          labelId={`${elementName}-label`}
          id={`${elementName}`}
          disabled={disabled}
          name={`${elementName}`}
          value={elementValue}
          label={elementLabel}
          onChange={(e) => changeHandler(e, setForm, form, index)}
          className={elementValue ? `${elementName} text-${elementValue}` : `${elementName} text-` }
        >
          {elementArrayOptions.map(option => <MenuItem value={option} key={option} >{option}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  )
}

// Common Select Element (with Labels) — Uses Global handleChange
export const selectElementHandleChange = (elementName, elementLabel, elementValue, disabled, focused, size, elementArrayOptions, form, setForm, setErrors) => {
  // const upperCaseElementName = elementName.charAt(0).toUpperCase() + elementName.slice(1)
  return (
    <Box
      sx={{
        width: '100%', maxWidth: positionChangeWidthSm,
      }}
    >
      <FormControl required fullWidth autoFocus={focused} size={size} >
        <InputLabel id={`${elementName}-label`}>{elementLabel}</InputLabel>
        <Select
          labelId={`${elementName}-label`}
          id={`${elementName}`}
          disabled={disabled}
          name={`${elementName}`}
          value={elementValue}
          size={size}
          label={elementLabel}
          onChange={(e) => handleChange(e, setErrors, setForm, form)}
          className={elementValue ? `${elementName} text-${elementValue}` : `${elementName} text-` }
          sx={{
            backgroundColor: 'white',
          }}
        >
          {elementArrayOptions.map(option => <MenuItem value={option} key={option} >{option}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  )
}

// Security Questions (custom disabled)
export const selectSecurityQuestion = (name, label, disabled, value, item, changeHandler, form, setForm, questionIndex) => {
  return (
    <FormControl required fullWidth>
      <InputLabel 
        id={`${name}-label`} 
        sx={{ 
          color: disabled ? 'gray' : 'black', 
        }}
      >
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        autoFocus={false}
        size='medium'
        id={name}
        name={name}
        label={label}
        value={value}
        onChange={(e) => changeHandler(e, questionIndex, form, setForm)}
        sx={{
          // width: '100%',
          backgroundColor: 'white',
          textAlign: 'left',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {item}
      </Select>
    </FormControl>
  )
}

// Immediately Set State
export const selectSortCategory = (name, label, disabled, value, sortByArray, setState, filtersObj, setFiltersObj, filteredSimulations, setFilteredSimulations, activePage, setActivePage) => {
  
  const handleSelectChange = (e) => {
    e.preventDefault()
    const value = e.target.value
    
    if ((value === 'Percentile ⬇' || value === 'Percentile ⬆') && filtersObj.statusOfSimulation !== 'Finished With Results') {
      // console.log('this runs')
      setFilteredSimulations(filteredSimulations.filter(simulation => simulation.hasOwnProperty('results') && simulation.results !== null && simulation.results.importantNumbers.decisionQualityPercentile !== 'NA'))
      // setActivePage(1)
      setFiltersObj({ ...filtersObj, statusOfSimulation: 'Finished With Results' })
    }
    
    setActivePage(1)
    setState(e.target.value)
  }
  
  return (
    <FormControl sx={{ mt: 1 }}>
      <InputLabel 
        id={`${name}-label`} 
        sx={{ 
          color: disabled ? 'gray' : 'black', 
        }}
      >
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        autoFocus={false}
        size='small'
        id={name}
        name={name}
        label={label}
        value={value}
        onChange={(e) => handleSelectChange(e)}
        sx={{
          // width: '100%',
          textAlign: 'left',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {sortByArray.map(option => <MenuItem value={option} key={option}>{option}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

// Used in filters to Select multiple 'Allotted To' names for items to filter by
export const selectMultiple = (name, label, disabled, value, selectOptionsArray, changeHandler, form, setForm, setErrors) => {
  return (
    <FormControl fullWidth size='medium'>
      <InputLabel id={name}>
        {label}
      </InputLabel>
      <Select
        labelId={name}
        label={label}
        id={name}
        disabled={disabled}
        autoFocus={true}
        multiple
        name={name}
        value={value}
        onChange={(e) => changeHandler(e, setErrors, setForm, form)}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
      >
        {selectOptionsArray.map((option) => (
          <MenuItem
            key={option}
            value={option}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
