/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams, Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import blackDottedSpinnerGIF from '../../../../assets/dotted-black-trans-spinner-200.gif'
import turquoiseDottedSpinnerGIF from '../../../../assets/dotted-turquoise-trans-spinner-200.gif'
import whiteDottedSpinnerGIF from '../../../../assets/dotted-white-trans-spinner-200.gif'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { navbarHeight, footerHeight } from '../../../../helpers/variableDefaults'
import { standardButton } from '../../../../helpers/buttons'
import { descriptionTextField } from '../../../../helpers/textfields'
import { standardErrorContainer } from '../../../../helpers/errors'
import { standardSpinner } from '../../../../helpers/spinners'
import useWindowDimensions from '../../../../helpers/windowDimensions'

import { seoPageTags, customAnalyticsEvent } from '../../../../helpers/analytics'

// VC Sim Submitted
const Submitted = (props) => {

  // Window Dimensions
  const { height, width } = useWindowDimensions()

  const { vcSimulation, setVCSimulation, setHasResults, loading, setLoading } = props

  const [preSimulationReflections, setPreSimulationReflections] = useState({
    initialInvestmentStrategy: '',
    followOnInvestmentStrategy: '',
  })
  const [postSimulationReflections, setPostSimulationReflections] = useState({
    initialInvestmentStrategyAdherence: '',
    followOnStrategyAdherence: '',
    otherReflections: '',
  })

  // const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(false)

  const handleChangeReflections = (e, setReflections, reflections) => {
    const name = e.target.name 
    const value = e.target.value 

    setReflections({ ...reflections, [name]: value })
  }

  const handleSubmitReflections = async () => {
    setLoading(true)
    setErrors(false)

    try {
      const objectToUpload = 
        !vcSimulation.testIsFinished ?
          {
            testTakerReflections: { 
              ...vcSimulation.testTakerReflections, 
              ...preSimulationReflections, 
            },
            testTakerInititialReflectionsSubmitted: true,
          }
          :
          {
            testTakerReflections: { 
              ...vcSimulation.testTakerReflections, 
              ...postSimulationReflections, 
            },
            testTakerReflectionsSubmitted: true,
          }
      // console.log('objectToUpload ->', objectToUpload)

      const { data } = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/vc-simulations/${vcSimulation._id}`, objectToUpload)
      // console.log('data ->', data)

      if (data.results !== null && data.results !== undefined) {
        setHasResults(true)
      }

      setVCSimulation(data)

      setLoading(false)
      setErrors(false)

    } catch (error) {
      setLoading(false)
      setErrors(true)
    }
  }

  const initialStrategyTypography = (title, description) => {
    return (
      <Box
        sx={{
          p: 2,
          width: '90%',
          fontSize: '18px',
          backgroundColor: 'lightyellow',
          border: 'solid 2px black',
          fontWeight: 'normal',
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '18px',
          }}
        >
          {title}
        </Typography>

        {description !== '' &&
          <Typography
            sx={{
              fontStyle: 'italic',
              fontSize: '18px',
            }}
          >
            {description}
          </Typography>
        }
      </Box>
    )
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        !vcSimulation.testIsFinished ? 'Reflections — Pre Simulation' : 'Reflections — Post Simulation'
      )}

      {/* Body */}
      <Box 
        sx={{ 
          // height: `calc(100vh - ${navbarHeight} - ${footerHeight})`, 
          height: '100vh',
          width: '100vw', 
          py: 5,
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            WebkitAppearance: 'none',
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: 'rgba(0, 0, 0, .35)',
            webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
          },
          display: 'flex', flexDirection: 'column', justifyContent: (height < 550 || vcSimulation.testIsFinished) ? 'flex-start' : 'center', alignItems: 'center', 
        }}
      >
        {/* Pre Simulation Reflections */}
        {!vcSimulation.testIsFinished &&
          <>
            {/* Simulation Completed Title */}
            <Typography 
              sx={{
                width: '100%',
                fontWeight: 'bold', fontSize: '20px',
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
              }}
            >
              VC Simulation Strategy
            </Typography>

            <Box sx={{ mt: 1, mb: 2, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                
              {/* Initial Investment Strategy */}
              {descriptionTextField(
                'initialInvestmentStrategy', 
                preSimulationReflections.initialInvestmentStrategy, 
                'Initial Investment Strategy',
                5, 
                'What is your strategy for initial investments?', 
                handleChangeReflections,
                setPreSimulationReflections,
                preSimulationReflections,
                2
              )}

              {/* Follow-On Investment Strategy Adherence */}
              {descriptionTextField(
                'followOnInvestmentStrategy', 
                preSimulationReflections.followOnInvestmentStrategy, 
                'Follow-On Investment Strategy',
                5, 
                'What is your strategy for follow-on investments? (optional)', 
                handleChangeReflections,
                setPreSimulationReflections,
                preSimulationReflections,
                4
              )}

            </Box>

            {/* Submit Button */}
            <Box
              sx={{
                width: '100%',
                height: '80px',
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
              }}
            >
              {standardButton(
                'Start Test', 
                'button',
                'contained',
                false,
                'secondary', 
                2,
                0,
                0,
                '180px', 
                '45px', 
                handleSubmitReflections
              )}
            </Box>
            

            {errors && 
              standardErrorContainer('There was an error uploading your answers. Please check your Internet connection', 0, 1)
            }
          </>
        }

        {/* Post Simulation Reflections */}
        {vcSimulation.testIsFinished &&
          <>
            {/* Simulation Completed Title */}
            <Typography 
              sx={{
                width: '100%',
                fontWeight: 'bold', fontSize: '20px',
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
              }}
            >
              VC Simulation Completed
            </Typography>

            <Box sx={{ mt: 1, mb: 2, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                
              {/* Initial Investment Strategy */}
              <Box
                sx={{
                  py: 2, mt: 2,
                  backgroundColor: 'whitesmoke',
                  boxShadow: 3, borderRadius: 3,
                  width: '100%',
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >
                {/* Initial Investment Strategy */}
                {initialStrategyTypography(
                  'Initial Investment Strategy:', 
                  vcSimulation.testTakerReflections.initialInvestmentStrategy === '' ? 'No Strategy Given' : vcSimulation.testTakerReflections.initialInvestmentStrategy
                )}
                
                {/* Initial Investment Strategy Adherence */}
                {descriptionTextField(
                  'initialInvestmentStrategyAdherence', 
                  postSimulationReflections.initialInvestmentStrategyAdherence, 
                  'Initial Investment Strategy Adherence',
                  5, 
                  'Did you adhere to your strategy for initial investments? If not, how did your strategy evolve? (optional)', 
                  handleChangeReflections,
                  setPostSimulationReflections,
                  postSimulationReflections,
                  2
                )}
              </Box>

              {/* Follow-On Strategy */}
              <Box
                sx={{
                  py: 2, mt: 2,
                  backgroundColor: 'whitesmoke',
                  boxShadow: 3, borderRadius: 3,
                  width: '100%',
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >
                {/* Initial Investment Strategy */}
                {initialStrategyTypography(
                  'Follow-On Strategy:', 
                  vcSimulation.testTakerReflections.followOnInvestmentStrategy === '' ? 'No Strategy Given' : vcSimulation.testTakerReflections.followOnInvestmentStrategy
                )}
                
                {/* Follow-On Investment Strategy Adherence */}
                {descriptionTextField(
                  'followOnStrategyAdherence', 
                  postSimulationReflections.followOnStrategyAdherence, 
                  'Follow-On Investment Strategy Adherence',
                  5, 
                  'Did you adhere to your strategy for follow-on investments? If not, how did your strategy evolve? (optional)', 
                  handleChangeReflections,
                  setPostSimulationReflections,
                  postSimulationReflections,
                  2
                )}
              </Box>
              
              {/* Other Reflections */}
              <Box
                sx={{
                  py: 2, mt: 2,
                  backgroundColor: 'whitesmoke',
                  boxShadow: 3, borderRadius: 3,
                  width: '100%',
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >
                {initialStrategyTypography(
                  'Other Reflections', 
                  ''
                )}
                {descriptionTextField(
                  'otherReflections', 
                  postSimulationReflections.otherReflections, 
                  'Other Reflections',
                  5, 
                  'Do you have any other reflections? (optional)', 
                  handleChangeReflections,
                  setPostSimulationReflections,
                  postSimulationReflections,
                  2
                )}
              </Box>

            </Box>

            {/* Submit Button */}
            <Box
              sx={{
                width: '100%',
                height: '80px',
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
              }}
            >
              {standardButton(
                'View Results', 
                'button',
                'contained',
                false,
                'secondary', 
                2,
                0,
                0,
                '180px', 
                '45px', 
                handleSubmitReflections
              )}
            </Box>
            

            {errors && 
              standardErrorContainer('There was an error uploading your answers. Please check your Internet connection', 0, 1)
            }
          </>
        }

      </Box>
    </>
  )
}

export default Submitted