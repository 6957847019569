/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'

import useWindowDimensions from '../../../helpers/windowDimensions'
import { securityQuestionAndAnswerBoxItems } from '../../../helpers/securityQuestionsAndAnswers'
import { validEmail, handleChange, Input, reloadView } from '../../../helpers/globalHelpers'
import { positionChangeWidthSm, profPicDefaultURL, positionChangeWidthMd, positionChangeHeightSm, navbarHeight, footerHeight, dashboardNavbarHeight, bottomSteps, onboardingStartingPeopleArray } from '../../../helpers/variableDefaults.js'
import { userIsAuthenticated, getPayload, getTokenFromLocalStorage, setProfPicToLocalStorage } from '../../../helpers/storage.js'
import { standardButton, replaceImageWithIconButton } from '../../../helpers/buttons.js'
import { standardErrorContainer } from '../../../helpers/errors.js'
import { standardSpinner } from '../../../helpers/spinners.js'
import { loginTextField } from '../../../helpers/textfields'
import { handleImageSelect } from '../../../helpers/profilePictureElements'

import { userProfilieSectionTitle, titleAndEditButton, personalDetailsTextFieldOrTypography, personalDetailsTypography, userProfileEstateElement } from '../../../helpers/settingsElements'

import { seoPageTags, customAnalyticsEvent } from '../../../helpers/analytics'

// MyAccount Page
const MyAccount = () => {
  // Window Dimensions
  const { height, width } = useWindowDimensions()
  
  // Navigate
  const navigate = useNavigate()

  // Params
  const { userId } = useParams()

  // Payload
  const payload = getPayload()

  // User
  const [user, setUser] = useState({})
  const [previousUser, setPreviousUser] = useState({})
  const [previousImage, setPreviousImage] = useState('') //For Deleting Previous Profile Picture

  // Edit Modes
  const [editPersonalDetails, setEditPersonalDetails] = useState(false)
  const [editSecurityQuestions, setEditSecurityQuestions] = useState(false)

  // Loading
  const [loading, setLoading] = useState(false)
  const [loadingProfPic, setLoadingProfPic] = useState(false)
  const [loadingPersonalDetailsEdits, setLoadingPersonalDetailsEdits] = useState(false)
  const [loadingSecurityQuestionsEdits, setLoadingSecurityQuestionsEdits] = useState(false)

  // Errors
  const [uploadProfilePictureErrors, setUploadProfilePictureErrors] = useState(false)
  const [uploadPersonalEditsErrors, setUploadPersonalEditsErrors] = useState(false)
  const [uploadSecurityQuestionsErrors, setUpploadSecurityQuestionsErrors] = useState(false)

  const resetErrors = () => {
    setUploadProfilePictureErrors(false)
    setUploadPersonalEditsErrors(false)
    setUpploadSecurityQuestionsErrors(false)
  }

  const resetLoading = () => {
    setLoadingProfPic(false)
    setLoadingPersonalDetailsEdits(false)
    setLoadingSecurityQuestionsEdits(false)
  }

  // Do this so that the answers to the frontend never touch the frontend and are not saved as part of the User state
  const getUserSecurityQuestions = async () => {
    
    const { data: retrievedQuestions } =  await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/users/${userId}/security-questions`, {
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    })
    // console.log('retrievedQuestions ->', retrievedQuestions)

    return retrievedQuestions
  }

  // UseEffect — Get User data and set estates state
  useEffect(() => {
    // console.log('my account use effect runs')

    if (!userIsAuthenticated() || !userId) {
      navigate(`/login`)
    } else if (userId !== payload.sub) {
      navigate(`/dashboard/${payload.sub}`)
      // reloadView()
    } else {
      const fetchData = async () => {
        setLoading(true)
        // console.log('userId ->', userId)
        try {
          const { data: retrievedUser } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/users/${payload.sub}/not-populated`, {
            headers: {
              Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            },
          })

          // console.log('retrievedUser ->', retrievedUser)

          if (retrievedUser._id === payload.sub) {
            
            // Do this so that the answers to the security questions never touch the frontend and are not saved as part of the User state
            retrievedUser.securityQuestions = await getUserSecurityQuestions() 
            
            // Set User State
            setUser(retrievedUser)
            setPreviousUser(retrievedUser)
            setPreviousImage(retrievedUser.profilePicture)

            setLoading(false)

          } else {
            useNavigate('/')
            setLoading(false)
          }

        } catch (err) {
          // console.log(err)

          useNavigate('/')
          setLoading(false)
        }
      }
  
      fetchData()
    }
  }, [])

  // Upload modifications in real time to server
  const uploadModifiedUser = async (modifiedPropertiesObj) => {
    // console.log('uploadModifiedUser runs')
    
    try {

      // PUT request with updated profile object
      // await axios.put(`/api/users/${userId}`, newForm, {
      await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/users/${userId}`, modifiedPropertiesObj, {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      })

      // update local storage
      if (modifiedPropertiesObj.hasOwnProperty('profilePicture')) {
        window.localStorage.removeItem('profPic')
        setProfPicToLocalStorage(modifiedPropertiesObj.profilePicture)
      }
      
    } catch (error) {
      // console.log(error)

      // error message posting new profile
      resetLoading()

      if (modifiedPropertiesObj.hasOwnProperty('profilePicture')) {
        setUploadProfilePictureErrors(true)
      } else if (modifiedPropertiesObj.hasOwnProperty('entityName')) {
        setUploadPersonalEditsErrors(true)
      } else if (modifiedPropertiesObj.hasOwnProperty('securityQuestions')) {
        setUpploadSecurityQuestionsErrors(true)
      }
      
    }

  }

  const resetPasswordPressed = () => {
    // console.log('resetPasswordPressed')
    resetErrors()
    navigate('/reset-password')
  }

  const editPersonalDetailsPressed = () => {
    // console.log('editPersonalDetailsPressed')

    setUser(previousUser)
    
    resetErrors()
    setEditSecurityQuestions(false)

    setEditPersonalDetails(!editPersonalDetails)
  }

  const removeSecurityAnswersFromUser = async () => {
    const userObj = { ...user }
    const userQuestions = await userObj.securityQuestions.map(question => {
      question.a = ''
      return question
    })
    userObj.securityQuestions = userQuestions
    setUser(userObj)
  }

  const editSecurityQuestionsPressed = async () => {
    // console.log('editSecurityQuestionsPressed')
    
    if (editSecurityQuestions) {
      await removeSecurityAnswersFromUser()
    }

    resetErrors()
    setEditPersonalDetails(false)

    setEditSecurityQuestions(!editSecurityQuestions)
  }

  const savePersonalDetailsPressed = async () => {
    // console.log('savePersonalDetailsPressed')

    setLoadingPersonalDetailsEdits(true)

    // Modify User Object to Backend
    const modifiedPropertiesObj = { 
      entityName: user.entityName.trim(),
      entityType: user.entityType,
      email: user.email,
    }
    await uploadModifiedUser(modifiedPropertiesObj)

    setPreviousUser(user)

    setLoadingPersonalDetailsEdits(false)
    setEditPersonalDetails(false)
  }

  const saveSecurityQuestionsPressed = async () => {
    // console.log('saveSecurityQuestionsPressed')

    setLoadingSecurityQuestionsEdits(true)

    // Modify User Object to Backend
    const modifiedPropertiesObj = { 
      securityQuestions: user.securityQuestions,
    }
    await uploadModifiedUser(modifiedPropertiesObj)

    await removeSecurityAnswersFromUser()
    setEditSecurityQuestions(false)
    setLoadingSecurityQuestionsEdits(false)

  }

  const handleManageSubscriptionPressed = async () => {
    // e.preventDefault()
    console.log('handleManageSubscriptionPressed runs')

    try {
      setLoading(true)

      const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/customers/${payload.sub}`, {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      })
      // console.log('data ->', data)

      window.location.href = data.url

      // setLoading(false)

    } catch (error) {
      // console.log('error connecting to customer portal ->', error)

      setLoading(false)
    }
  }


  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'My Account'
      )}

      {/* Body */}

      {loading ?
        <Box sx={{ 
          width: '100%',
          // minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight} - ${dashboardNavbarHeight})`,
          minHeight: `calc(100vh - ${navbarHeight} - ${dashboardNavbarHeight})`,
          mt: 0, mb: 0,
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
        }}>
          {standardSpinner()}
        </Box>
        : 
        <Box 
          sx={{ 
            width: '100%',
            minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight} - ${dashboardNavbarHeight})`,
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
          }}
        >
          <Box sx={{ mt: 4, mb: 4, width: '100%', maxWidth: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

            {/* This must be first input So that the file upload only fires when you press the button */}
            <Box sx={{ width: '100%' }}>
              <Input type="text" autoFocus="autoFocus" />
            </Box>

            {/* Profile Picture */}
            {replaceImageWithIconButton(
              user.profilePicture, 
              previousImage,
              setPreviousImage,
              width > 399 ? 200 : width > 299 ? 200 : 175, 
              'icon-button-file', 
              'replace-prof-pic', 
              handleImageSelect,
              -1,
              loadingProfPic, 
              setLoadingProfPic, 
              resetErrors, 
              user._id, 
              uploadModifiedUser, 
              user, 
              setUser, 
              'profilePicture', 
              uploadProfilePictureErrors
            )}

            {/* Error Message, if errors */}
            {uploadProfilePictureErrors &&
              standardErrorContainer(
                'Error uploading image. Please try again.',
                0,
                0
              )
            }

            {/* Personal Details (First Name, Last Name, Email, Password) */}
            <Box sx={{ mt: width < positionChangeWidthSm ? 2 : 0, mb: 0, width: '90%', maxWidth: '450px' }}>
              {/* Personal Details — Title and Edit/Save/Cancel Buttons */}
              {titleAndEditButton('Personal Details', editPersonalDetails, user, savePersonalDetailsPressed, editPersonalDetailsPressed )}
              
              {/* Error Message, if errors */}
              {uploadPersonalEditsErrors &&
                standardErrorContainer(
                  'Error uploading changes. Please try again.',
                  0,
                  0
                )
              }
              <Box 
                sx={{
                  backgroundColor: editPersonalDetails ? 'white' : 'whitesmoke',
                  mt: 1,
                  pl: 3, pr: 3, pt: 1, pb: 1,
                  borderRadius: '5px',
                  boxShadow: 3,
                  border: editPersonalDetails ? 0 : 2,
                  borderColor: 'black',
                  display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
              >
                {loadingPersonalDetailsEdits ?
                  standardSpinner()
                  :
                  <>
                    
                    {/* First Name */}
                    {personalDetailsTextFieldOrTypography('entityName', 'Name', user, setUser, editPersonalDetails, setUploadPersonalEditsErrors)}
                    
                    {/* Entity Type */}
                    {personalDetailsTextFieldOrTypography('entityType', 'Type', user, setUser, editPersonalDetails, setUploadPersonalEditsErrors)}
                    
                    {/* Email */}
                    {personalDetailsTextFieldOrTypography('email', 'Email', user, setUser, editPersonalDetails, setUploadPersonalEditsErrors)}
                    
                    {/* Password and Reset Password Button */}
                    {!editPersonalDetails &&
                      <Box 
                        sx={{
                          mt: editPersonalDetails ? 2 : 0,
                          width: '100%',
                          display: 'flex', flexDirection: 'row', justifyContent: 'center',
                        }}
                      >
                        {personalDetailsTypography('Password')}
                        
                        <Box textAlign={'right'} sx={{ width: '50%' }}>
                          {standardButton(
                            'Reset Password', 
                            'button',
                            'text',
                            !user.subscriptionIsActive,
                            'secondary', 
                            0,
                            0,
                            0,
                            '137px',
                            '30px',
                            resetPasswordPressed
                          )}
                        </Box>
                      </Box>
                    }
                    
                  </>
                }
                
              </Box>
            </Box>

            {/* Security Questions Title and Edit Security Questions Button */}
            <Box sx={{ mt: width < positionChangeWidthSm ? 2 : 4, mb: 0, pb: 0, width: '90%', maxWidth: '450px' }}>
              {/* Security Questions — Title and Edit/Save Button */}
              {titleAndEditButton('Security Questions', editSecurityQuestions, user, saveSecurityQuestionsPressed, editSecurityQuestionsPressed )}
              
              {/* Error Message, if errors */}
              {uploadSecurityQuestionsErrors &&
                standardErrorContainer(
                  'Error uploading changes. Please try again.',
                  0,
                  0
                )
              }
              <Box 
                sx={{
                  backgroundColor: editSecurityQuestions ? 'white' : 'whitesmoke',
                  mt: 1, mb: user.entityType !== 'Individual' ? 0 : 4,
                  pl: 3, pr: 3, pt: 1, pb: 1,
                  borderRadius: '5px',
                  boxShadow: 3,
                  border: editSecurityQuestions ? 0 : 2,
                  borderColor: 'black',
                  display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
              >
                {loadingSecurityQuestionsEdits ?
                  standardSpinner()
                  :
                  <>

                    {/* Security Questions and Answers */}
                    {securityQuestionAndAnswerBoxItems(user, setUser, !editSecurityQuestions)}
                    
                  </>
                }
                
              </Box>
            </Box>

            {/* Manage Subscription */}
            {user.entityType !== 'Individual' &&
              <Box sx={{ mt: width < positionChangeWidthSm ? 2 : 4, mb: 0, pb: 4, width: '90%', maxWidth: '450px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                  {userProfilieSectionTitle('Subscription')}
                </Box>
                <Box 
                  sx={{
                    backgroundColor: 'whitesmoke',
                    mt: 1,
                    pl: 3, pr: 3, pt: 1, pb: 1,
                    borderRadius: '5px',
                    boxShadow: 3,
                    border: 2,
                    borderColor: 'black',
                    display: 'flex', flexDirection: 'column', alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {standardButton(
                      'Manage Subscription', 
                      'button',
                      'contained',
                      false,
                      'primary', 
                      2,
                      2,
                      0,
                      '225px', 
                      '45px', 
                      handleManageSubscriptionPressed
                    )}
                  </Box>
                  
                </Box>
              </Box>
            }

          </Box>
        </Box>
      }
    </>
  )
}

export default MyAccount