/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Routes, Route } from 'react-router-dom' //Router components

// Import Components
import ScrollToTop from './components/common/ScrollToTop'
import NotFound from './components/common/NotFound'
import Layout from './components/common/Layout'
import FAQ from './components/homepage/FAQ'
import Home from './components/homepage/Home'
import AllAuth from './components/auth/AllAuth'
import TermsOfService from './components/auth/TermsOfService'
import PrivacyPolicy from './components/auth/PrivacyPolicy'
import ResetPassword from './components/auth/ResetPassword'
import CreateAccount from './components/onboarding/CreateAccount.js'
import Dashboard from './components/user/Dashboard.js'
import VCSimulation from './components/simulations/vc/VCSimulation.js'
import VCDemo from './components/simulations/vc/VCDemo.js'

import { permittedCountryCodes, oneTimeRate } from './helpers/variableDefaults'

//MUI
import getDesignTokens from './helpers/theme.js' //MUI theming presets
import { createTheme, ThemeProvider } from '@mui/material/styles' //MUI theme creator functions/components
import Box from '@mui/material/Box'

// Stripe
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY)


const App = () => {
  // Helmet Context
  const helmetContext = {}

  // // Geolocation
  // const [countryCode, setCountryCode] = useState('')
  // const getData = async () => {
  //   axios.get('https://ipapi.co/json/').then((response) => {
  //     // const data = response.data
  //     // console.log('countryCode ->', response.data.country_code)
  //     setCountryCode(
  //       response.hasOwnProperty('data') && 
  //       response.data.hasOwnProperty('country_code') && 
  //       response.data.country_code.length === 2 
  //         ? 
  //         response.data.country_code 
  //         : 
  //         ''
  //     )
  //   }).catch((error) => {
  //     console.log(error)
  //   })
  // }

  // useEffect(() => {
  //   //passing getData method to the lifecycle method
  //   getData()
  // }, [])

  // Stripe Options
  const [registerForm, setRegisterForm] = useState({
    amount: oneTimeRate * 100,
    currency: 'usd',
    mode: 'payment',
  })

  return (
    // HelmetProvider must go around everything in order to use dynamic helmets
    <HelmetProvider context={helmetContext}>
      
      {/* Wrap everything in the theme so that these presets cascade down */}
      <ThemeProvider theme={createTheme(getDesignTokens())}>

        {/* The 'wrapper-box' id is referenced in main.scss for global variables that cascade down */}
        <Box id='wrapper-box' bgcolor='background.default' color='text.primary'>
          
          {/* All the routes must be set inside the Browser Router */}
          <BrowserRouter>

            {/* Scroll to top automatically every time route changes */}
            <ScrollToTop />

            {/* Stripe Elements */}
            <Elements options={registerForm} stripe={stripePromise}>
                
              {/* The Website */}
              <Routes>

                {/* No Navbar Routes */}

                {/* VC Simulation */}
                <Route path="/vc-simulation/:vcSimulationId" element={<VCSimulation />} />
                <Route path="/demo/" element={<VCDemo />} />

                {/* Navbar In "Layout"; Navbar only appears for routes that are children of this Route */}
                <Route element={<Layout />}> 

                  {/* Homepage */}
                  <Route path="/" element={<Home />} />

                  {/* FAQ route */}
                  <Route path="/faq" element={<FAQ fromComponent='faq' />} />

                  {/* Auth routes */}
                  <Route path="/login" element={<AllAuth fromCreateAccount={false} registerForm={registerForm} setRegisterForm={setRegisterForm} />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/reset-password/:userId/:token" element={<ResetPassword />} />
                  <Route path="/tos" element={<TermsOfService />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />

                  {/* Create Account */}
                  <Route path="/create-account" element={<CreateAccount registerForm={registerForm} setRegisterForm={setRegisterForm} />} />

                  {/* User */}
                  <Route path="/dashboard/:userId" element={<Dashboard />} />

                  {/* Not Found; this path matches any path specified, so it needs to come last */}
                  <Route path="*" element={<NotFound />} />

                </Route>

              </Routes>

            </Elements>
                
          </BrowserRouter>
        </Box>
      </ThemeProvider>
    </HelmetProvider>
  )
}

export default App
