/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Divider from '@mui/material/Divider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import PortfolioItemCard from './PortfolioItemCard'

import { navbarHeight, footerHeight, positionChangeWidthSm, positionChangeWidthLg, positionChangeWidthMd } from '../../../../helpers/variableDefaults'
import { standardSpinner } from '../../../../helpers/spinners'
import { couldNotLoadPageError } from '../../../../helpers/errors'
import { makeTabIndex } from '../../../../helpers/formatting'
import { TabPanel } from '../../../../helpers/formatting'
import { standardInfoTooltip } from '../../../../helpers/tooltips'
import { getCompanyOfInvestmentSelection } from '../../../../helpers/getNestedElements'

const portfolioItem = (investmentNumber, name, decision, month, followOnYes, amountInvested, amountReturned, exitYear, decisionQuality, momentumAtFollowOn, result, initialInvestmentReason, followOnInvestmentReason, poorPacing) => {
  return (
    <Box
      sx={{
        // backgroundColor: name === 'Name' ? 'lightblue' : 'lightyellow',
        // width: '100%',
        // mb: name === 'Name' ? 0 : 1, pb: name === 'Name' ? 0 : 1,
        // borderBottom: name === 'Name' ? '' : '1px solid black',
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
      }}
    >
      {/* Row Items */}
      <Box
        sx={{
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-end',
        }}
      >
        {/* Investment Number */}
        <Box
          sx={{
            width: '20px',
            fontSize: investmentNumber === 'Investment Number' ? '18px' : '18px',
            fontWeight: investmentNumber === 'Investment Number' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {investmentNumber === 'Investment Number' ? '' : investmentNumber}
        </Box>


        {/* Company Name */}
        <Box
          sx={{
            pl: name === 'Name' ? 0 : 2,
            width: '120px',
            fontSize: name === 'Name' ? '18px' : '18px',
            fontWeight: name === 'Name' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: name === 'Name' ? 'center' : 'flex-start', alignItems: 'center',
          }}
        >
          {name}
        </Box>


        {/* Decision Number */}
        <Box
          sx={{
            width: '80px',
            fontSize: decision === 'Decision' ? '18px' : '18px',
            fontWeight: decision === 'Decision' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: decision === 'Decision' ? 'center' : 'center', alignItems: 'center',
          }}
        >
          {decision}
        </Box>


        {/* Month */}
        <Box
          sx={{
            width: '80px',
            fontSize: month === 'Month' ? '18px' : '18px',
            fontWeight: month === 'Month' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {month}
        </Box>


        {/* Follow-On */}
        <Box
          sx={{
            width: '120px',
            fontSize: followOnYes === 'Follow-On' ? '18px' : '18px',
            fontWeight: followOnYes === 'Follow-On' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {followOnYes === 'Follow-On' ? followOnYes : followOnYes ? 'Yes' : 'No'}
        </Box>


        {/* Amount Invested */}
        <Box
          sx={{
            width: '120px',
            fontSize: amountInvested === 'Invested' ? '18px' : '18px',
            fontWeight: amountInvested === 'Invested' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {amountInvested === 'Invested' ? `${amountInvested}` : `$${amountInvested}M`}
        </Box>


        {/* Amount Returned */}
        <Box
          sx={{
            width: '140px',
            fontSize: amountReturned === 'Returned' ? '18px' : '18px',
            fontWeight: amountReturned === 'Returned' ? 'bold' : 'normal',
            color: poorPacing && amountReturned !== 'Returned' ? 'red' : '',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {amountReturned === 'Returned' ? `${amountReturned}` : `$${parseFloat(amountReturned).toFixed(2)}M`}
        </Box>


        {/* Exit Year */}
        <Box
          sx={{
            width: '80px',
            fontSize: exitYear === 'Exit Year' ? '18px' : '18px',
            fontWeight: exitYear === 'Exit Year' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {exitYear}
        </Box>


        {/* Decision Quality */}
        <Box
          sx={{
            width: '140px',
            fontSize: decisionQuality === 'Decision Quality' ? '18px' : '18px',
            fontWeight: decisionQuality === 'Decision Quality' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {decisionQuality === 'Decision Quality' ? 'Quality' : decisionQuality.includes(' ') ? decisionQuality.substring(0, decisionQuality.indexOf(' ')) : decisionQuality}
        </Box>


        {/* Momentum at Follow-On */}
        <Box
          sx={{
            width: '120px',
            fontSize: momentumAtFollowOn === 'Momentum at Follow-On' ? '18px' : '18px',
            fontWeight: momentumAtFollowOn === 'Momentum at Follow-On' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {momentumAtFollowOn.includes(' ') ? momentumAtFollowOn.substring(0, momentumAtFollowOn.indexOf(' ')) : momentumAtFollowOn}
        </Box>


        {/* Result */}
        <Box
          sx={{
            width: '120px',
            fontSize: result === 'Result' ? '18px' : '18px',
            fontWeight: result === 'Result' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {result.includes(' ') ? result.substring(0, result.indexOf(' ')) : result}
        </Box>


      </Box>

      {/* Initial Investment Reason */}
      {initialInvestmentReason !== 'Initial Investment Reason' &&
        <Box
          sx={{
            // width: '80px',
            ml: 6, mt: 1,
            fontSize: '18px',
            fontWeight: 'normal',
            // fontStyle: 'italic',
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          {ReactHtmlParser(`Initial Investment Reason: <span style="font-style:italic; margin-left:5px;">'${initialInvestmentReason}'</span>`)}
        </Box>
      }

      {/* Follow-On Investment Reason */}
      {followOnInvestmentReason !== 'Follow-On Decision Reason' &&
        <Box
          sx={{
            // width: '80px',
            ml: 6, mt: 0.5, mb: 1,
            fontSize: '18px',
            fontWeight: 'normal',
            // fontStyle: 'italic',
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          {ReactHtmlParser(`Follow-On Decision Reason: <span style="font-style:italic; margin-left:5px;">'${followOnInvestmentReason}'</span>`)}
        </Box>
      }

    </Box>
  )
}

export const vcResultsPortfolioTabJSX = (value, index, results, vcSimulation, rules, loading, width) => {
  return (

    <TabPanel value={value} index={index}>
      {loading ?
        standardSpinner()
        : 
        <Box sx={{ 
          // backgroundColor: 'yellow',
          mt: 0, mb: 6, px: 4,
          width: '100vw', maxWidth: '100vw',  
          overflowX: 'scroll',
          '&::-webkit-scrollbar': {
            WebkitAppearance: 'none',
            // width: '2px',
            height: '0px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: 'rgba(0, 0, 0, .35)',
            webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
          },
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: width > positionChangeWidthLg ? 'center' : 'flex-start', 
        }}>

          {/* Row Titles */}
          {width >= positionChangeWidthMd && results.hasOwnProperty('resultsSummary') && results.resultsSummary.length > 0 && 
            <Box
              sx={{
                // width: '100%',
                backgroundColor: 'lightblue',
                border: '2px solid black',
                mb: 0, pb: 1, pt: 1, pl: 1,
              }}
            >
              {portfolioItem('Investment Number', 'Name', 'Decision', 'Month', 'Follow-On', 'Invested', 'Returned', 'Exit Year', 'Decision Quality', 'Momentum at Follow-On', 'Result', 'Initial Investment Reason', 'Follow-On Decision Reason', 'Poor-Pacing')}
            </Box>
          }

          {/* Row Items */}
          {width >= positionChangeWidthMd && results.hasOwnProperty('resultsSummary') && results.resultsSummary.length > 0 && results.resultsSummary.map((resultSummary, resultSummaryIndex) =>(
            <Box
              key={resultSummaryIndex}
              sx={{
                // width: '100%',
                backgroundColor: 'lightyellow',
                // backgroundColor: 'lavenderblush',
                borderBottom: resultSummaryIndex + 1 === results.resultsSummary.length ? '2px solid black' : '1px solid black',
                borderLeft: '2px solid black', borderRight: '2px solid black',
                pt: 2, mb: 0, pb: 1, pl: 1,
              }}
            >
              {portfolioItem(
                resultSummaryIndex + 1, 
                resultSummary.companyName, 
                resultSummary.decisionNumber,
                resultSummary.initialInvestmentMonth,
                resultSummary.followOnYes,
                resultSummary.amountInvested,
                resultSummary.expectedDollars,
                resultSummary.exitYear ,
                resultSummary.initialInvestmentDecisionQuality,
                resultSummary.statusAtFollowOn,
                resultSummary.result,
                resultSummary.initialInvestmentReason,
                resultSummary.followOnDecisionReason,
                resultSummary.poorPacingPenalty 
              )}
            </Box>
          ))}

          {/* Portfolio Item Card */}
          {vcSimulation.hasOwnProperty('investmentSelections') && vcSimulation.investmentSelections.length > 0 && vcSimulation.investmentSelections.map((selection, selectionIndex) => (
            <Box
              key={selectionIndex}
              sx={{
                mt: selectionIndex === 0 ? 6 : 1,
                width: '100%',
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
              }}
            >
              {/* {console.log('currentCompany ->', getCompanyOfInvestmentSelection(selection.id, vcSimulation))} */}
              <PortfolioItemCard 
                vcSimulation={vcSimulation} 
                currentCompany={getCompanyOfInvestmentSelection(selection.id, vcSimulation)} 
                rules={rules} 
                decisionNumber={selectionIndex + 1} 
              />
            </Box>
          ))
          }
        </Box>
      }
    </TabPanel>
  )
}