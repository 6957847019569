/* eslint-disable no-prototype-builtins */
import React from 'react'
import { Helmet } from 'react-helmet-async'

// Change Header of a page for title, seo, and analytics purposes
export const seoPageTags = (title) => {
  // console.log('helmet title ->', title)
  return (
    <>
      <Helmet>

        { /* Standard metadata tags */ }
        {title === 'Home' ?
          <title>SimCap — Simulate Venture Capitalist Decision Making — {title}</title>
          :
          <title>SimCap — {title}</title>
        }
        <meta name='description' content={'Full Immersion. Detailed Insights. Better Investments.'} />

        {/* Referrer Policy */}
        <meta name="referrer" content="origin" />

        { /* Facebook tags */ }
        <meta property="og:type" content={'website'} />
        <meta property="og:title" content={`SimCap — Simulate Venture Capitalist Decision Making — ${title}`} />
        <meta property="og:description" content={'A software service for simulating venture capitalist decision making for both job-interviewing and educational environments.'} />

        {/* Yandex Verification */}
        {/* <meta name="yandex-verification" content="08a79e53272c53b3" /> */}

        { /* Twitter tags */ }
        <meta name="twitter:creator" content={'SimCap'} />
        <meta name="twitter:card" content={'website'} />
        <meta name="twitter:title" content={`SimCap — Simulate Venture Capitalist Decision Making — ${title}`} />
        <meta name="twitter:description" content={'A software service for simulating venture capitalist decision making for both job-interviewing and educational environments.'} />

        {/* Google Analytics — Must be at bottom of Helmet */}
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID}`}></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID}');
          `}
        </script>
      </Helmet>
    </>
  )
}

// Send a custom analytics event to Google Analytics
export const customAnalyticsEvent = (eventName, value, category, label, screenName) => {
  if (typeof window !== 'undefined' && window.hasOwnProperty('gtag')) {
    window.gtag('event', eventName, {
      value: value,
      event_category: category,
      event_label: label,
      screen_name: screenName,
    })
  }
}