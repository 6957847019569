/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */

import React from 'react'

// mui
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import { Link } from '@mui/material'

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'

import { standardButton } from './buttons'
import { positionChangeWidthSm } from './variableDefaults'

export const standardPagination = (activePage, setActivePage, itemsPerPage, pageRangeDisplayed, totalItemsCount, subscriptionIsActive, width) => {

  // console.log('pageRangeDisplayed ->', pageRangeDisplayed)
  const pageRadiusDisplayed = Math.floor(pageRangeDisplayed / 2)
  // console.log('pageRadiusDisplayed ->', pageRadiusDisplayed)
  const lastPage = Math.max(Math.ceil(totalItemsCount / itemsPerPage), 1)
  const numbersArray = []
  for (let i = activePage - pageRadiusDisplayed; i <= activePage + pageRadiusDisplayed; i++) {
    const numberToPush = i < 1 ? i + pageRangeDisplayed : i > lastPage ? i - pageRangeDisplayed : i
    if (numberToPush > 0 && numberToPush <= lastPage) {
      numbersArray.push(numberToPush)
    }
  }
  // console.log('numbersArray ->', numbersArray)
  const displayNumbersArray = numbersArray.sort((a,b) => a - b).filter(number => number <= lastPage)

  const handlePageChangeFirst = (e) => {
    e.preventDefault()
    // console.log('handlePageChangeFirst runs')
    setActivePage(1)
  }

  const handlePageChangePrevious = (e) => {
    e.preventDefault()
    // console.log('handlePageChangePrevious runs')
    setActivePage(activePage - 1)
  }

  const handlePageChangeNumber = (e) => {
    e.preventDefault()
    // console.log('handlePageChangeNumber runs')
    // console.log('value ->', parseInt(e.target.innerText))  
    setActivePage(parseInt(e.target.innerText))
  }

  const handlePageChangeNext = (e) => {
    e.preventDefault()
    // console.log('handlePageChangeNext runs')
    setActivePage(activePage + 1)
  }

  const handlePageChangeLast = (e) => {
    e.preventDefault()
    // console.log('handlePageChangeLast runs')
    setActivePage(lastPage)
  }

  return (
    <Box
      sx={{
        mt: 2,
        width: '100%',
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
      }}
    >

      {/* First */}
      <Box 
        className={`first-page`} 
        onClick={(e) => handlePageChangeFirst(e)}
        sx=
          {{ 
            borderRadius: '50%', display: 'flex', alignItems: 'center', 
          }} 
      >
        <IconButton 
          aria-label="view-results" 
          name={`first-page`}
          className={`first-page`} 
          disabled={activePage <= 2 || !subscriptionIsActive}
          sx={{ 
            width: '30px', height: '30px', 
            mb: 1, mt: 1, mr: 2, ml: 2, bottom: 0, left: 0, 
            // border: 2, borderColor: 'white', 
            boxShadow: 3, 
          }} 
        >
          <KeyboardDoubleArrowLeftIcon 
            fontSize="small" 
            color= {activePage <= 2 ? '' : 'primary'}
            sx={{  }} 
          />
        </IconButton>
      </Box>

      {/* Previous */}
      <Box 
        className={`first-page`} 
        onClick={(e) => handlePageChangePrevious(e)}
        sx=
          {{ 
            borderRadius: '50%', display: 'flex', alignItems: 'center', 
          }} 
      >
        <IconButton 
          aria-label="view-results" 
          name={`first-page`}
          className={`first-page`} 
          disabled={activePage === 1 || !subscriptionIsActive}
          sx={{ 
            width: '30px', height: '30px', 
            mb: 1, mt: 1, mr: 2, ml: 2, bottom: 0, left: 0, 
            // border: 2, borderColor: 'white', 
            boxShadow: 3, 
          }} 
        >
          <KeyboardArrowLeftIcon 
            fontSize="small" 
            color= {activePage === 1 ? '' : 'primary'}
            sx={{  }} 
          />
        </IconButton>
      </Box>

      {/* Numbers */}
      {width >= positionChangeWidthSm && displayNumbersArray.length > 0 && displayNumbersArray.map((number, numberIndex) => (
        <Button 
          key={numberIndex}
          disabled={!subscriptionIsActive}
          type={'button'}
          variant={number === activePage ? 'contained' : 'text'}
          color={'primary'}
          onClick={(e) => handlePageChangeNumber(e)}
          // size="small"
          sx={{ 
            mt: 1, 
            mb: 1, 
            mx: 1, 
            width: '30px',
            height: '30px',
            // borderRadius: '50%',
            color: number === activePage ? 'white' : '',
          }}
        >
          {number}
        </Button>
      ))}


      {/* Next */}
      <Box 
        className={`first-page`} 
        onClick={(e) => handlePageChangeNext(e)}
        sx=
          {{ 
            borderRadius: '50%', display: 'flex', alignItems: 'center', 
          }} 
      >
        <IconButton 
          aria-label="view-results" 
          name={`first-page`}
          className={`first-page`} 
          disabled={activePage === lastPage || !subscriptionIsActive}
          sx={{ 
            width: '30px', height: '30px', 
            mb: 1, mt: 1, mr: 2, ml: 2, bottom: 0, left: 0, 
            // border: 2, borderColor: 'white', 
            boxShadow: 3, 
          }} 
        >
          <KeyboardArrowRightIcon 
            fontSize="small" 
            color= {activePage === lastPage ? '' : 'primary'}
            sx={{  }} 
          />
        </IconButton>
      </Box>

      {/* Last */}
      <Box 
        className={`first-page`} 
        onClick={(e) => handlePageChangeLast(e)}
        sx=
          {{ 
            borderRadius: '50%', display: 'flex', alignItems: 'center', 
          }} 
      >
        <IconButton 
          aria-label="view-results" 
          name={`first-page`}
          className={`first-page`} 
          disabled={activePage >= lastPage - 1 || !subscriptionIsActive}
          sx={{ 
            width: '30px', height: '30px', 
            mb: 1, mt: 1, mr: 2, ml: 2, bottom: 0, left: 0, 
            // border: 2, borderColor: 'white', 
            boxShadow: 3, 
          }} 
        >
          <KeyboardDoubleArrowRightIcon 
            fontSize="small" 
            color= {activePage >= lastPage - 1 ? '' : 'primary'}
            sx={{  }} 
          />
        </IconButton>
      </Box>

    </Box>
  )
}