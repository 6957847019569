/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'

import { standardSpinner } from '../../helpers/spinners'
import { inProcessAllAuth } from './helpers/inProcessLoginRegister'
import { codeOrQuestion, input6DigitCode, answerSecurityQuestion } from './helpers/inProcessSecurity'
import { navbarHeight, footerHeight, positionChangeWidthSm, oneTimeRate } from '../../helpers/variableDefaults'
import useWindowDimensions from '../../helpers/windowDimensions'
import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'
import { userIsAuthenticated, getPayload } from '../../helpers/storage'

// Stripe
import { useStripe, useElements } from '@stripe/react-stripe-js'

// All Auth Component — 
// Set states here, most of the code is in the inProcessLoginRegister helper; 
// I do it this way so that login can occur in multiple places throughout code, and states must be defined in components, but having the functions in helper views makes it so they only have to be written once
const AllAuth = (props) => {

  // Destructure Props
  const { fromCreateAccount, registerForm, setRegisterForm } = props

  // Stripe
  let stripe
  let elements
  if (fromCreateAccount) {
    stripe = useStripe()
    elements = useElements()
  }
  
  // Navigate
  const navigate = useNavigate()

  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // URL Path
  const urlPath = window.location.pathname

  // Email/Password Login
  const [loginForm, setLoginForm] = useState({
    email: '',
    password: '',
  })
  const [ loginErrors, setLoginErrors ] = useState(false)
  const [loadingLogin, setLoadingLogin] = useState(false)

  // Email/Password Register
  const [ registerErrors, setRegisterErrors ] = useState({})
  const [ paymentErrors, setPaymentErrors ] = useState({})
  const [loadingRegister, setLoadingRegister] = useState(false)
  const [loadingPayment, setLoadingPayment] = useState(false)
  const [acceptedTOS, setAcceptedTOS] = useState(false)

  // For toggling between Login and Register screens
  const [loginViewIndex, setLoginViewIndex] = useState(fromCreateAccount ? 1 : 0)

  // For toggling Login and Security Question Screens
  const [allAuthViewIndex, setAllAuthViewIndex] = useState(0)

  // Security
  const [ token, setToken ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ profilePicture, setProfilePicture ] = useState('')
  const [ securityQuestion, setSecurityQuestion ] = useState('')
  const [ securityQuestionIndex, setSecurityQuestionIndex ] = useState(Math.floor(Math.random() * 3))
  const [ securityCodeGuess, setSecurityCodeGuess ] = useState('')
  const [ attemptsRemaining, setAttemptsRemaining ] = useState(3)
  const [ securityCodeErrors, setSecurityCodeErrors ] = useState(false)

  // UseEffect might be useful at some point
  useEffect(() => {
    if (userIsAuthenticated()) {
      const payload = getPayload()
      if (payload.sub) {
        navigate(`/dashboard/${payload.sub}`)
      }
    } else if (fromCreateAccount) {
      setRegisterForm({
        ...registerForm,
        entityName: '',
        entityType: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        acceptedTOS: false,
        securityQuestions: [],
        subscriptionType: '',
        // amount: oneTimeRate * 100,
        // currency: 'usd',
        // mode: 'payment',
        cardholderName: '',
        promoCode: '',
        paymentMethodIsValid: false,
      })
    }
  }, [])


  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {urlPath.includes('login') && seoPageTags(
        fromCreateAccount ? 'Register' : 'Login'
      )}
      
      {/* Login Body */}
      <Box
        sx={{
          // mt: 4,
          // backgroundColor: 'orange',
          pb: 8,
          width: '100%',
          maxHeight: '100%', 
          minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight})`,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: width < positionChangeWidthSm ? 'flex-start' : 'center',
          alignItems: 'center',
        }}
      >
        {/* Login or Register View */}
        {allAuthViewIndex === 0 && inProcessAllAuth(
          urlPath, 
          loginViewIndex, setLoginViewIndex,
          loginForm, setLoginForm, loginErrors, setLoginErrors, loadingLogin, setLoadingLogin,
          registerForm, setRegisterForm, registerErrors, setRegisterErrors, paymentErrors, setPaymentErrors, loadingRegister, setLoadingRegister, loadingPayment, setLoadingPayment, acceptedTOS, setAcceptedTOS,
          navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture,
          stripe, elements,
          width
        )}

        {/* Answer Security Question or 6-Digit Code Prompt */}
        {allAuthViewIndex === 1 && codeOrQuestion(setAllAuthViewIndex, token, securityQuestionIndex, setSecurityQuestion)}

        {/* Input 6-Digit Code */}
        {allAuthViewIndex === 2 && input6DigitCode(email, profilePicture, securityCodeGuess, setSecurityCodeGuess, attemptsRemaining, setAttemptsRemaining, securityCodeErrors, setSecurityCodeErrors, token, navigate, urlPath)}

        {/* Input Answer Security Question */}
        {allAuthViewIndex === 3 && answerSecurityQuestion(email, profilePicture, securityQuestion, securityQuestionIndex, securityCodeGuess, setSecurityCodeGuess, attemptsRemaining, setAttemptsRemaining, securityCodeErrors, setSecurityCodeErrors, token, navigate, urlPath)}

      </Box>
    </>
  )
}

export default AllAuth