/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams, Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { LineChart } from '@mui/x-charts/LineChart'
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis'
import EditIcon from '@mui/icons-material/Edit'
import StarIcon from '@mui/icons-material/Star'
import SendIcon from '@mui/icons-material/Send'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { ExpandMore } from '../../../../helpers/cardHelpers'
import { positionChangeHeightSm, positionChangeWidthSm, simcapPink, simcapTurquoise } from '../../../../helpers/variableDefaults'
import useWindowDimensions from '../../../../helpers/windowDimensions'
import { standardButton } from '../../../../helpers/buttons'
import { dummyBox } from '../../../../helpers/globalHelpers'
import { getOutcomesOfInvestmentSelection } from '../../../../helpers/getNestedElements'

const PortfolioItemCard = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Destructure Props
  const { vcSimulation, currentCompany, rules, decisionNumber } = props
  // console.log('company ->', company)
  
  useEffect
  const followOnIds = vcSimulation.followOnSelections.map(selection => selection.id)

  // Tracks whether the card is expanded
  const [expanded, setExpanded] = useState(false)
  const [outcome, setOutcome] = useState(vcSimulation.testIsFinished && vcSimulation.standardizedSimulation.hasOwnProperty('outcomes') ? getOutcomesOfInvestmentSelection(currentCompany.id, vcSimulation) : {})

  // Changes the card expanded state
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }


  const descriptionElement = (label, description) => {
    return (
      <Box
        sx={{
          // mb: 1,
          width: width < positionChangeWidthSm ? '95%' : '85%',
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start',
        }}
      >
        {/* Description Label */}
        <Typography
          sx={{
            mr: 1,
            width: width < 400 ? '180px' : width < positionChangeWidthSm ? '190px' : '220px', minWidth: width < 400 ? '150px' : width < positionChangeWidthSm ? '150px' : '190px',
            fontWeight: 'bold',
            fontSize: width < 400 ? '14px' : width < positionChangeWidthSm ? '16px' : '18px',
          }}
        >
          {label}
        </Typography>

        {/* Name of Company*/}
        <Typography
          sx={{
            // backgroundColor: 'blue',
            ml: 1,
            fontSize: width < 400 ? '14px' : width < positionChangeWidthSm ? '16px' : '18px',
          }}
        >
          {description}
        </Typography>
      </Box>
    )
  }

  const nonFinancialFactorPercentiles = (factor, percentile, upperBound, lowerBound) => {
    return (
      <Box
        sx={{
          // backgroundColor: 'yellow',
          mb: 0.5,
          width: width < positionChangeWidthSm ? '95%' : '85%',
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start',
        }}
      >
        {/* Factor */}
        <Box
          sx={{
            width: '175px',
            fontSize: width < 450 ? '14px' : width < positionChangeWidthSm ? '16px' : factor === 'Factor' ? '18px' : '18px',
            fontWeight: factor === 'Factor' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: factor === 'Factor' ? 'flex-start' : 'flex-start', alignItems: 'center',
          }}
        >
          {factor}
        </Box>

        {/* Mean */}
        <Box
          sx={{
            width: '80px',
            fontSize: width < 450 ? '14px' : width < positionChangeWidthSm ? '16px' : percentile === 'Mean' ? '18px' : '18px',
            fontWeight: percentile === 'Mean' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {percentile}
        </Box>

        {/* Upper Bound */}
        <Box
          sx={{
            width: '80px',
            fontSize: width < 450 ? '14px' : width < positionChangeWidthSm ? '16px' : upperBound === 'Upper' ? '18px' : '18px',
            fontWeight: upperBound === 'Upper' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {upperBound}
        </Box>

        {/* Lower Bound */}
        <Box
          sx={{
            width: '80px',
            fontSize: width < 450 ? '14px' : width < positionChangeWidthSm ? '16px' : lowerBound === 'Lower' ? '18px' : '18px',
            fontWeight: lowerBound === 'Lower' ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {lowerBound}
        </Box>

      </Box>
    )
  }

  const companyDetailsElement = (label, description) => {
    return (
      <Box
        sx={{
          // backgroundColor: 'yellow',
          mb: 0.5,
          width: width < positionChangeWidthSm ? '100%' : '85%',
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start',
        }}
      >
        {/* Description Label */}
        <Typography
          sx={{
            mr: 1,
            width: width < 450 ? '200px' : width < positionChangeWidthSm ? '250px' : '350px', minWidth: width < 450 ? '200px' : width < positionChangeWidthSm ? '250px' : '350px',
            // fontWeight: 'bold',
            fontSize: width < 450 ? '14px' : width < positionChangeWidthSm ? '16px' : '18px',
          }}
        >
          {label}
        </Typography>

        {/* Name of Company*/}
        <Typography
          sx={{
            // backgroundColor: 'blue',
            ml: width < 450 ? 0 : 1,
            fontWeight: 'bold',
            fontSize: width < 450 ? '14px' : width < positionChangeWidthSm ? '16px' : '18px',
          }}
        >
          {description}
        </Typography>
      </Box>
    )
  }


  return (
    <Card 
      align="left" 
      sx={{ 
        pt: 2, my: 1, pb: width > 400 ? 0 : 2,
        backgroundColor: 'whitesmoke',
        width: '95%', maxWidth: '600px',boxShadow: 3, borderRadius: 3,
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
      }}
    >
      {/* Decision Number */}
      <Box
        sx={{
          mb: 0, ml: 0,
          width: width < positionChangeWidthSm ? '95%' : '85%',
          fontWeight: 'bold',
          fontSize: '20px',
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        {decisionNumber}
      </Box>

      {/* Company */}
      <Box
        sx={{
          mb: 1,
          width: '100%',
          fontWeight: 'bold',
          fontSize: width < positionChangeWidthSm ? '20px' : '24px',
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        }}
      >
        {currentCompany.name}
      </Box>

      {/* Description */}
      <Box
        sx={{
          mt: 1, mb: 1.5,
          // backgroundColor: 'yellow',
          // px: 2,
          width: '100%',
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            width: width < positionChangeWidthSm ? '95%' : '85%',
            fontSize: width < positionChangeWidthSm ? '16px' : '18px',
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          {currentCompany.description}
        </Typography>
      </Box>

      {/* Initial Post-Financing Ownership Share */}
      {descriptionElement(`Entry Month:`, currentCompany.initialInvestmentMonth)}


      {/* Initial Investment Amount */}
      {descriptionElement(`Initial Investment ($M):`, `$${rules.initialInvestmentAmount}M`)}


      {/* Follow-On Amount */}
      {descriptionElement(`Follow-On ($M):`, (vcSimulation.currentFollowOnIndex <= decisionNumber - 1 && !vcSimulation.testIsFinished) ? 'NA' : `$${vcSimulation.followOnSelections.map(followOn => followOn.id).indexOf(currentCompany.id) > -1 ? rules.followOnInvestmentAmount : 0}M`)}


      {/* Initial Decision Quality */}
      {vcSimulation.testIsFinished && descriptionElement(`Initial Decision Quality:`, Object.keys(outcome).length > 0 && outcome.outcome[vcSimulation.weightings].hasOwnProperty('initialInvestmentDecisionQuality') ? outcome.outcome[vcSimulation.weightings].initialInvestmentDecisionQuality : 'NA')}


      {/* Momentum at Follow-On */}
      {/* {console.log('outcome length > 0 ->', Object.keys(outcome).length > 0)}
      {console.log('outcome has own property and length > 0 ->', Object.keys(outcome).length > 0 && outcome.outcome[vcSimulation.weightings].hasOwnProperty('statusAtFollowOn'))}
      {console.log('has empty space ->', outcome.outcome[vcSimulation.weightings].statusAtFollowOn.includes(' '))}
      {console.log('outcome status at follow-on ->', outcome.outcome[vcSimulation.weightings].statusAtFollowOn)}
      {console.log('momentum at follow-on ternary ->', (Object.keys(outcome).length > 0 && outcome.outcome[vcSimulation.weightings].hasOwnProperty('statusAtFollowOn') ? outcome.outcome[vcSimulation.weightings].statusAtFollowOn.includes(' ') ? outcome.outcome[vcSimulation.weightings].statusAtFollowOn.substring(0, outcome.outcome[vcSimulation.weightings].statusAtFollowOn.indexOf(' ')) : outcome.outcome[vcSimulation.weightings].statusAtFollowOn : (vcSimulation.currentFollowOnIndex >= decisionNumber || vcSimulation.testIsFinished) && currentCompany.hasOwnProperty('followOnInfo') ? currentCompany.followOnInfo.statusAtFollowOn.includes(' ') ? currentCompany.followOnInfo.statusAtFollowOn.substring(0, currentCompany.followOnInfo.statusAtFollowOn.indexOf(' ')) : currentCompany.followOnInfo.statusAtFollowOn : 'NA'))} */}
      {descriptionElement(`Momentum at Follow-On:`, (Object.keys(outcome).length > 0 && outcome.outcome[vcSimulation.weightings].hasOwnProperty('statusAtFollowOn') ? outcome.outcome[vcSimulation.weightings].statusAtFollowOn.includes(' ') ? outcome.outcome[vcSimulation.weightings].statusAtFollowOn.substring(0, outcome.outcome[vcSimulation.weightings].statusAtFollowOn.indexOf(' ')) : outcome.outcome[vcSimulation.weightings].statusAtFollowOn : (vcSimulation.currentFollowOnIndex >= decisionNumber || vcSimulation.testIsFinished) && currentCompany.hasOwnProperty('followOnInfo') ? currentCompany.followOnInfo.statusAtFollowOn.includes(' ') ? currentCompany.followOnInfo.statusAtFollowOn.substring(0, currentCompany.followOnInfo.statusAtFollowOn.indexOf(' ')) : currentCompany.followOnInfo.statusAtFollowOn : 'NA'))}


      {/* Result */}
      {/* {console.log('outcome ->', outcome)} */}
      {/* {console.log('has results property', outcome.outcome[vcSimulation.weightings].hasOwnProperty('resultIfYesFollowOn'))} */}
      {/* {console.log('Object.keys(outcome).length ->', Object.keys(outcome).length)} */}
      {vcSimulation.testIsFinished && descriptionElement(`Result:`, Object.keys(outcome).length > 0 && outcome.outcome[vcSimulation.weightings].hasOwnProperty('resultIfYesFollowOn') ? followOnIds.includes(outcome.id) ? outcome.outcome[vcSimulation.weightings].resultIfYesFollowOn : outcome.outcome[vcSimulation.weightings].resultIfNoFollowOn : 'NA')}

      {/* Expected Dollars */}
      {/* {console.log('expected dollars ->', vcSimulation.results.resultsSummary.filter(companySummary => companySummary.companyId === currentCompany.id))} */}
      {vcSimulation.testIsFinished && vcSimulation.results !== null 
        && descriptionElement(`Amount Returned:`, vcSimulation.results.resultsSummary.filter(companySummary => companySummary.companyId === currentCompany.id).length > 0 ? `$${parseFloat(vcSimulation.results.resultsSummary.filter(companySummary => companySummary.companyId === currentCompany.id)[0].expectedDollars).toFixed(2)}M` : 'NA')  
      }

      {/* Exit Year */}
      {vcSimulation.testIsFinished && descriptionElement(`Exit Year:`, Object.keys(outcome).length > 0 && outcome.outcome[vcSimulation.weightings].hasOwnProperty('exitYear') ? outcome.outcome[vcSimulation.weightings].exitYear : 'NA')}
      
      {/* Expand More Icon */}
      {width > 400 &&
        <CardActions disableSpacing
          sx={{
            // backgroundColor: 'yellow',
            width: '100%',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >

          {/* Expand More */}
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon 
              // color='primary' 
            />
          </ExpandMore>

        </CardActions>
      }

      {/* Longer Description — Part that expands when Expand More is Pressed */}
      <Collapse in={expanded} timeout="auto" unmountOnExit
        sx={{
          width: '100%',
          // backgroundColor: 'orange',
          // display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        <CardContent
          sx={{
            width: '100%',
            // backgroundColor: 'pink',
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          <Typography
            component="pre"
            sx={{
              // backgroundColor: 'green',
              mb: 1, mt: 0,
              width: '100%',
              fontFamily: 'Lato',
              fontSize: '18px',
              fontWeight: 'bold',
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            }}
          >
            Financial Factors
          </Typography>

          {/* Initial Post-Financing Ownership Share */}
          {companyDetailsElement(`Initial Post-Financing Ownership Share:`, currentCompany.financialExpectations.initialInvestmentOwnershipPercentage.description)}


          {/* Post-Money Valuation, Initial Investment */}
          {companyDetailsElement(`Post-Money Valuation, Initial Investment:`, `$${currentCompany.financialExpectations.initialPostMoneyValuation}M`)}


          {/* Forecasted Revenue at Plateau */}
          {companyDetailsElement(`Forecasted Revenue at Plateau:`, currentCompany.financialExpectations.projectedRevenuesPlateau.description)}


          {/* Forecasted Margin at Plateau */}
          {companyDetailsElement(`Forecasted Margin at Plateau:`, currentCompany.financialExpectations.profitMarginAtPlateau.description)}


          {/* Anticipated Additional Investment */}
          {companyDetailsElement(`Anticipated Additional Investment:`, currentCompany.financialExpectations.anticipatedAdditionalInvestment.description)}


          {/* Forecasted Margin at Plateau */}
          {companyDetailsElement(`Year 1 Revenue Projection:`, currentCompany.financialExpectations.projectedYear1Revenues.description)}

          
          {/* Non-Financial Factors */}
          <Typography
            component="pre"
            sx={{
              mb: 1.5, mt: 5,
              width: '100%',
              fontFamily: 'Lato',
              fontSize: '18px',
              fontWeight: 'bold',
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            }}
          >
            Non-Financial Factors (%)
          </Typography>

          {/* Categories (Factor, Mean, Upper, Lower) */}
          {nonFinancialFactorPercentiles('Factor', 'Mean', 'Upper', 'Lower')}

          {/* Team */}
          {nonFinancialFactorPercentiles('Team', parseFloat(currentCompany.team.meanPortfolioPercentile).toFixed(2), parseFloat(currentCompany.team.upperBoundPortfolioPercentile).toFixed(2), parseFloat(currentCompany.team.lowerBoundPortfolioPercentile).toFixed(2))}

          {/* Problem/Solution */}
          {nonFinancialFactorPercentiles('Problem/Solution', parseFloat(currentCompany.problemSolution.meanPortfolioPercentile).toFixed(2), parseFloat(currentCompany.problemSolution.upperBoundPortfolioPercentile).toFixed(2), parseFloat(currentCompany.problemSolution.lowerBoundPortfolioPercentile).toFixed(2))}

          {/* Differentiation */}
          {nonFinancialFactorPercentiles('Differentiation', parseFloat(currentCompany.differentiation.meanPortfolioPercentile).toFixed(2), parseFloat(currentCompany.differentiation.upperBoundPortfolioPercentile).toFixed(2), parseFloat(currentCompany.differentiation.lowerBoundPortfolioPercentile).toFixed(2))}

          {/* Market */}
          {nonFinancialFactorPercentiles('Market', parseFloat(currentCompany.market.meanPortfolioPercentile).toFixed(2), parseFloat(currentCompany.market.upperBoundPortfolioPercentile).toFixed(2), parseFloat(currentCompany.market.lowerBoundPortfolioPercentile).toFixed(2))}

          {/* Commercialization */}
          {nonFinancialFactorPercentiles('Commercialization', parseFloat(currentCompany.commercialization.meanPortfolioPercentile).toFixed(2), parseFloat(currentCompany.commercialization.upperBoundPortfolioPercentile).toFixed(2), parseFloat(currentCompany.commercialization.lowerBoundPortfolioPercentile).toFixed(2))}

          {/* Additional Risk */}
          {nonFinancialFactorPercentiles('Additional Risk', parseFloat(currentCompany.additionalRisks.meanPortfolioPercentile).toFixed(2), parseFloat(currentCompany.additionalRisks.upperBoundPortfolioPercentile).toFixed(2), parseFloat(currentCompany.additionalRisks.lowerBoundPortfolioPercentile).toFixed(2))}


        </CardContent>
      </Collapse>
      
    </Card>
  )
}

export default PortfolioItemCard